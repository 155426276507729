import React, { useState, useEffect } from "react";
import { PiNotePencilDuotone } from "react-icons/pi";
import { authAxios , withoutAuthAxios } from "../services/config";
import { toast } from "react-toastify";
import { CanadianCity } from "../utils/constants";
import Select from "react-select";
// import { authAxios, withoutAuthAxios } from "../../services/config";

import { useSelector } from "react-redux";
import ProfileRattingDetails from "./ProfileRattingDetails";
import { setReportFormatDate } from "../assets/Helper";
import ChatIdTask from "./ChatIdTask";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaChevronLeft } from "react-icons/fa6";
import { FaUser, FaBriefcase, FaStar } from "react-icons/fa6";


// import { FaUser, FaBriefcase, FaStar } from "react-icons/fa";

import {
  checkServices,
  correctArrayFormat,
  displayCategoryInHustlerProfile,
  handleDecimalNumber,
  removeIds,
} from "../utils/helper";
import CreatableSelect from "react-select/creatable";
import { State, City } from "country-state-city";
import { IoCloseCircle } from "react-icons/io5";
import IsLoadingHOC from "./IsLoadingHOC";
import { Tooltip } from "react-tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Button, message, Popconfirm } from 'antd';



//import testImage from "./assets/images/hustler--taskdone.png"
const HustlerProfile = (props) => {
  
  const { setLoading } = props;
  const taskCatgeory = useSelector((state) => state.app?.services);
  const taskServices = useSelector((state) => state.app?.tasks);

  const [activeLink, setActiveLink] = useState("my-profile");
  const [specificCategory, setspecificCategory] = useState([]);
  const [comments, setcomments] = useState([]);
  const [showTaskDetailsModel, setshowTaskDetailsModel] = useState(false);
  const [taskDetails, settaskDetails] = useState([]);

  const [userDetails, setuserDetails] = useState({
    personalDetails: {
      email: "",
      fname: "",
      lname: "",
      phone: "",
      description: "",
      status:"",
      profilePic: "",
    },
    securityDetails: {
      securityArea: "",
      securityLevel: "",
    },
    workDetails: {
      province: "",
      location: "",
      services: [],
    },
  });
  
  const confirm = (e) => {
    console.log(e);
    toggleStatus()
    // message.success('Click on Yes');
  };
  const cancel = (e) => {
    console.log(e);
    // message.error('Click on No');
  };
 
 
  // Handle button click to toggle status
  const toggleStatus = () => {
    setuserDetails((prev) => ({
      ...prev,
      personalDetails: {
        ...prev.personalDetails,
        status: prev.personalDetails.status === "active" ? "inactive" : "active",
      },
    }));
  };

  const [useradditionalDetails, setuseradditionalDetails] = useState({
    location: "",
    province: "",
  });

  const handleAlphabet = (e) => {
    const { name, value } = e.target;
    const regex = /^[A-Za-z ]*$/;
    if (regex.test(value)) {
      setuserDetails((prev) => ({
        ...prev,
        personalDetails: {
          ...prev.personalDetails,
          [name]: value,
        },
      }));
    }
  };

  const [allservices, setAllServices] = useState({
    count: 0,
    services: [],
  });

  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCode, setStateCode] = useState("");

  const userId = useSelector((state) => state?.auth?.user?.userId);
  const [expandedComments, setExpandedComments] = useState([]);

  const toggleReadMore = (index) => {
    if (expandedComments.includes(index)) {
      setExpandedComments(expandedComments.filter((i) => i !== index));
    } else {
      setExpandedComments([...expandedComments, index]);
    }
  };

  const fetchallReview = async () => {
    await authAxios()
      .get(`review/specific-user-reviews/${userId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setcomments(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchTaskDetails = async (id) => {
    await authAxios()
      .get(`/task/get-specific-task/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          settaskDetails(resData.data);
          setshowTaskDetailsModel(true);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    setuserDetails((prev) => ({
      ...prev,
      personalDetails: {
        ...prev.personalDetails,
        [name]: value,
      },
    }));
  };

  const navLinks = [
    { id: "my-profile", text: "My Profile", icon: <FaUser /> },
    { id: "work-profile", text: "Work Profile", icon: <FaBriefcase /> },
    { id: "reviews-profile", text: "View Reviews", icon: <FaStar /> },
  ];
  
  // const navLinks = [
  //   { id: "my-profile", text: "My Profile" },
  //   { id: "work-profile", text: "Work Profile" },
  //   { id: "reviews-profile", text: "View Reviews" },
  // ];

  const handleAreaChange = (e, data) => {
    if (e == "province") {
      setStateCode(data?.value);

      setuseradditionalDetails((prev) => ({
        ...prev,
        province: { label: data?.label, value: data?.label },
        location: "",
      }));
    } else if (e == "location") {
      setuseradditionalDetails((prev) => ({
        ...prev,
        location: { label: data?.label, value: data?.label },
      }));
    }
  };

  const fetchUserDetails = async () => {
    await authAxios()
      .get("profile/view-profile")
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setuseradditionalDetails((prev) => ({
            ...prev,
            location: {
              label: resData.data.workDetails.location,
              value: resData.data.workDetails.location,
            },
            province: {
              label: resData.data.workDetails.province,
              value: resData?.data?.workDetails?.province,
            },
          }));

          const data = State.getStatesOfCountry("CA").map(
            ({ isoCode, name }) => ({
              value: isoCode,
              label: name,
            })
          );

          const filterData = data.filter(
            (item) => item.label == resData?.data?.workDetails?.province
          );

          // setcityList(
          //   City.getCitiesOfState("CA", filterData[0]?.value).map(
          //     ({ name }) => ({
          //       value: name,
          //       label: name,
          //     })
          //   )
          // );

          setAllServices((prev) => ({
            ...prev,
            services: displayCategoryInHustlerProfile(
              resData.data.workDetails.services
            ),
            count: resData.data.workDetails.services.length - 1,
          }));


          setuserDetails(resData?.data);
          fetchUserAllReviewDetails();
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  
  const fetchUserAllReviewDetails = async () => {
    await authAxios()
      .get(`/users/get-specific-user/${userId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {

          setuserDetails((prev) => ({
            ...prev,
            personalDetails: {
              ...prev.personalDetails,
              stripeAccountStatus: resData.data.stripeAccountStatus,
              isStripeAccountCreated: resData.data.isStripeAccountCreated,
            },
          }));
          //  setallProfile(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleProfileChange = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("profile_picture", file);
    setLoading(true);

    await authAxios()
      .put("/users/update-profile-picture", formData)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData.message);
          fetchUserDetails();
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const value = checkServices(modifyArray2);


    const modifyArray = removeIds(allservices.services);
    const modifyArray2 = correctArrayFormat(modifyArray);
    if (!useradditionalDetails?.location?.label) {
      toast.info("Please enter your city.");
      return false;
    } else if (checkServices(modifyArray2) == "not completed") {
      toast.info("Please fill in all categories and services.");
      return false;
    } else if (userDetails.personalDetails.fname == "") {
      toast.info("Please enter your first name.");
      return false;
    } else if (userDetails.personalDetails.lname == "") {
      toast.info("Please enter your last name.");
      return false;
    }else if (!userDetails.personalDetails.phone.startsWith('+1')) {
      toast.info("Phone number must start with +1.");
      return false;
    } else if (!/^\+1\d{10}$/.test(userDetails.personalDetails.phone)) {
      toast.info("Phone number must be in the format +1 followed by 10 digits.");
      return false;
    }

    const payLoad = {
      personalDetails: {
        email: userDetails.personalDetails.email,
        fname: userDetails.personalDetails.fname,
        lname: userDetails.personalDetails.lname,
        phone: userDetails.personalDetails.phone,
        description: userDetails.personalDetails.description,
        status:userDetails.personalDetails.status,
        profilePic: userDetails.personalDetails.profilePic,
      },
      workDetails: {
        location: useradditionalDetails.location.value,
        province: useradditionalDetails.province.value,
        services: modifyArray2,
      },
    };
    setLoading(true);
    await authAxios()
      .put("/profile/update-profile", payLoad)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData?.message);
          fetchUserDetails();
          setLoading(false);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleVerify = async () => {
    await authAxios()
      .post("/stripe/reverify-account")
      .then((response) => {
        window.open(response.data.data.url);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    fetchUserDetails();
    fetchallReview();
  }, []);


  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
     ]
     );
  }, []);

  useEffect(() => {
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )


    if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // const handleInputAddCategoryChange = (inputValue, actionMeta, index) => {
  //   if (actionMeta.action == "menu-close") {
  //     if (actionMeta.prevInputValue.length > 0) {
  //       const newOption = {
  //         label: actionMeta.prevInputValue,
  //         value: actionMeta.prevInputValue,
  //       };

  //       setAllServices((prev) => {
  //         const updatedServices = [...prev.services];
  //         const updatedCategories = [
  //           ...updatedServices[index].category,
  //           newOption,
  //         ];
  //         updatedServices[index] = {
  //           ...updatedServices[index],
  //           category: updatedCategories,
  //         };
  //         return { ...prev, services: updatedServices };
  //       });
  //     }
  //   }
  // };


  const handleInputAddCategoryChange = (inputValue, actionMeta, index) => {
    console.log("checking")
    if (actionMeta.action === "menu-close") {
      if (actionMeta.prevInputValue.length > 0) {
        const newOption = {
          label: toTitleCase(actionMeta.prevInputValue),
          value: toTitleCase(actionMeta.prevInputValue),
        };

        setAllServices((prev) => {
          const updatedServices = [...prev.services];
          const existingCategories = updatedServices[index].category.map(
            (category) => category.value.toLowerCase()
          );

          // Check for duplicates (case insensitive)
          const exists = existingCategories.includes(newOption.value.toLowerCase());

          if (!exists) {
            const updatedCategories = [...updatedServices[index].category, newOption];
            updatedServices[index] = {
              ...updatedServices[index],
              category: updatedCategories,
            };
            return { ...prev, services: updatedServices };
          }

          // If a duplicate is found, do not update the state
          return prev;
        });
      }
    }
  };





  const handleRemoveService = (indexToRemove) => {
    setAllServices((prevState) => {
      const updatedServices = prevState.services.filter(
        (_, index) => index !== indexToRemove
      );
      return {
        count: Math.min(prevState.count, updatedServices.length),
        services: updatedServices,
      };
    });
  };

  // const handleAddServiceNameChange = (newValue, index) => {
  //   setSelectedServiceName(selectedOption ? selectedOption.value : null);

  //   setAllServices((prevState) => {
  //     const updatedServices = [...prevState.services];
  //     updatedServices[index].serviceName = newValue;
  //     return { ...prevState, services: updatedServices };
  //   });
  // };

  const handleInputAddServiceNameChange = (inputValue, actionMeta, index) => {
    if (actionMeta.action == "menu-close") {
      if (actionMeta.prevInputValue.length > 0) {
        const newOption = {
          label: actionMeta.prevInputValue,
          value: actionMeta.prevInputValue,
        };
        setAllServices((prevState) => {
          const updatedServices = [...prevState.services];
          updatedServices[index].serviceName = newOption;
          return { ...prevState, services: updatedServices };
        });
      }
    }
  };


  const handleScrollChange = (divId) => {
    const element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveLink(divId)

  };
const [total ,setTotal]=useState()
  const fetchProgressTask = async () => {
    setLoading(true);
    await authAxios()
      .get(
        `/bookhustler/get-in-progress-task?page=1&limit=10`
      )
      .then((response) => {
        const resData = response?.data;
        setTotal(resData?.count?.total)
        setLoading(false);
        if (resData.status == 1) {
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
useEffect(()=>{
  fetchProgressTask()
},[])
const toCamelCase = (str) => {
  if(str){ 
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
};


const [data, setData] = useState([])
  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data.data;
        setData(response?.data.data)
        setLoading(false);
        if (resData?.status == 1) {
          const services = [];
          resData?.data.map((item) => {
            services.push({
              label:  toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });
          const category=[]
          resData?.data?.map((item)=>{
            item?.category?.map((cat)=>{
              category?.push(cat)
            })
          })

        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaskListData();
  }, []);

const [selectedServiceName, setSelectedServiceName] = useState(null);
const [categoryOptions, setCategoryOptions] = useState([]);

const [previousServices, setPreviousServices] = useState([]); // State to store previous selections

const handleAddServiceNameChange = (newValue, index) => {
  // allservices
//21``
  // Update the previous services array with the new value and log the updated state inside the callback
  // setPreviousServices((prevState) => {
  //   const updatedServices = [...prevState, previousServiceObject];
  //   console.log("Updated Job Title Object (inside setState callback):", updatedServices);
  //   // return updatedServices;
  // });

  setSelectedServiceName(newValue ? newValue.value : null);

  setAllServices((prevState) => {
    const updatedServices = [...prevState.services];
    updatedServices[index].serviceName = newValue;
    updatedServices[index].category = []; 
    return { ...prevState, services: updatedServices };
  });

  setCategoryOptions([]); 
};

const handleAddCategoryChange = (newValue, index) => {
  console.log(allservices,"allservices")
  // const previousServiceName = allservices.services.map(x).serviceName;

  const previousServiceName = allservices.services[index].serviceName;
  console.log(previousServiceName,"previousServiceName")

// console.log( "handleAddCategoryChange", newValue, index)
  setAllServices((prevState) => {
    const updatedServices = [...prevState.services];
    updatedServices[index].category = newValue;
    return { ...prevState, services: updatedServices };
  });
};

const handleAddService = () => {
  setAllServices((prevState) => ({
    count: allservices.services.length - 1,
    services: [
      ...prevState.services,
      { id: allservices.services.length, serviceName: "", category: [] },
    ],
  }));
};

const CustomService =(index)=>{
  setSelectedServiceName(allservices.services[index].serviceName.value)
}

useEffect(() => {
  if (selectedServiceName) {
    const filteredCategories = data
      .find(service => service.serviceName === selectedServiceName)
      ?.category || [];

    setCategoryOptions(filteredCategories);
  } else {
    setCategoryOptions([]);
  }
}, [selectedServiceName, data]);


const excludedLabels = allservices.services.map(service => service.serviceName.label);
console.log("Excluded Labels:", excludedLabels);

// Filter data based on excluded labels
const filteredData = data.filter(s => 

  !excludedLabels.includes(s.serviceName)
);
console.log("Filtered Data:", filteredData);

// Map filtered data to the format required by CreatableSelect
const options = filteredData.map(s => ({
  label: s.serviceName,
  value: s.serviceName
}));
console.log("Options:", options);

const customStyles = {
  clearIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};


  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="pt-117"></div>
        <div className="heading--page px-3">
          <h1 className="text--dark playFairDisplay heading-h1 mb-2">
            Profile 
          </h1>
        </div>
        <div className="hustlers--wrapper setting--profile px-3 profile--setting-edit">
          <div className="sidebar">
            <ul>
              {navLinks.map((item) => (
                <>
                  <li
                    onClick={() => handleScrollChange(item.id)}
                    className={item.id == activeLink ? "active" : ""}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      cursor: 'pointer',
                      // backgroundColor: item.id === activeLink ? '#f0f0f0' : 'transparent',
                      transition: 'background-color 0.3s',
                    }}
                  >
                   {item.icon}
                    <a>  {item.text}</a>
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div className="main_content profile--details">
            <div id="my-profile" className="section zIndex2">
              <div className="created--task--lists">
                <div className="task--items--header mb-2">
                  <h4 className="mb-3">Personal Details</h4>
                </div>
                <div className="task--items-footer">
                  <div id="contact" className="form" role="form">
                    <div className="row profile--image">
                      <div className="col-xs-12 col-md-12 form-group">
                        <div className="img--profile">
                          <input
                            type="file"
                            onChange={handleProfileChange}
                            accept=".jpg, .png .jpeg"
                          />

                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetails?.personalDetails?.profilePic}`}
                          />

                          <div className="input--upload--img">
                            <p>Profile Image</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-6 col-md-6 form-group">
                        <input
                          className="form-control"
                          id="firstName"
                          name="fname"
                          placeholder="First Name"
                          type="text"
                          autoFocus=""
                          onChange={handleAlphabet}
                          value={userDetails?.personalDetails?.fname}
                        />
                      </div>
                      <div className="col-xs-6 col-md-6 form-group">
                        <input
                          className="form-control"
                          id="lastName"
                          name="lname"
                          placeholder="Last Name"
                          type="text"
                          onChange={handleAlphabet}
                          value={userDetails?.personalDetails?.lname}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-md-12 form-group">
                        <input
                          disabled
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="email"
                          onChange={handleChange}
                          value={userDetails?.personalDetails?.email}
                        />
                      </div>
                    </div>

                    <div className="row">
  <div className="col-xs-12 col-md-12 form-group">
    <input
      className="form-control"
      id="phone"
      name="phone"
      placeholder="Mobile Phone"
      type="tel"
      autoFocus=""
      onChange={(e) => {
        // Ensure the value always starts with +1
        const value = e.target.value;
        if (value.startsWith('+1')) {
          handleChange(e);
        } else {
          handleChange({ ...e, target: { ...e.target, value: '+1' + value.replace(/^\+1/, '') } });
        }
      }}
      value={userDetails.personalDetails.phone.startsWith('+1') 
        ? userDetails.personalDetails.phone 
        : '+1' + userDetails.personalDetails.phone}
    />
  </div>
</div>
                    {/* <div className="row">
                      <div className="col-xs-12 col-md-12 form-group">
                        <input
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Mobile Phone"
                          type="phone"
                          autoFocus=""
                          onChange={handleChange}
                          value={userDetails?.personalDetails?.phone}
                        />
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-xs-12 col-md-12 form-group">
                        <textarea
                          className="form-control"
                          id="phone"
                          name="description"
                          placeholder="Enter your description"
                          type="text"
                          autoFocus=""
                          onChange={handleChange}
                          value={userDetails?.personalDetails?.description}
                        />
                      </div>
                    </div>

    {/* //======================          */}
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px clamp(10px, 2vw, 23px)",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        marginBottom: "15px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <span
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#333",
        }}
      >
        Status:
      </span>
      {total>0 && userDetails.personalDetails.status=="active" ?<div>

        <Popconfirm
          placement="topRight"
          title="Change status"
          description={
            <>
              All your current jobs will be cancelled and <br />
              You will not be available to join any new jobs <br />
               until you become active again. <br /> <br />
               Are you sure you want to continue?
            </>
          }
    
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >

          <span
            // data-tooltip-id={`Active-InActive`}
            style={{
              background: "black",
              color: "white",
              padding: "5px 15px",
              border: "none",
              borderRadius: "10px",
              cursor: "pointer",
              transition: "background 0.3s ease",
            }}
          >
            {userDetails.personalDetails.status === "active" ? 'Active' : 'Inactive'} <AiOutlineInfoCircle />
          </span>
        </Popconfirm>
      </div>
         :
         <span
           data-tooltip-id={`Active-InActive`}
           onClick={toggleStatus}
           style={{
             background: "black",
             color: "white",
             padding: "5px 15px",
             border: "none",
             borderRadius: "10px",
             cursor: "pointer",
             transition: "background 0.3s ease",
           }}
         >
           {userDetails.personalDetails.status === "active" ? 'Active' : 'Inactive'} <AiOutlineInfoCircle />
         </span>
         
          }
    </div>


{/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px clamp(10px,2vw,23px)",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "8px",
                            marginBottom:"15px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#333",
                            }}
                          >
                            Status:
                          </span>
                          <div>
                            <span
                               data-tooltip-id={`Active-InActive`}
                              onClick={toggleStatus}
                              style={{
                                background: "black",
                                color: "white",
                          backgroundColor:"black",
                                padding: "5px 15px",
                                border: "none",
                                borderRadius: "10px",
                                cursor: "pointer",
                                transition: "background 0.3s ease",
                              }}
                            >
{userDetails.personalDetails.status === "active" ? 'Active' : 'Inactive'} <AiOutlineInfoCircle />
                            </span>
                          </div>
                        </div> */}
                        {" "}
                        <Tooltip
                      id={`Active-InActive`}
                      style={{ width: "210px" }}
                    >
Please click to set status                    
</Tooltip>


                    <Tooltip
                      id={`tooltip-verfiy--account`}
                      style={{ width: "210px" }}
                    >
                      You can see the status of your stripe account here
                    </Tooltip>

                    {userDetails.personalDetails.isStripeAccountCreated ==
                      false ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px clamp(10px,2vw,23px)",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#333",
                            }}
                          >
                            Account:
                          </span>
                          <div>
                            <span
                              data-tooltip-id={`tooltip-verfiy--account`}
                              onClick={handleVerify}
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 15px",
                                border: "none",
                                borderRadius: "10px",
                                cursor: "pointer",
                                transition: "background 0.3s ease",
                              }}
                            >
                              Connect <AiOutlineInfoCircle />
                            </span>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        {userDetails.personalDetails.stripeAccountStatus ==
                          "verified" ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px clamp(10px,2vw,23px)",
                              backgroundColor: "#f9f9f9",
                              borderRadius: "8px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Account:
                            </span>
                            <span
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 15px",
                                border: "none",
                                borderRadius: "10px",
                                cursor: "pointer",
                                transition: "background 0.3s ease",
                              }}
                              data-tooltip-id={`tooltip-verfiy--account`}
                            >
                              Verified
                              <img
                                src="/assets/images/completetask.png"
                                alt="Verified"
                                style={{ marginLeft: "8px" }}
                              />
                            </span>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px clamp(10px,2vw,23px)",
                              backgroundColor: "#f9f9f9",
                              borderRadius: "8px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Account:
                            </span>
                            <div>
                              <span
                                data-tooltip-id={`tooltip-verfiy--account`}
                                onClick={handleVerify}
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "5px 15px",
                                  border: "none",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  transition: "background 0.3s ease",
                                }}
                              >
                                Verify <AiOutlineInfoCircle />
                              </span>
                            </div>
                          </div>
                        )}{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div id="work-profile" className="section zIndex2">
              <div
                className="created--task--lists"
                style={{ maxWidth: "797px", width: "100%" }}
              >
                <div className="task--items--header"></div>
                <div className="task--items-footer">
                  <div
                    id="workProfileForm"
                    className="form hustler--workprofile"
                    role="form"
                  >
                    <div
                      className="row--add--more"
                      style={{
                        padding: "10px",
                        width: "100%",
                        margin: "0 auto",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        background: "#fff",
                      }}
                    >
                      <div className="col-xs-12 col-md-12 form-group ">
                        <label>Select your province</label>

                        <Select
                          required
                          className="select--custom profile--select"
                          options={provinceList}
                          //  isClearable
                          placeholder={`Select your province`}
                          value={useradditionalDetails.province}
                          onChange={(e) => handleAreaChange("province", e)}
                        />
                      </div>

                      <div className="col-xs-12 col-md-12 form-group ">
                        <label>Select your city</label>

                        <Select
                          //   required
                          className="select--custom profile--select"
                          options={cityList}
                          // isClearable
                          placeholder={`Select your city`}
                          value={useradditionalDetails.location}
                          onChange={(e) => handleAreaChange("location", e)}
                        />
                      </div>
                    </div>
                    {allservices.services.map((item, index) => (
                      <div
                        className="row--add--more"
                        key={index}
                        style={{
                          padding: "10px",
                          width: "100%",
                          margin: "0 auto",
                          marginBottom: "10px",
                          borderRadius: "5px",
                          background: "#fff",
                          position: "relative",
                        }}
                      >
                        <span
                          className="remove--service"
                          style={{
                            position: "absolute",
                            left: "-10px",
                            top: "-13px",
                            fontSize: "22px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveService(index)}
                        >
                          <IoCloseCircle />
                        </span>
                        <div className="form--group--login text-start mb-2">
                          {  console.log(allservices,"allservices 1133")
                          }
                          {/* {console.log(item,"item.serviceName")} */}
                          <label htmlFor="Your Job Title">Your Job Title</label>
                          <CreatableSelect
                            //  required
                            isClearable
                            className="select--custom profile--select"
                            options={options}
                            // options={data
                            //   // .filter(s => s.serviceName !== item.serviceName.label) // Exclude previous job title
                            //   .filter(s => 
                            //     !allservices.services.some(service => service.serviceName.label === s.serviceName.label)
                            //   )
                            //  .map(s => ({ label: s.serviceName, value: s.serviceName }))}
                            value={item.serviceName}
                            placeholder={`Your Job Title`}
                            onChange={(newValue) =>
                              handleAddServiceNameChange(newValue, index)
                            }
                            onInputChange={(inputValue, actionMeta) =>
                              handleInputAddServiceNameChange(
                                inputValue,
                                actionMeta,
                                index
                              )
                            }
                            styles={customStyles} // Apply custom styles

                          />
                        </div>
                        
                        <div className="form--group--login text-start">
                          <label htmlFor="Choose Category">
                            Choose Service
                          </label>

                          <CreatableSelect
                            isMulti
                            // required
                            // options={taskServices}
                            options={categoryOptions.map(cat => ({ label: cat.label, value: cat.value }))}


                            isClearable
                            className="select--custom profile--select multiple--select--overflow"
                            placeholder={`Choose Services`}
                            value={item.category}
                            onMenuOpen={() => {
                              console.log("Dropdown is about to open");
                              CustomService(index); // Pass the index or other necessary data here
                            }}

                            onChange={(newValue) =>
                              handleAddCategoryChange(newValue, index)
                            }
                            onInputChange={(inputValue, actionMeta) =>
                              handleInputAddCategoryChange(
                                inputValue,
                                actionMeta,
                                index
                              )
                            }
                          
                          />
                        </div>

                      </div>
                    ))}

                    <p onClick={handleAddService} style={{ cursor: "pointer" }}>
                      Add another category +
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="reviews-profile">

              <div >
                <ProfileRattingDetails />
              </div>

              <div id="view--reviews" className="comments--profile--lists">
                <div className="reviews--section">
                  {!comments && (
                    <div className="no--comments">
                      <h5 style={{ textAlign: "center", color: "#ada7a7" }}>
                        No Comments
                      </h5>
                    </div>
                  )}

                  {comments &&
                    comments.map((item, index) => {
                      const isExpanded = expandedComments.includes(index);
                      const commentText =
                        item?.comments.length > 100 && !isExpanded
                          ? `${item?.comments.substring(0, 100)}...`
                          : item?.comments;

                      return (
                        <div className="user--comment--items" key={item._id}>
                          <div className="user--comment--header">
                            <div className="user--comment--details">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.reviewSender.profilePic}`}
                                alt=""
                                className="user--profile--img"
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  borderRadius: "10px",
                                  objectFit: "cover",
                                }}
                              />
                              <div className="user--comment--detail">
                                <h3>
                                  {item?.reviewSender.fname} {item?.reviewSender.lname}
                                </h3>
                                <span className="user--rating--comment">
                                  <img src="/assets/images/hustler-star.png" alt="rating" />
                                  {handleDecimalNumber(item?.rating)}
                                </span>{" "}
                                {setReportFormatDate(item?.createdAt)}
                              </div>
                            </div>
                            <div
                              className="view--task--comment"
                              onClick={() => fetchTaskDetails(item?.taskId)}
                            >
                              <MdOutlineRemoveRedEye
                                style={{ cursor: "pointer" }}
                                color="white"
                              />
                            </div>
                          </div>

                          <div className="user--comment--description">
                            <p>{commentText}</p>
                            {item?.comments.length > 100 && (
                              <span
                                className="read-more-toggle"
                                onClick={() => toggleReadMore(index)}
                                style={{ cursor: "pointer", color: "#1f1f28", textDecoration: "underline", fontWeight:'600' }}
                              >
                                {isExpanded ? "Read Less" : "Read More"}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {showTaskDetailsModel && (
              <ChatIdTask
                text="profile"
                setshowTaskDetailsModel={setshowTaskDetailsModel}
                taskDetails={taskDetails}
              />
            )}
            <button
              type="submit"
              className="custom--btn ligghtbtn-hover transparent--btn minbutton mb-3"
            >
              <span>Save Changes</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default IsLoadingHOC(HustlerProfile);
