import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { BsArrowLeft, BsArrowRight, BsStarFill } from 'react-icons/bs'
import TestimonialsArrow from './TestimonialsArrow';



const TestimonialSlider = () => {
    const testimonialSlider = [
        {
            id: 1,
            image: './assets/images/testimonial-img.png',
            name: 'Keri E.',
            description: 'Very friendly, helped me out at the last minute with what actually turned out to be a more difficult task that originally thought. Thanks!',
            date: 'October 04, 2019',
            starRating: 5,
        },
        {
            id: 2,
            image: './assets/images/testimonial-img.png',
            name: 'Keri E.',
            description: 'Very friendly, helped me out at the last minute with what actually turned out to be a more difficult task that originally thought. Thanks!',
            date: 'October 04, 2019',
            starRating: 4,
        },
        {
            id: 3,
            image: './assets/images/testimonial-img.png',
            name: 'Keri E.',
            description: 'Very friendly, helped me out at the last minute with what actually turned out to be a more difficult task that originally thought. Thanks!',
            date: 'October 04, 2019',
            starRating: 4,
        },
        {
            id: 4,
            image: './assets/images/testimonial-img.png',
            name: 'Keri E.',
            description: 'Very friendly, helped me out at the last minute with what actually turned out to be a more difficult task that originally thought. Thanks!',
            date: 'October 04, 2019',
            starRating: 4,
        },
        {
            id: 5,
            image: './assets/images/testimonial-img.png',
            name: 'Keri E.',
            description: 'Very friendly, helped me out at the last minute with what actually turned out to be a more difficult task that originally thought. Thanks!',
            date: 'October 04, 2019',
            starRating: 4,
        },
        {
            id: 6,
            image: './assets/images/testimonial-img.png',
            name: 'Keri E.',
            description: 'Very friendly, helped me out at the last minute with what actually turned out to be a more difficult task that originally thought. Thanks!',
            date: 'October 04, 2019',
            starRating: 4,
        },
    ];
    return (
        <>
            <section className='py-100 testimonial--container'>
                <div className='testimonial--slider flex'>
                    <Carousel
                        additionalTransfrom={0}
                        autoPlaySpeed={3000}
                        arrows={false}
                        containerClass="carousel-container testimonials--list"
                        renderButtonGroupOutside={true}
                        customButtonGroup={<TestimonialsArrow />}
                        infinite
                        draggable={false}
                        partialVisible
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1200
                                },
                                items: 4,
                                // partialVisibilityGutter: 80
                            },
                            mobile: {
                                breakpoint: {
                                    max: 767,
                                    min: 0
                                },
                                items: 1,
                                // partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1200,
                                    min: 767
                                },
                                items: 2,
                                // partialVisibilityGutter: 30
                            }
                        }}
                        slidesToSlide={1}
                        swipeable={false}
                    >
                        {testimonialSlider.map((item, index) => {
                            if (item.name.length > 0) {
                                return (
                                    <div key={index} className='testimonial--item'>
                                        <div className='testimonial--item-header'>
                                            <div className='testimonial--name'><h3>{item.name}</h3></div>
                                            <div className='testimonial--image'><img src={item.image} alt={item.name} /></div>
                                            <div className='rating--testimonial'>
                                                {Array.from({ length: item.starRating }, (_, index) => (
                                                    <BsStarFill key={index} className="star-icon" />
                                                ))}
                                            </div>
                                        </div>
                                        <div className='description-testimonial'>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className='date--testimonial'>
                                            {item.date}
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index}>
                                        <p>Not Found Testimonials.</p>
                                    </div>
                                );
                            }
                        })}


                    </Carousel>
                </div>
            </section>
        </>
    )
}

export default TestimonialSlider