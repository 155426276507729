import React from "react";
import { useState } from 'react';
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi';

const PasswordInputField = ({ label }) => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <>
            <label htmlFor="Password">{label}</label>
            <div className="input-password relative">
                <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={label}
                />
                <div className="passwod--visibitly" onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <PiEyeLight /> : <PiEyeSlash />}
                </div>
            </div>
        </>
    );
};

export default PasswordInputField;