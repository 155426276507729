import React from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const TestimonialsArrow = ({ next, previous }) => {
  return (

    <>

      <div className='custom--container heading--testimonial'>
        <div className='col-lg-12'>
          <h2 className='playFairDisplay heading-h3'>Don't just take our word for it...</h2>
        </div>

          <div className='slide--navigation'>
            <button className='left--arow' onClick={() => previous()}><BsArrowLeft /></button>
            <button className='right--arow' onClick={() => next()}><BsArrowRight /></button>
          </div>
      </div>


    </>
  );
};

export default TestimonialsArrow

