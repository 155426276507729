import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";

//import 'https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&display=swap';

const CountdownTimer = () => {
  // Set the launch date and time (change this to your desired date and time)
  // // Timezone in Kelowna, BC, Canada
  // const targetDate = new Date("2024-10-15T11:59:59").getTime();
    // Target date and time in Pacific Time Zone (Kelowna, BC)
    const targetDate = DateTime.fromISO("2024-10-15T11:59:59", { zone: "America/Vancouver" }).toMillis();
    // const targetDate = DateTime.fromISO("2024-10-14T04:40:59", { zone: "America/Vancouver" }).toMillis();



  // console.log(targetDate,"target date check",new Date("2024-10-15T11:59:59"))

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Function to calculate the remaining time
  function calculateTimeLeft() {
    // const now = new Date().getTime();
    const now = DateTime.now().setZone("America/Vancouver").toMillis(); 

    // console.log(now,"now  time check",new Date())
    const difference = targetDate - now;

    // Calculate time units
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    // Update the timer every second
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
    }, 1000);

    // Clean up the timer on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="timerContainer" style={styles.container}>
      <div className="myTimeBox" style={styles.timeBox}>
        {/* <div style={styles.number}>{timeLeft.days}</div> */}
        <div className="grayBg" style={styles.number}>{String(timeLeft.days).padStart(3, '0')}</div>

        <div className="timeSpan">Days</div>
      </div>
      <div className="myTimeBox" style={styles.timeBox}>
        {/* <div style={styles.number}>{timeLeft.hours}</div> */}
        <div className="grayBg" style={styles.number}>{String(timeLeft.hours).padStart(2, '0')}</div>
        <div className="timeSpan">Hrs</div>
      </div>
      <div className="myTimeBox" style={styles.timeBox}>
      <div className="grayBg" style={styles.number}>{String(timeLeft.minutes).padStart(2, '0')}</div>
        {/* <div style={styles.number}>{timeLeft.minutes}</div> */}
        <div className="timeSpan">Mins</div>
      </div>
      <div className="myTimeBox" style={styles.timeBox}>
      <div className="grayBg" style={styles.number}>{String(timeLeft.seconds).padStart(2, '0')}</div>

        {/* <div style={styles.number}>{timeLeft.seconds}</div> */}
        <div className="timeSpan">Secs</div>
      </div>
    </div>
  );
};

// CSS-in-JS for styling

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    fontFamily: "Bebas Neue, sans-serif",    
    // backgroundColor: "#f0f0f0",
    padding: "20px",
    borderRadius: "10px",
    //position: "absolute", 
    zIndex:"99999",
    //top: "100px" ,
    responsive: {
      1400: {
        position: "relative",
      }
    }
  },
  timeBox: {
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0)",
    padding: "0px",
  },
  number: {
    //fontSize: "2rem",
  },
};


export default CountdownTimer;
