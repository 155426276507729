import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { IoIosCloseCircle, IoIosSearch } from "react-icons/io";
import { CanadianCity } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { authAxios } from "../../services/config";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import RangeSlider from "react-range-slider-input";
import { State, City } from "country-state-city";

import {
  handleDecimalNumber,
  sortByBudget,
  sortByPrice,
  staticCategory,
  staticRatingFilter,
} from "../../utils/helper";
import { Link } from "react-router-dom";
import { FaRegPaperPlane } from "react-icons/fa";
import Select from "react-select";
import Pagination from "../../common/Pagination";
import { BiSolidCaretLeftSquare } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { GoArrowRight } from "react-icons/go";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

const HustlersLists = (props) => {
  const { setLoading } = props;
  const [hustler, sethustler] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [baseProfileBase, setBaseProfileBase] = useState("");

  // pagination
  const [currentPage, setcurrentPage] = useState(1);
  const [ongoingPagination, setongoingPagination] = useState("fetchallUsers");
  const [postsPerPage, setpostsPerPage] = useState(6);
  const [totalPosts, settotalPosts] = useState(0);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const [favoriteHustlers, setFavoriteHustlers] = useState([]);

  const fetchallUsers = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/search/get-all-hustlers?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      // .get(`/search/get-all-hustlers`)
      .then((response) => {
        const resData = response?.data;


        setLoading(false);

        if (resData.status == 1) {
          settotalPosts(resData?.count?.total);
          sethustler(resData?.data);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleButtonSearch = async () => {
    const payload = {
      query: selectedService,
      location: selectedCity?.label,
      province: selectedProvince?.label,
      sort: selectedSort?.value,
    };
    handleSearchHustlers(payload, 1);

    setongoingPagination("all-searching");
    setcurrentPage(1);
  };

  const handleSearchSortBy = (item) => {
    if (item.value == selectedSort.value) {
      setSelectedSort("");
      if (
        selectedService == "" &&
        !selectedCity?.label &&
        !selectedProvince?.label
      ) {
        fetchallUsers(1);
        setongoingPagination("fetchallUsers");
      } else {
        const payload = {
          query: selectedService,
          location: selectedCity?.label,
          province: selectedProvince?.label,
        };
        handleSearchHustlers(payload, 1);

        setongoingPagination("all-searching");
        setcurrentPage(1);
      }
    } else {
      const payload = {
        query: selectedService,
        location: selectedCity?.label,
        province: selectedProvince?.label,
        sort: item?.value,
      };
      setSelectedSort(item);
      handleSearchHustlers(payload, 1);
      setongoingPagination("all-searching");
      setcurrentPage(1);
    }
  };

  const handleSearchHustlers = async (data, number) => {
    const previousData = {
      query: selectedService,
      location: selectedCity?.label,
      province: selectedProvince?.label,
      sort: selectedSort?.value,
    };

    setLoading(true);
    await authAxios()
      .post(
        `/search/search-and-sort-hustlers?page=${
          number || currentPage
        }&limit=${postsPerPage}`,
        data
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;


        if (resData.status === 1) {
          sethustler(resData?.data?.hustlers);
          settotalPosts(resData?.data?.totalCount);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchFavorites = async () => {
    if(ongoingPagination=="favourite-hustler"){
       setSelectedSort("");
      setcurrentPage(1);
      fetchallUsers(1);
      setongoingPagination("fetchallUsers");
      setSelectedService("");
      setSelectedCity("");
      setselectedProvince("");
    }else{
      setSelectedSort("");
      setcurrentPage(1);
      handlefetchFavouriteHustlers(1);
      setongoingPagination("favourite-hustler");
      setSelectedService("");
      setSelectedCity("");
      setselectedProvince("");
    }
   
  };

  const handleSecondFavourites=()=>{
    setSelectedSort("");
    setcurrentPage(1);
    handlefetchFavouriteHustlers(1);
    setongoingPagination("favourite-hustler");
    setSelectedService("");
    setSelectedCity("");
    setselectedProvince("");
  }

  const handlefetchFavouriteHustlers = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/fav-hustler/get-fav-hustler?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        const resData = response?.data;

        setLoading(false);

        if (resData.status === 1) {
          sethustler(resData?.data?.hustlers);
          settotalPosts(resData?.data?.totalCount);
        } else {
          //toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        //toast.error(error?.response?.data?.message);
      });
  };

  const handleProfileBaseMode = (buttonName) => {
    setBaseProfileBase(buttonName === baseProfileBase ? null : buttonName);
  };

  const handleToggleFavorite = async (userId) => {
    try {
      const checkFavorite = favoriteHustlers.some(
        (item) => item.user.id === userId
      );

      if (checkFavorite) {
        await authAxios().delete(`/fav-hustler/remove-favorites/${userId}`);
        toast.success("Hustler removed from favorites.");
        if (ongoingPagination == "favourite-hustler") {
          setFavoriteHustlers((prevHustlers) =>
            prevHustlers.filter((item) => item.user.id !== userId)
          );
          handleSecondFavourites();
        } else {
          setFavoriteHustlers((prevHustlers) =>
            prevHustlers.filter((item) => item.user.id !== userId)
          );
        }
      } else {
        await authAxios().post(`/fav-hustler/add-to-fav/${userId}`);
        toast.success("Hustler added to favorites.");
        setFavoriteHustlers((prevHustlers) => [
          ...prevHustlers,
          { user: { id: userId } },
        ]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred.");
    }
  };


  const checkFavoriteHustler = async () => {
    try {
      const response = await authAxios().get(`/fav-hustler/get-fav-hustler`);
      const resData = response?.data;
      setFavoriteHustlers(resData?.data?.hustlers || []);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to fetch favorite hustlrs."
      );
    }
  };

  useEffect(() => {
    checkFavoriteHustler();
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);

  useEffect(() => {
    setcityList([])
    if(stateCode=="BC"){
        setcityList(
          [ {"value": "Kamloops", "label": "Kamloops"},
            {"value": "Kelowna","label": "Kelowna"},
            {"value": "Peachland","label": "Peachland"},
            {"value": "Penticton","label": "Penticton"},
            {"value": "Vernon","label": "Vernon"},
            {"value": "West Kelowna","label": "West Kelowna"},
          ]
        )
      }
      else{
    
        setcityList(
          [ {value: "Saskatoon",label: "Saskatoon"}
          ]
        )
    
    
      }
      if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  useEffect(() => {
    if (ongoingPagination == "fetchallUsers") {
      fetchallUsers();
    } else if (ongoingPagination == "favourite-hustler") {
      handlefetchFavouriteHustlers();
    } else if (ongoingPagination == "all-searching") {
      handleSearchHustlers();
    } else {
    }
  }, [currentPage]);


  
  return (
    <>
      <div className="h-100px"></div>

      <section className="hustler--list--container">
        <div className="filter--left--hustler">
          <h2 className="filter--mobile">
            Filter{" "}
            <div
              className="arrow--view--profilebase"
              onClick={() => handleProfileBaseMode("filter--price--budget")}
            >
              <BiSolidCaretLeftSquare />
            </div>
          </h2>

          <div
            className={`filter--left--hustler--data ${
              baseProfileBase === "filter--price--budget"
                ? "active--filter--price--budget"
                : ""
            }`}
          >
            <div
              className="close c-pointer"
              onClick={() => setBaseProfileBase(null)}
            >
              <IoIosCloseCircle />
            </div>

            <div className="filter--div">
              <div className="select--price">
                <p className="filter--head">Sort By</p>
                <div className="checkbox--task">
                  <ul>
                    {staticRatingFilter &&
                      staticRatingFilter.length > 0 &&
                      staticRatingFilter.map((item, index) => (
                        <>
                          <li key={index}>
                            <div className="checkbox--custom">
                              <label
                                htmlFor={`checkbox-${index}`}
                                style={{ cursor: "pointer" }}
                              >
                                {item?.label}
                              </label>

                              <input
                                type="checkbox"
                                id={`checkbox-${index}`}
                                checked={selectedSort?.value === item.value}
                                onChange={() => handleSearchSortBy(item)}
                              />
                              <span className="checkmark"></span>
                            </div>
                          </li>
                        </>
                      ))}
                  </ul>
                </div>

              </div>
              <hr className="hr--class" />
              <div className="add--favorite">
                <button
                  onClick={fetchFavorites}
                  className={`custom--btn minbutton ${
                    ongoingPagination === "favourite-hustler"
                      ? "darkbtn-hover"
                      : "ligghtbtn-hover transparent--btn"
                  }`}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    Favorite Hustlrs{" "}
                    {ongoingPagination === "favourite-hustler" ? (
                      <GoArrowRight />
                    ) : null}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="filterdata--right--hustler">
          <div className="director--search--hustler hustler--home--page">
            <div className="custom--container">
              <div className="container--filter">
                <div
                  className="filter--data--search"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="filter--search--col">
                    <div className="search--bar--banner flex sm-flex-col sm-justify-start responsive--search--bar">
                      <input
                        required
                        placeholder="I need help with..."
                        className="need--select"
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                      />

                      <div className="city--zip">
                        <Select
                          options={provinceList}
                          isClearable={false}
                          className="select--custom province--select"
                          value={selectedProvince}
                          placeholder={`Select Province`}
                          onChange={handleSelectProvince}
                          // isSearchable={false}
                        />
                      </div>
                      <div className="city--zip">
                        <Select
                          options={selectedProvince ? cityList : []}
                          className="select--custom"
                          value={selectedCity}
                          placeholder={`Select City`}
                          onChange={(option) => setSelectedCity(option)}
                          // isSearchable={false}
                        />
                      </div>

                      {selectedService?.length > 0 || selectedProvince?.label ? (
                        <button
                          className="btn btn-dark submit--need"
                          onClick={handleButtonSearch}
                        >
                          Search Hustlr
                        </button>
                      ) : (
                        <button
                          className="btn btn-dark submit--need"
                          // style={{ cursor: "not-allowed" }}
                          onClick={() => fetchallUsers(1)}
                        >
                          Search Hustlr
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hustlers-lists">
            {hustler && hustler.length > 0 ? (
              hustler.map((item, index) => (
                <div key={index} className="hustler--thumbnail--items">
                  <div className="custom--scroll hustler--thumbnail-scroll">
                    <div className="thumbnail--hustler">
                      <Link
                        to={`/user-info/${item?.user?.id}`}
                        className="thumbnail--hustler--profile"
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                        />
                      </Link>
                      <div
                        className="thumbnail--hustler--info"
                        style={{
                          width: "60%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="hustler--i">
                          <h2 style={{ marginBottom: "0px" }}>
                            {item?.user?.fname} {item?.user?.lname}
                          </h2>
                          <p
                            style={{
                              marginBottom: "0px",
                              fontSize: "12px",
                              color: " #949494",
                            }}
                          >
                            {item?.user?.hustlerDetails?.province},{" "}
                            {item?.user?.hustlerDetails?.location}
                          </p>
                          <p className="search--category">
                            {item?.user?.hustlerDetails?.services?.length > 0 &&
                              item?.user?.hustlerDetails?.services.map(
                                (service, index, array) => (
                                  <span key={service._id}>
                                    {service.serviceName}
                                    {index !== array.length - 1 && ", "}
                                  </span>
                                )
                              )}
                          </p>
                        </div>

                        <Tooltip
                          id="task--detail-id"
                          className="tooltip--task--details"
                        />
                        <div
                          data-tooltip-id="task--detail-id"
                          data-tooltip-content={
                            favoriteHustlers?.some(
                              (favorite) =>
                                favorite?.user?.id === item?.user?.id
                            )
                              ? "Remove Favorite"
                              : "Add Favorite"
                          }
                          data-tooltip-place="top"
                          className="add--favorite--item"
                          onClick={() => handleToggleFavorite(item?.user?.id)}
                        >
                          {favoriteHustlers?.some(
                            (favorite) => favorite?.user?.id === item?.user?.id
                          ) ? (
                            <AiFillHeart />
                          ) : (
                            <AiOutlineHeart />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="items-content-hustler">
                      <div>
                        <p className="hutler--taskdone">
                          <img src="./assets/images/hustler--taskdone.png" />
                          {item?.user?.hustlerDetails?.completedTask || 0}{" "}
                          Completed Tasks
                        </p>
                        <p className="working--rating">
                          <img src="../assets/images/hustler-star.png" />
                          {handleDecimalNumber(
                            item?.user?.hustlerDetails?.ratings
                          )}
                        </p>
                        <ul className="featured--work--list">
                          {item?.user?.hustlerDetails?.services.map(
                            (service) => (
                              <>
                                {service.category.map((category) => (
                                  <li key={category._id}>{category?.label}</li>
                                ))}
                              </>
                            )
                          )}
                        </ul>
                        <p className="discription--hustler--profile">
                          {item.user.description == "" ||
                          item.user.description == null ? (
                            <></>
                          ) : (
                            <>
                              {" "}
                              {item.user.description.length > 100 ? (
                                <>
                                  {" "}
                                  {item?.user?.description?.substring(0, 100) +
                                    "..."}{" "}
                                </>
                              ) : (
                                <>{item?.user?.description} </>
                              )}{" "}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="hustler-profile-view">
                    <Link
                      to={`/hustlr-booking/${item?.user?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <button className="custom--btn mt-50 darkbtn-hover add--favorite--item">
                        <span>
                          <FaRegPaperPlane /> View Profile
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div>No hustlrs found</div>
            )}
          </div>
         
          
          <Pagination
            currentPage={currentPage}
            totalPosts={totalPosts}
            paginate={paginate}
            postsPerPage={postsPerPage}
          />
        </div>
      </section>
      {baseProfileBase === "filter--price--budget" && (
        <div className="hustlerlist--filter--overlay"></div>
      )}
    </>
  );
};

export default IsLoadingHOC(HustlersLists);
