import React, { useEffect, useState } from "react";
import { TbMoneybag } from "react-icons/tb";
import BookHustlerPopup from "./BookHustlerPopup";
import { toast } from "react-toastify";
import HustlerReviews from "./HustlerReviews";
import { CanadianCity } from "../../utils/constants";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { State, City } from "country-state-city";
import { useParams } from "react-router-dom";
import { authAxios } from "../../services/config";
import { BudgetFormat, displayBudget, handleDecimalNumber, payloadBudget, toCamelCase } from "../../utils/helper";
import { setReportFormatDate } from "../../assets/Helper";
import { GrLocation } from "react-icons/gr";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { useSocket } from "../../contexts/SocketContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import DirectBookingPopup from "./DirectBookingPopup";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoCheckmarkCircle, IoCloseOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import CreatableSelect from 'react-select/creatable';
import ProfileReviews from "../../common/ProfileReview";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const HustlerBooking = (props) => {
  const { setLoading } = props;
  const param = useParams();
  const navigate = useNavigate();
  const { id } = param;
  const socket = useSocket();

  const [showBookHustler, setshowBookHustler] = useState(false);
  const [paymentAmount, setpaymentAmount] = useState("");

  const [taskBooking, settaskBooking] = useState({
    hustlerId: "",
    taskId: "",
  });

  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedService, setSelectedService] = useState(null);
  const taskOptions = useSelector((state) => state.app.tasks);
  const [userDetail, setuserDetail] = useState({});
  const taskServices = useSelector((state) => state.app?.services);
  const [selectedTaskOption, setSelectedTaskOption] = useState("Existing task");
  const [showPaymentForm, setshowPaymentForm] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [checkboxValue, setcheckboxValue] = useState("");
  const [OpenTask, setOpenTask] = useState([]);
  const [comments, setcomments] = useState([]);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [dropdownBook, setDropdownBook] = useState({});
  const [option, setOption] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const [createTaskData, setcreateTaskData] = useState({
    id: "",
    title: "",
    dueDate: "",
    serviceName: "",
    province: "",
    cityName: "",
    budget: "",
    description: "",
    oneTimePayment: "",
    subscriptionInterval: "",
    job_image: []
  });

  
  const MAX_UPLOAD_LIMIT = 10;
  const [uploadLimitExceeded, setUploadLimitExceeded] = useState(false);

  const handleDropdownBook = (item) => {
    setDropdownBook((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([key]) => [key, false])),
      [item._id]: !prevState[item._id],
    }));
  };

  const fetchHustler = async () => {
    await authAxios()
      .get(`users/get-specific-user/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setuserDetail(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchOpentask = async () => {
    setLoading(true);
    await authAxios()
      .get("/task/tasks-created-by-director")
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setOpenTask(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchallReview = async () => {
    await authAxios()
      .get(`review/specific-user-reviews/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setcomments(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleSelectCity = (option) => {
    setcreateTaskData((prev) => ({
      ...prev,
      cityName: option,
    }));
  };

  const handleSelectProvince = (option) => {
    if (option) {
      setcreateTaskData((prev) => ({
        ...prev,
        province: option,
        cityName: "",
      }));
      setStateCode(option?.value);
    } else {
      setcreateTaskData((prev) => ({
        ...prev,
        province: "",
        cityName: "",
      }));
      setStateCode("");
    }
  };

  const handleSelectTask = (option) => {
    setcreateTaskData((prev) => ({
      ...prev,
      serviceName: option,
    }));
  };
  const handleInputChange=(value,e)=>{
    if (e.action == "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        setcreateTaskData((prev) => ({
          ...prev,
          serviceName: newOption,
        }));
      }
    }
  }

  const handleOptionTaskChange = (event) => {
    setSelectedTaskOption(event.target.value);
    fetchOpentask()
    setcreateTaskData((prev) => ({
      ...prev,
      budget: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setcreateTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMediaUpload = (e) => {
    const files = e.target.files;
    if (createTaskData.job_image.length + files.length > MAX_UPLOAD_LIMIT) {
      setUploadLimitExceeded(true);
      return;
    }

    setcreateTaskData((prevStepForm) => ({
      ...prevStepForm,
      job_image: [...prevStepForm.job_image, ...Array.from(files)],
    }));
  };

  const removeImage = (index) => {
    const updatedImages = [...createTaskData.job_image];
    updatedImages.splice(index, 1);
    setcreateTaskData((prevStepForm) => ({
      ...prevStepForm,
      job_image: updatedImages,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setshowBookHustler(true);

    if (showBookHustler == true) {
      if (createTaskData?.id) {
        //console.log("Directly Booked")

        const item = {
          hustlerId: id,
          taskId: createTaskData?.id,
        };
        DirectlyBookedHustler(item);
      } else {
        //console.log("Created Booked")

        const formData = new FormData();
        createTaskData.job_image.forEach((file) => {
          formData.append("job_image", file);
        });



        formData.append("title", createTaskData.title);
        formData.append("serviceName", toCamelCase(createTaskData?.serviceName?.value));
        formData.append("cityName", createTaskData?.cityName?.value);
        formData.append("budget", payloadBudget(`${createTaskData.budget}`));
        formData.append("province", createTaskData?.province?.label);
        formData.append("dueDate", startDate);
        formData.append("description", createTaskData.description);
        formData.append("oneTimePayment", createTaskData.oneTimePayment);
        if (!createTaskData.subscriptionInterval == "") {
          formData.append("subscriptionInterval", createTaskData.subscriptionInterval)
        }
        formData.append("status", "open");



        setLoading(true);
        await authAxios()
          .post(`/task/create-tasks`, formData)
          .then((response) => {
            const resData = response.data;
            if (resData.status == 1) {
              toast.success(resData?.message);
              setLoading(false);
              const item = {
                hustlerId: id,
                taskId: resData?.data?._id,
              };
              DirectlyBookedHustler(item);
            } else {
              setLoading(false);
              toast.error(resData.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message);
          });
      }
    }
  };

  const handleStripeAccountConnect = async (item) => {
    await authAxios()
      .post(`/stripe/create-customer-account`)
      .then((response) => {
        const resData = response.data;

        if (resData.status == 1) {
          handlePayment(item);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handlePayment = async (item) => {
    const resData = await authAxios()
      .get(`/stripe/check-stripe-account`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.data.status === 0) {
          handleStripeAccountConnect(item);
        } else {
          toast.error(error.response.data.message);
        }
      });

    if (
      resData &&
      resData.status === 1
    ) {
      //  console.log(item)


      const paymentIntentResponse = await authAxios().post(
        `/stripe/create-payment-intent`,
        {
          amount: item?.amount * 100,

          currency: "cad",
          transferGroup: item?.transferGroup,
        }
      );

      const paymentIntentResData = paymentIntentResponse.data;
      if (paymentIntentResData.status === 1) {
        setPaymentIntentId(paymentIntentResData.data.client_secret);
        setLoading(false);
        setshowPaymentForm(true);
        setshowBookHustler(false);
      } else {
        toast.error(paymentIntentResData.message);
      }
    }
  };

  const DirectlyBookedHustler = async (item) => {
    const payload = {
      hustlerId: item?.hustlerId,
      taskId: item?.taskId,
    };
    setLoading(true);

    await authAxios()
      .post(`/bookhustler/check-hustler-interest`, payload)
      .then(async (response) => {
        const resData = response.data;
        console.log(resData, "resdata")
        if (resData.status === 1) {
          await bookingHustler(payload);
        } else {
          toast.info(resData.message)
          setLoading(false);
          setshowBookHustler(false);
          setcheckboxValue("");
          setcreateTaskData((prev) => ({
            ...prev,
            id: "",
          }));
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const bookingHustler = async (payload) => {
    await authAxios()
      .post(`/bookhustler/book-hustler`, payload)
      .then((response) => {
        const resData = response.data;
        if (resData.status === 1) {
          socket.emit("directlyHustlerBooked", resData.data);
          setLoading(false);
          toast.success(resData.message);
          setshowBookHustler(false);
          // navigate("/director-thanks");
          console.log("/your-task-list","1233")

          // navigate("/your-task-list");
          navigate("/your-task-list", { state: { id: "in-progress" } });


        } else {
          setLoading(false);
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  
  const handleOpenJob = (event) => {
    setcreateTaskData((prev) => ({
      ...prev,
      id: event._id,
      budget: event.budget,
      /* title: event.title,
       dueDate: event.dueDate,
       serviceName: event.serviceName,
       cityName: event.cityName,
       budget: event.budget,
       description: event.description,
       */
    }));
    setcheckboxValue(event._id === checkboxValue ? "" : event._id);

    setshowBookHustler(true);
  };

  const handleCancelPopup = () => {
    setshowBookHustler(false);
    setcheckboxValue("");
    setcreateTaskData((prev) => ({
      ...prev,
      id: "",
    }));
  };

  const handleOptionChange = (event) => {
    // console.log("ev", event.target.id);

    if (event.target.id == "true") {
      setcreateTaskData((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
        subscriptionInterval: "",
      }));
    } else {
      setcreateTaskData((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
      }));
    }
  };

  const handleSubscriptionChange = (option) => {
    setcreateTaskData((prev) => ({
      ...prev,
      subscriptionInterval: `${option}`,
      oneTimePayment: "false",
    }));
  };

  useEffect(() => {
    fetchHustler();
    fetchOpentask();
    fetchallReview();
  }, []);

  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);

  useEffect(() => {
    setcityList([])
    if(stateCode=="BC"){
        setcityList(
          [ {"value": "Kamloops", "label": "Kamloops"},
            {"value": "Kelowna","label": "Kelowna"},
            {"value": "Peachland","label": "Peachland"},
            {"value": "Penticton","label": "Penticton"},
            {"value": "Vernon","label": "Vernon"},
            {"value": "West Kelowna","label": "West Kelowna"},
          ]
        )
      }
      else{
    
        setcityList(
          [ {value: "Saskatoon",label: "Saskatoon"}
          ]
        )
    
    
      }
      if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  return (
    <>
      <div className="h-100px"></div>

      <section className="container--profile--view">
        <div className="profile--view--header" style={{ display: "block" }}>
          <div className="profile--info" style={{ width: "100%", maxWidth:'695px' }}>
            <div className="thumbnail--profile">
              <div className="thumbnail--profile--image">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail?.profilePic}`}
                  alt="Profile"
                />
              </div>

              {showBookHustler && (
                <DirectBookingPopup
                  handleSubmit={handleSubmit}
                  //setshowBookHustler={setshowBookHustler}
                  handleCancelPopup={handleCancelPopup}
                />
              )}

              

              <div className="thumbnail--info">
                <h2 style={{ marginBottom: "0px" }}>
                  {userDetail?.fname} {userDetail?.lname}{" "}
                </h2>
                <p className="location--hustler">
                  {userDetail?.hustlerDetails?.province},{" "}
                  {userDetail?.hustlerDetails?.location}
                </p>
                {/* <h6>{userDetail?.hustlerDetails?.serviceName}</h6> */}
                <div>
                  <p className="search--category mb-1">
                    <img src="/assets/images/hustler--taskdone.png" />{" "}
                    {userDetail?.hustlerDetails?.completedTask} Completed Jobs
                  </p>
                  <p className="starting--price mb-1">
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.hustlerDetails?.ratings)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="profile--content--area hustiler--booktaks">
          <div className="profile--content--leftside" style={{width: "100%",maxWidth:'625px'}}>
            <div className="profile--category--work hide-991">
              <div className="profile--sarvice--category" style={{display:'block'}}>
                {userDetail?.hustlerDetails?.services.length > 0 && userDetail?.hustlerDetails?.services.map((service, index, array) => (
                  <span key={service._id}>{service.serviceName}{index !== array.length - 1 && ', '}</span>
                ))}
              </div>
              <ul>
                {/* {userDetail?.hustlerDetails?.category.map((item) => (
                  <li>{item.label}</li>
                ))} */}
                {/* {userDetail?.hustlerDetails?.services && userDetail?.hustlerDetails?.services.length > 0 && (
                  <>
                    {userDetail?.hustlerDetails?.services[0].category.slice(0, 2).map(category => (
                      <li key={category._id}>{category.label}</li>
                    ))}
                  </>
                )} */}

                {userDetail?.hustlerDetails?.services.map(service => (
                  <>
                    {service.category.map(category => (
                      <li key={category._id} style={{ textTransform: 'capitalize' }}>
                        {category.label}
                      </li>
                    ))}
                  </>
                ))}
              </ul>
            </div>

            <div className="profile--description pb-2 hide-991">
              <p>{userDetail?.description}</p>
            </div>
            {/* {comments?
            <ProfileReviews userDetail={userDetail} comments={comments} />
            :"No comments"
            } */}
            <ProfileReviews userDetail={userDetail} comments={comments} />

          </div>

          <div className="profile--content--rightside">

            <div className="mb-info">
              <div className="profile--category--work">
                <ul>
                  {/* {userDetail?.hustlerDetails?.category.map((item) => (
                    <li>{item.label}</li>
                  ))} */}
                  {userDetail?.hustlerDetails?.services && userDetail?.hustlerDetails?.services.length > 0 && (
                    <>
                      {userDetail?.hustlerDetails?.services[0].category.slice(0, 2).map(category => (
                        <li key={category._id}>{category.label}</li>
                      ))}
                    </>
                  )}
                </ul>
              </div>

              <div className="profile--description pb-2">
                <p>{userDetail?.description}</p>
              </div>
            </div>


            <div className="select--your--details--form">
              <div className="select--task--option">
                <label className="custom--ratio">
                  Choose Existing Job
                  <input
                    type="radio"
                    id="existingtask"
                    name="select_task_option"
                    value="Existing task"
                    checked={selectedTaskOption === "Existing task"}
                    onChange={handleOptionTaskChange}
                  />
                  <span class="circlemark"></span>
                </label>

                <label className="custom--ratio">
                  Create New Job
                  <input
                    type="radio"
                    id="createtask"
                    name="select_task_option"
                    value="Create Task"
                    checked={selectedTaskOption === "Create Task"}
                    onChange={handleOptionTaskChange}
                  />
                  <span class="circlemark"></span>
                </label>
              </div>

              <div className="form--field--qoutation pt-3">
                {selectedTaskOption === "Existing task" && (
                  <div className="list--createdtask">
                    <h5 className="mb-3">Select Your Existing Job</h5>

                    <div className="list--tasks">
                      {OpenTask && OpenTask?.length > 0 ? (
                        OpenTask.map((item, index) => (
                          <div key={index} className="created--task--lists">
                            <div
                              className="task--items--header"
                              style={{ alignItems: "flex-start" }}
                            >
                              <div className="task--items--header--details hustler--book--direct max-w-991">
                                <h2>{item?.title}</h2>
                                <div className="service--category--task">
                                  <h3>{item.serviceName} </h3>
                                </div>
                              </div>
                              <div className="task--items--edit--delete" style={{width:'auto'}}>

                                <div className="edit--taks--items">
                                  <Tooltip
                                    id="view--task-d"
                                    className="tooltip--task--wishlist"
                                  />
                                  <Link
                                    className="delete--taks--items"
                                    data-tooltip-id="view--task-d"
                                    data-tooltip-content="View Job Details"
                                    to={`/task/${item._id}`}
                                    state={{ id: "view-task-hire" }}
                                  >
                                    {" "}
                                    <MdOutlineRemoveRedEye color="white" />
                                  </Link>
                                </div>

                                {/* <div
                                  className="edit--taks--items"
                                  style={{ backgroundColor: '#f0f0f0', position: 'relative' }}
                                  onClick={() => handleDropdownBook(item)}
                                >
                                  {" "}
                                  <BsThreeDotsVertical color="#1F1F28" />

                                  {dropdownBook[item._id] && (
                                    <div className="dropdown--items">
                                      <ul>
                                        <li
                                          onClick={() => handleOpenJob(item)}
                                        >Book Hustler</li>
                                      </ul>
                                    </div>

                                  )}

                                </div> */}

                              </div>
                            </div>

                            <div className="task--items--content">
                              <p>
                                <span className="task--items--content--icon">
                                  <TbMoneybag />
                                </span>{" "}
                                Budget: ${BudgetFormat(`${item?.budget}`)}
                              </p>
                              <p>
                                <span className="task--items--content--icon">
                                  <GrLocation />
                                </span>{" "}
                                Area: {item?.cityName}
                              </p>
                            </div>

                            <div className="task--items-footer">
                              {item?.description
                                ? item?.description?.length > 200 ? `${item?.description?.substring(0, 200)}...` : `${item?.description}`
                                : "No description available"}
                            </div>

                            <div className="flex">
                              <button
                                className="custom--btn mt-3 darkbtn-hover mini-btn ml-auto"
                                style={{ maxWidth: '150px' }}
                                onClick={() => handleOpenJob(item)}>
                                <span>
                                  Hire Now
                                </span>
                              </button>
                            </div>
                            {item?.oneTimePayment ? null : (
                              <div className="task--timing">
                                <p>Please note that this is a <b>{item?.subscriptionInterval}ly</b> job, and payment will be made on a <b>{item?.subscriptionInterval}ly</b> basis upon completion period. </p>
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div>No jobs to display</div>
                      )}
                    </div>
                  </div>
                )}

                {selectedTaskOption === "Create Task" && (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="zIndex2">
                        <div className="form--select--field">
                          <label className="mb-2">Is this job a one-time occurrence? <span className="mendotry-field">*</span></label>

                          <div className="job--type">
                            <div className="one--time--payment">
                              <div className="ratio--box">
                                <div className="radio--custom">
                                  <input
                                    type="radio"
                                    required
                                    id="true"
                                    name="option"
                                    value={createTaskData.oneTimePayment}
                                    checked={createTaskData.oneTimePayment == "true"}
                                    onChange={handleOptionChange}
                                  />
                                  <span className="radio"></span>
                                </div>
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div className="ratio--box">
                                <div className="radio--custom">
                                  <input
                                    type="radio"
                                    required
                                    id="false"
                                    name="option"
                                    value={createTaskData.oneTimePayment}
                                    checked={createTaskData.oneTimePayment == "false"}
                                    onChange={handleOptionChange}
                                  />
                                  <span className="radio"></span>
                                </div>
                                <label htmlFor="no">No</label>
                              </div>
                            </div>

                            {createTaskData.oneTimePayment === "false" ? (
                              <>
                                <label className="mt-3 mb-2">
                                  How frequently would you like this job to repeat?{" "}
                                  <span className="mendotry-field">*</span>
                                </label>
                                <div className="what--subscription">
                                  <label
                                    className={`ratio--box custom--btn minbutton ${createTaskData.subscriptionInterval === "week"
                                      ? "darkbtn-hover"
                                      : "ligghtbtn-hover transparent--btn"
                                      }`}
                                    onClick={() => handleSubscriptionChange("week")}
                                  >
                                    <input

                                      type="radio"
                                      required
                                      name="subcriptionoption"
                                      id="week"
                                      checked={createTaskData.subscriptionInterval == "week"}
                                    />

                                    <span>
                                      {createTaskData.subscriptionInterval == "week" && (
                                        <IoCheckmarkCircle />
                                      )}{" "}
                                      Weekly
                                    </span>
                                  </label>

                                  <label
                                    className={`ratio--box custom--btn minbutton ${createTaskData.subscriptionInterval === "month"
                                      ? "darkbtn-hover"
                                      : "ligghtbtn-hover transparent--btn"
                                      }`}
                                    onClick={() =>
                                      handleSubscriptionChange("month")
                                    }
                                  >
                                    <input
                                      type="radio"
                                      required
                                      name="subcriptionoption"
                                      id="month"
                                      checked={createTaskData.subscriptionInterval ===
                                        "month"}
                                    />
                                    <span>
                                      {createTaskData.subscriptionInterval ===
                                        "month" && <IoCheckmarkCircle />}{" "}
                                      Monthly
                                    </span>
                                  </label>
                                  {/* 
                                  <label
                                    className={`ratio--box custom--btn minbutton ${createTaskData.subscriptionInterval === "year"
                                      ? "darkbtn-hover"
                                      : "ligghtbtn-hover transparent--btn"
                                      }`}
                                    onClick={() => handleSubscriptionChange("year")}
                                  >
                                    <input
                                      type="radio"
                                      required
                                      name="subcriptionoption"
                                      id="year"
                                      checked={createTaskData.subscriptionInterval === "year"}
                                    />
                                    <span>
                                      {createTaskData.subscriptionInterval === "year" && (
                                        <IoCheckmarkCircle />
                                      )}{" "}
                                      Yearly
                                    </span>
                                  </label> */}
                                </div>
                              </>
                            ) : null}
                          </div>

                        </div>

                        <div className="form--select--field">
                          <label className="mb-2">What date would you like the task to be started? <span className="mendotry-field">*</span></label>
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            value={startDate}
                            minDate={new Date()}
                            onChange={(date) => setStartDate(date)}
                            required
                            dateFormat="MMM-dd-yy"
                          />
                        </div>

                        {createTaskData.oneTimePayment === "false" ? (
                          <> 

                          {createTaskData.subscriptionInterval? <p>Your job will reoccur <b>{createTaskData.subscriptionInterval=="week"? 'weekly':'monthly'}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>  : <p>Your job will reoccur <b>{`weekly/monthly`}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>}
                         

                          </>
                        ) : null}


                        <div className="form--select--field">
                          <label className="mb-2">Enter Job Title <span className="mendotry-field">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Job Title"
                            name="title"
                            required
                            value={createTaskData.title}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form--select--field">
                          <label className="mb-2">Select Job Type <span className="mendotry-field">*</span></label>
                          <CreatableSelect
                            options={taskServices}
                            isClearable
                            className="select--custom"
                            value={createTaskData.serviceName}
                            placeholder={`I need help with`}
                            onChange={handleSelectTask}
                            onInputChange={handleInputChange}
                            
                            required
                          />
                        </div>

                        <div className="form--select--field">
                          <label className="mb-2">Select Province <span className="mendotry-field">*</span></label>
                          <Select
                            options={provinceList}
                            isClearable={false}
                            className="select--custom province--select"
                            value={createTaskData.province}
                            placeholder={`Select province`}
                            onChange={handleSelectProvince}
                            required
                          />
                        </div>

                        <div className="form--select--field">
                          <label className="mb-2">Select Area <span className="mendotry-field">*</span></label>
                          <Select
                            options={cityList}
                            isClearable
                            className="select--custom"
                            value={createTaskData.cityName}
                            placeholder={`Select City`}
                            onChange={handleSelectCity}
                            required
                          />
                        </div>

                        <div className="form--select--field">
                          <label className="mb-2">Enter Budget <span className="mendotry-field">*</span></label>

                          <div className="input--dolldar">
                            <span className="dollar-icon">$</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="0"
                              onChange={handleChange}
                              value={displayBudget(createTaskData.budget)}
                              name="budget"
                              required
                            />
                          </div>
                        </div>

                        <div className="form--select--field">
                          <label className="mb-2">Add Your Images</label>
                          <div className="upload--media">
                            <div className="btn btn-dark darkbtn-hover">
                              Upload Media
                            </div>
                            <input
                              type="file"
                              name="mediaupload"
                              multiple="multiple"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={handleMediaUpload}
                            />
                          </div>

                          <div className="media--list--task">
                            {createTaskData.job_image.map((image, index) => (
                              <div className="media--item" key={index}>
                                <p
                                  className="remove--media"
                                  onClick={() => removeImage(index)}
                                >
                                  <IoCloseOutline />
                                </p>
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Image ${index + 1}`}
                                />
                              </div>
                            ))}
                          </div>

                          {uploadLimitExceeded && (
                            <p style={{ color: "red" }} className="pt-3">
                              Upload limit exceeded. You can upload a maximum of{" "}
                              {MAX_UPLOAD_LIMIT} images.
                            </p>
                          )}

                        </div>

                        <div className="form--select--field">
                          <label className="mb-2">Add Your Description <span className="mendotry-field">*</span></label>
                          <textarea
                            className="p-2"
                            name="description"
                            onChange={handleChange}
                            required
                            value={createTaskData.description}
                          ></textarea>
                        </div>
                      </div>

                      <div className="submit--btn">
                        <button
                          type="submit"
                          className="custom--btn ligghtbtn-hover transparent--btn"
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IsLoadingHOC(HustlerBooking);
