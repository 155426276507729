import React, { useEffect, useState } from "react";
import { CanadianCity } from "../../utils/constants";
import Select from "react-select";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { setReportFormatDate } from "../../assets/Helper";
import { State, City } from "country-state-city";
import { displayBudget, payloadBudget } from "../../utils/helper";
import { IoCheckmarkCircle, IoCloseOutline } from "react-icons/io5";
import CreatableSelect from 'react-select/creatable';

const EditcreateTask = ({ singleTask, setshoweditModal, EditupdateTask }) => {
  const taskServices = useSelector((state) => state.app?.services);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [option, setOption] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const MAX_UPLOAD_LIMIT = 10;
  const [uploadLimitExceeded, setUploadLimitExceeded] = useState(false);

  const [data, setdata] = useState({
    id: "",
    title: "",
    serviceName: "",
    cityName: "",
    province: "",
    budget: "",
    dueDate: "",
    oneTimePayment: "",
    subscriptionInterval: "",
    description: "",
    prev_images: [],
    job_image: [],
  });

  const [startDate, setstartDate] = useState(new Date());


  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectCity = (selectedOption) => {
    if (selectedOption) {
      setdata((prev) => ({
        ...prev,
        cityName: {
          label: selectedOption.label,
          value: selectedOption.value,
        },
      }));
    } else {
      setdata((prev) => ({
        ...prev,
        cityName: "",
      }));
    }
  };

  const handleSelectService = (selectedOption) => {
    if (selectedOption) {
      setdata((prev) => ({
        ...prev,
        serviceName: {
          label: selectedOption.label,
          value: selectedOption.value,
        },
      }));
    } else {
      setdata((prev) => ({
        ...prev,
        serviceName: "",
      }));
    }
  };

  const handleInputChange=(value,e)=>{
    if (e.action == "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        setdata((prev) => ({
          ...prev,
          serviceName: newOption
        }));
      }
    }
  }

  const handleSelectProvince = (option) => {
    if (option) {
      setdata((prev) => ({
        ...prev,
        province: option,
        cityName: "",
      }));

      setStateCode(option.value);
      setcityList([]);
    } else {
      setdata((prev) => ({
        ...prev,
        province: "",
        cityName: "",
      }));
      setStateCode("");
      setcityList([]);
    }
  };

  const handleOptionChange = (event) => {
    if (event.target.id == "true") {
      setdata((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
        subscriptionInterval: "",
      }));
    } else {
      setdata((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
      }));
    }
  };

  const handleSubscriptionChange = (option) => {
    setdata((prev) => ({
      ...prev,
      subscriptionInterval: `${option}`,
      oneTimePayment: "false",
    }));
  };

  const handleMediaUpload = (e) => {
    const files = e.target.files;
    if (data.job_image.length + files.length > MAX_UPLOAD_LIMIT) {
      setUploadLimitExceeded(true);
      return;
    }


    setdata((prevStepForm) => ({
      ...prevStepForm,
      job_image: [...prevStepForm.job_image, ...Array.from(files)],
    }));
  };


  const removeImage = (index) => {
    const updatedImages = [...data.job_image];
    updatedImages.splice(index, 1);
    setdata((prevStepForm) => ({
      ...prevStepForm,
      job_image: updatedImages,
    }));
  };

  const handleRemovePrevimages = (image) => {

    const prevImagesFilter = data.prev_images.filter(item => item !== `${image}`);


    setdata((prev) => ({
      ...prev,
      prev_images: prevImagesFilter
    }))


  };


  const handleSubmit = (e) => {
    e.preventDefault();



    const mergeImagesarray = data.prev_images.concat(data.job_image)



    const payload = {
      id: data.id,
      title: data.title,
      serviceName: data.serviceName.value,
      province: data.province.label,
      cityName: data.cityName.value,
      job_image: mergeImagesarray,
      budget: payloadBudget(`${data.budget}`), //payloadBudget(data.budget),
       dueDate: startDate,

      oneTimePayment: data.oneTimePayment,
      subscriptionInterval: data.subscriptionInterval,
      description: data.description,
    };

    EditupdateTask(payload);
  };

  useEffect(() => {
    const ignoreCity = {
      label: singleTask.cityName,
      value: singleTask.cityName,
    };

    const ignoreService = {
      label: singleTask.serviceName,
      value: singleTask.serviceName,
    };

    const ignoreProvince = {
      label: singleTask.province,
      value: singleTask.province,
    };

    setdata((prev) => ({
      ...prev,
      id: singleTask._id || "",
      title: singleTask.title || "",
      serviceName: ignoreService || "",
      province: ignoreProvince || "",
      cityName: ignoreCity || "",
      budget: singleTask.budget || "",
      description: singleTask.description || "",
      dueDate: singleTask.dueDate,
      prev_images: singleTask.attachments || "",
      oneTimePayment: `${singleTask.oneTimePayment}` || "",
      subscriptionInterval: singleTask.subscriptionInterval || ""
    }));
    setstartDate(new Date(singleTask.dueDate));



    const data = State.getStatesOfCountry('CA').map(({isoCode, name}) => ({
      value: isoCode,
      label: name,
    }));

    const filterData = data.filter(item => item.label == ignoreProvince.value);
    
    // setcityList(
    //   City.getCitiesOfState('CA', filterData[0]?.value).map(({name}) => ({
    //     value: name,
    //     label: name,
    //   }))
    // );
  }, []);


  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);

  useEffect(() => {
    setcityList([])
    if(stateCode=="BC"){
        setcityList(
          [ {"value": "Kamloops", "label": "Kamloops"},
            {"value": "Kelowna","label": "Kelowna"},
            {"value": "Peachland","label": "Peachland"},
            {"value": "Penticton","label": "Penticton"},
            {"value": "Vernon","label": "Vernon"},
            {"value": "West Kelowna","label": "West Kelowna"},
          ]
        )
      }
      else{
    
        setcityList(
          [ {value: "Saskatoon",label: "Saskatoon"}
          ]
        )
    
    
      }
      if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  const handleStartDateChange = (date) => {
    setstartDate(date);
    if (data.subscriptionInterval === 'month') {
      const nextMonthDate = new Date(date);
      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1); // Move to the next month
      // nextMonthDate.setDate(nextMonthDate.getDate() - 1); // Subtract one day

  } else {
  }
  };



  return (
    <>
      <div
        className={`edit-created--task modal ${singleTask ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Edit Created Task</h5>
                <button
                  onClick={() => setshoweditModal(false)}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <label htmlFor="Budget" className="mb-2">
                    Is this job a one-time occurrence? <span className="mendotry-field">*</span>
                  </label>
                  <br />

                  <div className="job--type">
                    <div className="one--time--payment">
                      <div className="ratio--box">
                        <div className="radio--custom">
                          <input
                            type="radio"
                            required
                            id="true"
                            name="option"
                            value={data.oneTimePayment}
                            checked={data.oneTimePayment == "true"}
                            onChange={handleOptionChange}
                          />
                          <span className="radio"></span>
                        </div>
                        <label htmlFor="yes">Yes</label>
                      </div>
                      <div className="ratio--box">
                        <div className="radio--custom">
                          <input
                            type="radio"
                            required
                            id="false"
                            name="option"
                            value={data.oneTimePayment}
                            checked={data.oneTimePayment == "false"}
                            onChange={handleOptionChange}
                          />
                          <span className="radio"></span>
                        </div>
                        <label htmlFor="no">No</label>
                      </div>
                    </div>

                    {data.oneTimePayment === "false" ? (
                      <>
                        <label className="mt-3 mb-2">
                          How frequently would you like this job to repeat?{" "}
                          <span className="mendotry-field">*</span>
                        </label>
                        <div className="what--subscription">
                          <label
                            className={`ratio--box custom--btn minbutton ${data.subscriptionInterval === "week"
                              ? "darkbtn-hover"
                              : "ligghtbtn-hover transparent--btn"
                              }`}
                            onClick={() => handleSubscriptionChange("week")}
                          >
                            <input
                              type="radio"
                              required
                              name="subcriptionoption"
                              id="week"
                              checked={data.subscriptionInterval == "week"}
                            />

                            <span>
                              {data.subscriptionInterval == "week" && (
                                <IoCheckmarkCircle />
                              )}{" "}
                              Weekly
                            </span>
                          </label>

                          <label
                            className={`ratio--box custom--btn minbutton ${data.subscriptionInterval === "month"
                              ? "darkbtn-hover"
                              : "ligghtbtn-hover transparent--btn"
                              }`}
                            onClick={() => handleSubscriptionChange("month")}
                          >
                            <input
                              type="radio"
                              required
                              name="subcriptionoption"
                              id="month"
                              checked={data.subscriptionInterval === "month"}
                            />
                            <span>
                              {data.subscriptionInterval === "month" && (
                                <IoCheckmarkCircle />
                              )}{" "}
                              Monthly
                            </span>
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="Select Date" className="mb-2">
                    What date would you like the task to be started?{" "}
                    <span className="mendotry-field">*</span>
                  </label>
                  <br />
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    // onChange={(date) => setstartDate(date)}
                    onChange={handleStartDateChange}

                    minDate={new Date()}
                    required
                    dateFormat="MMM-dd-yy"
                  />
                </div>


                {data.oneTimePayment === "false" ? (<>  
                  {data.subscriptionInterval? <p>Your job will reoccur <b>{data.subscriptionInterval=="week"? 'weekly':'monthly'}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>  : <p>Your job will reoccur <b>{`weekly/monthly`}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>}

                  </>
                ):null}

                <div className="form-group mb-3">
                  <label htmlFor="Budget" className="mb-2">
                    Title <span className="mendotry-field">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    required
                    className="form-control"
                    name="title"
                    onChange={handleChange}
                    value={data.title}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Select Job" className="mb-2">
                    Select Service <span className="mendotry-field">*</span>
                  </label>
                  <CreatableSelect
                    required
                    options={taskServices}
                    className="select--custom"
                    placeholder={`Service`}
                    value={data.serviceName}
                    onChange={handleSelectService}
                    onInputChange={handleInputChange}
                    isClearable

                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Select City" className="mb-2">
                    Select Province <span className="mendotry-field">*</span>
                  </label>
                  <Select
                    required
                    options={provinceList}
                    isClearable={false}
                    className="select--custom province--select"
                    value={data.province}
                    placeholder={`Select Province`}
                    onChange={handleSelectProvince}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Select City" className="mb-2">
                    Select City <span className="mendotry-field">*</span>
                  </label>
                  <Select
                    required
                    options={cityList}
                    isClearable
                    className="select--custom"
                    value={data.cityName}
                    placeholder={`City / Zip`}
                    onChange={handleSelectCity}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Budget" className="mb-2">
                    Budget <span className="mendotry-field">*</span>
                  </label>
                  <br />

                  <div className="input--dolldar">
                    <span className="dollar-icon">$</span>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={displayBudget(`${data.budget}`)}
                      name="budget"
                      onChange={handleChange}
                      placeholder="0"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Add Your Images" className="mb-2">
                    Add Your Images
                  </label>
                  <br />
                  <div className="upload--media">
                    <div className="btn btn-dark darkbtn-hover">
                      Upload Media
                    </div>
                    <input
                      type="file"
                      name="mediaupload"
                      multiple="multiple"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleMediaUpload}
                    />
                  </div>

                  <div className="media--list--task">
                    {data.prev_images.map((item, index) => (
                      <div className="media--item" key={index}>
                        <p
                          className="remove--media"
                          onClick={() => handleRemovePrevimages(item)}

                        //    onClick={() => removeImage(index)}
                        >
                          <IoCloseOutline />
                        </p>
                        <img src={`${process.env.REACT_APP_IMAGE_URL}job_attachments/${item}`} />
                      </div>
                    ))}

                    {data.job_image.map((image, index) => (
                      <div className="media--item" key={index}>
                        <p
                          className="remove--media"
                          onClick={() => removeImage(index)}
                        >
                          <IoCloseOutline />
                        </p>

                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Image ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>

                  {uploadLimitExceeded && (
                    <p style={{ color: "red" }} className="pt-3">
                      Upload limit exceeded. You can upload a maximum of{" "}
                      {MAX_UPLOAD_LIMIT} images.
                    </p>
                  )}

                </div>

                <div className="form-group">
                  <label htmlFor="Description" className="mb-2">
                    Description <span className="mendotry-field">*</span>
                  </label>
                  <br />
                  <textarea
                    name="description"
                    value={data.description}
                    onChange={handleChange}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-dark darkbtn-hover">
                  <span>Save Changes</span>
                </button>
                <button
                  onClick={() => setshoweditModal(false)}
                  type="button"
                  className="custom--btn ligghtbtn-hover transparent--btn"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop ${singleTask ? "show" : ""}`}></div>
    </>
  );
};

export default EditcreateTask;
