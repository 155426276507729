import React, { useState, useEffect } from "react";
import Select from "react-select";
import Header from "../common/header";
import { IoIosSearch } from "react-icons/io";
import TestimonialSlider from "../component/TestimonialSlider";
import VideoHome from "../component/VideoHome";
import TrendingSlider from "../component/TrendingSlider";
import Footer from "../common/footer";
import { useDispatch, useSelector } from "react-redux";
import { saveTaskOptions } from "../Redux/Reducers/appSlice";
import { CanadianCity } from "../utils/constants";
import IsLoadingHOC from "../common/IsLoadingHOC";
import { withoutAuthAxios, authAxios } from "../services/config";
import { toast } from "react-toastify";
import LandingPage from "./LandingPage";
import SearchResult from "./SearchResult";
import { Link, useNavigate } from "react-router-dom";
import { State, City } from "country-state-city";

function Home(props) {
  const { setLoading } = props;

  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState(null);

  const [categoryList, setcategoryList] = useState([]);
  const taskServices = useSelector((state) => state.app?.services);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCode, setStateCode] = useState("");

  const taskCatgeory = useSelector((state) => state.app?.services);


  const fetchcategorylist = async () => {
    setLoading(true);
    await authAxios()
      .get("task/list-random-categories")
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setcategoryList(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleClick = (item) => {
    setSelectedService(item?.label);
  };

  const handleSelectTask = (option) => {
    setSelectedService(option);
    if (!option) {
    }
  };

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleSelectCity = (option) => {
    setSelectedCity(option);
    if (!option) {
    }
  };

  useEffect(() => {
    //fetchcategorylist();
  }, []);

  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);

  useEffect(() => {
    setcityList([])
if(stateCode=="BC"){
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )
  }
  else{

    setcityList(
      [ {value: "Saskatoon",label: "Saskatoon"}
      ]
    )


  }


    // if (stateCode) {
    //   setcityList(
    //     City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
    //       value: name,
    //       label: name,
    //     }))
    //   );
    // }
  },[stateCode]);

//   How To SideHustl:
// Create the job you’d like to delegate
// Assign the job to a qualified Hustlr of your choice
// Schedule, chat, pay, review and even re-hire through one reliable platform

// Create an account and offload your to-do list in just a few clicks		[ Get Started ] 


  const howWorks = [
    {
        id: 1,
        name: "Create Job",
        description: "Create the job you’d like to delegate."
    },
    {
        id: 2,
        name: " Assign Job",
        description: "Assign the job to a qualified Hustlr of your choice."
    },
    {
        id: 3,
        name: "Manage Your Process",
        description: "Schedule, chat, pay, review and even re-hire through one reliable platform."
    },
    // {
    //     id: 4,
    //     name: "Pay registration fee",
    //     description: "In applicable cities, we charge a $25 registration fee that helps us provide the best service to you."
    // },
    // {
    //     id: 5,
    //     name: "Set your schedule and area",
    //     description: "Set your weekly availability and opt in to receive same-day jobs."
    // },
    // {
    //     id: 4,
    //     name: "Manage Your Tasks",
    //     description: "Schedule, chat, get paid, reviewed and even re-hired through one reliable platform."
    // },
];
  return (
    <>
      <section
        className="banner--top common--top--banner"
        style={{ position: "relative", backgroundColor: "var(--lightgray)" }}
      >
        <div
          className="container"
          style={{ zIndex: "2", position: "relative" }}
        >
          <div className="row">
            <div className="banner--content " style={{marginTop:"55px"}}>
              <h1
                className="playFairDisplay text-center heading-h1 mb-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Less Hassle, More Hustl
              </h1>
              <h5
                className="playFairDisplay text-center  mb-4 mt-0"
              >
                {/* Search Your Job Here */}
                Find The Right Person For Your Job

              </h5>
              <div onClick={() => navigate("/login")}>
                <div
                  className="search--bar--banner flex justify-center sm-flex-col sm-justify-start"
                  // data-aos="fade-up"
                  // data-aos-offset="300"
                  // data-aos-duration="900"
                >
                  <div className="need--select">
                    <input
                      required
                      placeholder="I need help with..."
                      className="need--select custom--input"
                      value={selectedService}

                      onChange={(e) => setSelectedService(e.target.value)}
                    />

                  </div>
                  <div className="city--zip">
                    <Select
                      options={provinceList}
                      isClearable={false}
                      
                      className="select--custom province--select"
                      placeholder={`Select Province`}
                      value={selectedProvince}
                      onChange={handleSelectProvince}
                    // isSearchable={false}
                    />
                  </div>

                  <div className="city--zip">
                    <Select
                      // options={cityList}
                      options={selectedProvince?cityList:[]}

                      isClearable
                      className="select--custom"
                      placeholder={`Select City`}
                      value={selectedCity}
                      onChange={handleSelectCity}
                    // isSearchable={false}
                    />
                  </div>

                  {selectedService && selectedCity?.label ? (
                    <button
                      className="btn btn-dark submit--need"
                      onClick={() => navigate("/login")}
                    >
                      Get help today
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark submit--need disabled"
                      onClick={() => navigate("/login")}
                      disabled
                    >
                      Get help today
                    </button>
                  )}
                </div>

               <div
                  className="category--searchlist mt-50"
                >
                                      {/* <Link to="/signup"         state={{ id: "director" }}
 className='custom--btn darkbtn-hover flex mx-auto mt-4'
 data-aos="fade-up"
 data-aos-offset="100"
 data-aos-duration="600"

 style={{
  justifyContent: 'center',
  maxWidth: '180px',
  padding: '10px 20px', 
  fontSize: '15px'
}}
                  >
                        <span>Create a job now!</span>
                    </Link> */}

                    <Link to="/signup" state={{ id: "director" }} style={{marginTop: "40px", display: "block", textAlign: "center",justifyContent:'center'}} className='custom--btn darkbtn-hover flex mx-auto mt-25'

                    >
                        <span>Create a job now!</span>
                    </Link>

                    {/* <Link
  to="/signup"
  state={{ id: "director" }}
  className="custom--btn darkbtn-hover flex mx-auto mt-4"
  style={{
    justifyContent: 'center',
    maxWidth: '180px', 
    padding: '10px 20px', 
    fontSize: '15px' 
  }}
>
  <span>Create a job now!</span>
</Link> */}




                </div>

                {/* <div
                  className="category--searchlist"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-duration="2000"
                >
                  <ul>
                    {taskCatgeory &&
                      taskCatgeory.length > 0 &&
                      taskCatgeory.map((item, index) => (
                        <li key={index + 1} style={{ textTransform: 'capitalize', cursor: 'pointer' }}>
                          <a onClick={() => handleClick(item)}>
                            {item.label}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div> */}

              </div>


            </div>
          </div>
        </div>

        <ul className="bubble--container" style={{ zIndex: "1" }}>
          <img
            src="./assets/images/banner--image/img-1.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-2.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-4.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-3.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-5.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-6.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-7.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-8.png"
            class="bubble"
            alt="img-2"
          />
        </ul>
      </section>
      {/* <SearchResult /> */}

      <TrendingSlider />

      {/* <section className="py-100" style={{ backgroundColor: "#f8f9fa" }}>
  <div className="custom--container m-auto text-center">
    <h3
      className="playFairDisplay heading-h3"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      How To SideHustl
    </h3>
    <p
      className="playFairDisplay text-center mb-20px"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-duration="1000"
    >
      Create the job you’d like to delegate
    </p>
    <p
      className="playFairDisplay text-center mb-20px"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-duration="1100"
    >
      Assign the job to a qualified Hustlr of your choice
    </p>
    <p
      className="playFairDisplay text-center mb-20px"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-duration="1100"
    >
      Schedule, chat, pay, review and even re-hire through one reliable platform
    </p>
    <p
      className="playFairDisplay text-center mb-20px"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-duration="1100"
    >
      Create an account and offload your to-do list in just a few clicks
    </p>
    <div
      data-aos-offset="300"
      data-aos-duration="1400"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "15px",
        gap: "20px",
      }}
    >
      <Link
        to="/signup"
        className="custom--btn mt-25 darkbtn-hover"
        data-aos="fade-up"
        state={{ id: "director" }}
      >
        <span>Get Started</span>
      </Link>
    </div>
  </div>
</section> */}
    {/* <hr style={{ borderTop: "10px solid #FFFFF", margin: "20px 0" }} /> */}

<section className="py-50 pt-0 px-3 " style={{background:"#f8f9fa"  }} >
                <div className='custom--container m-auto px-0' style={{ paddingTop:"40px"}}>
                    <h2 className='playFairDisplay heading-h2 text-center' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">How to SideHustl?</h2>
                    <div className='how--works--grid' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1300">
                        {howWorks.map(item => (
                            <div style={{minHeight: "10px", padding: "10px 10px"}} className='grid--how' key={item.id}>
                                <h3><span className='number--grid'>{item.id}</span>{item.name}</h3>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                    <h3 className='playFairDisplay heading-h2 text-center' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1100">      
                      Create an account and offload your to-do list in just a few clicks
                    </h3>

                    {/* <p
      className="playFairDisplay text-center mb-20px"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-duration="1200"
    >
      Create an account and offload your to-do list in just a few clicks
    </p> */}

                    {/* <p
      className="playFairDisplay text-center mb-20px"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-duration="1200"
    >
      Create an account and offload your to-do list in just a few clicks
    </p>
    <div
      data-aos-offset="300"
      data-aos-duration="1400"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "15px",
        gap: "20px",
      }}
    >
      <Link
        to="/signup"
        className="custom--btn mt-25 darkbtn-hover"
        data-aos="fade-up"
        state={{ id: "director" }}
      >
        <span>Get Started</span>
      </Link>

 */}
                    <Link   to="/signup" 
                    data-aos="fade-up" data-aos-delay="10" data-aos-duration="1000"
                     state={{ id: "director" }}
                      style={{marginTop: "40px",
                         display: "block",
                          textAlign: "center",
                          justifyContent:'center'}}  className='custom--btn darkbtn-hover flex mx-auto mt-25'

                    >
                        <span>Get Started</span>

                    </Link>

                </div>
            </section>


      <VideoHome />
      {/* <TestimonialSlider /> */}

      <section className="py-100 pt-0 " style={{ backgroundColor: "#f8f9fa" }}>
        <div className="custom--container m-auto text-center" style={{paddingTop:"25px", marginTop:"25px"}}>
          <h3
            className="playFairDisplay heading-h3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
Hear that? The sweet sigh of relief.          
</h3>

{/* <h5
            className="playFairDisplay heading-h2 "
            data-aos="fade-up"
            data-aos-duration="1000"
            style={{marginTop:"25px", marginBottom:"25px"}}

          >
Join The SideHustl Community 
</h5> */}
<h3 className='playFairDisplay' 
data-aos="fade-up"
data-aos-duration="1000"
style={{marginTop:"25px", fontSize: '28px', color: '#333', marginBottom:"25px"}}

> 

 Join The SideHustl Community 
 </h3>


<div  
            data-aos-offset="300"
            data-aos-duration="1500"

style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "15px",
              gap: "20px"  // Added gap between buttons

            }}>
          <Link
            to="/signup"
            className="custom--btn  darkbtn-hover"
            data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-duration="1500"
            style={{
              marginBottom: "-20px",
              // maxWidth: '200px',        // Reduced maximum width
              // width: '80%',             // Reduced width
              // padding: '8px 16px',      // Reduced padding
              // fontSize: '16px',         // Reduced font size
            }}
          

            state={{ id: "director" }}
          >
            <span>Become a Creatr</span>
          </Link>
          <Link
            to="/signup"
            className="custom--btn  darkbtn-hover"
            data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-duration="1500"
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   margin: "0 auto",
            //   marginTop: "15px",
            // }}
            style={{
              marginBottom: "-20px"
            }}

          >
            <span>Become a Hustlr</span>
          </Link>
          </div>

        </div>
      </section>
    </>
  );
}

export default IsLoadingHOC(Home);
