import React from 'react'
import { BsCurrencyDollar } from 'react-icons/bs'
import { FiMapPin } from 'react-icons/fi'

const SearchResult = ({data}) => {

    return (
        <>
            <div className='search--results--container py-50' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1300">
                <div className='custom--container m-auto'>
                    <h2 className="playFairDisplay">Search results</h2>

                    <ul className='list--search'>
                        {data.map((task) => (
                            <li key={task.id}>
                                <div>
                                    <div className='thumb-image-task'>
                                        <img src={task.img_url} alt={task.title} className='w-100' />
                                        <div className='task--status'><FiMapPin />{task.status}</div>
                                    </div>
                                    <div className='thumb-content-task'>

                                    <div className='heading--title'>
                                        <div className='task--title'>{task.title}</div>
                                        <div className='task--location'><FiMapPin />{task.location} London</div>
                                    </div>
                                    <div className='task--description'>{task.description.length > 42 ? `${task.description.slice(0, 42)}...` : task.description}</div>
                                    </div>
                                </div>

                                <div className='footer--task'>
                                <div className='task--posteddate'>Posted Date: {new Date(task.datePosted).toLocaleDateString()}</div>
                                    <div className='task--budget'><BsCurrencyDollar />${task.budget}</div>
                                </div>
                            </li>
                        ))}
                    </ul>

                </div>
            </div>
        </>
    )
}

export default SearchResult