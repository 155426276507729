import React from 'react'
import { BsChatRightText } from 'react-icons/bs'
import { FaSquare } from 'react-icons/fa'

const DirectorNotifications = () => {
    return (
        <>

            <div className='notification--container pt-117'>
                <div className='heading--page'>
                    <h1 className='text--dark playFairDisplay heading-h1 mb-2'>Notifications</h1>
                </div>

                <div className='notifications--lists'>
                    <ul>
                        <li>
                            <div className='notification--content--item'>
                                <div className='check--notification'><FaSquare /></div>
                                <div className='notification--content'>
                                    <p>Creatr Jhon sent you a message. check and reply.</p>
                                    <span>20 Nov 2023</span>
                                </div>
                            </div>
                            <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
                                <span>
                                    <BsChatRightText /> Message
                                </span>
                            </button>
                        </li>

                        
                        <li>
                            <div className='notification--content--item'>
                                <div className='check--notification'><FaSquare style={{fill : '#E1E1E1'}} /></div>
                                <div className='notification--content'>
                                    <p>Lorem ipsum dolor sit amet, consectet uer adipig elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolo.</p>
                                    <span>20 Nov 2023</span>
                                </div>
                            </div>
                            <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
                                <span>
                                    <BsChatRightText /> Message
                                </span>
                            </button>
                        </li>

                        <li>
                            <div className='notification--content--item'>
                                <div className='check--notification'><FaSquare /></div>
                                <div className='notification--content'>
                                    <p>You have successfully applied to 26 jobs.</p>
                                    <span>20 Nov 2023</span>
                                </div>
                            </div>
                            <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
                                <span>
                                    <BsChatRightText /> Message
                                </span>
                            </button>
                        </li>

                        <li>
                            <div className='notification--content--item'>
                                <div className='check--notification'><FaSquare /></div>
                                <div className='notification--content'>
                                    <p>Your upcoming task is on 20 Nov at 12:00PM.</p>
                                    <span>20 Nov 2023</span>
                                </div>
                            </div>
                            <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
                                <span>
                                    <BsChatRightText /> Message
                                </span>
                            </button>
                        </li>

                        
                        <li>
                            <div className='notification--content--item'>
                                <div className='check--notification'><FaSquare /></div>
                                <div className='notification--content'>
                                    <p>Lorem ipsum dolor sit amet, consectet uer adipig elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolo.</p>
                                    <span>20 Nov 2023</span>
                                </div>
                            </div>
                            <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
                                <span>
                                    <BsChatRightText /> Message
                                </span>
                            </button>
                        </li>




                    </ul>
                </div>

            </div>

        </>
    )
}

export default DirectorNotifications