import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { LiaUserCircleSolid } from "react-icons/lia";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Reducers/authSlice";
import { toast } from "react-toastify";
import { socketService } from "./../../services/socket";
import { FaSquare } from "react-icons/fa";
import { IoHomeOutline, IoSettingsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { authAxios } from "../../services/config";
import { useRef } from "react";
import Notifications from "../../common/Notifications";
import { PiFolderSimplePlusLight } from "react-icons/pi";

const DirectorHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [arrivalMessage, setArrivalMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [profileViewDropdown, setProfileViewDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [userDetails, setuserDetails] = useState({
    personalDetails: {
      fname: "",
      lname: "",
      profilePic: "",
    }
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileViewDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const fetchUserDetails = async () => {
    await authAxios()
      .get("profile/view-profile")
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setuserDetails(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (arrivalMessage) {
      const existData = messages.find(
        ({ userId, task_id }) =>
          userId === arrivalMessage.userId && arrivalMessage.task_id
      );
      if (!existData) {
        setMessages((prev) => [...prev, arrivalMessage]);
      }
    }

    fetchUserDetails();
  }, [arrivalMessage]);

  useEffect(() => {
    socketService.connect().then(async (socket) => {
      await socket.on("accepted-task", (data) => {
        setArrivalMessage(data);
      });
    });
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    toast.success("Logout successful");
    navigate("/login");
  };




  const handleProviewViewDropdown = () => {
    setProfileViewDropdown(!profileViewDropdown)
  }

  return (
    <>
      <div className="header--director">
        <header
          className={`main--header`}
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="max-w-1920 flex items-center justify-between sm-flex-col">
            <div className="main--nav">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/">
                  <img src="/assets/images/logo.png" alt="logo" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/messages/director">
                        Message
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/hustlrs-lists">
                        Hustlrs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/your-task-list">
                        Your Jobs
                      </Link>
                    </li>
                    <li className="nav-item hide-menu mobile--item--nav">
                      <span
                        className="nav-link c-pointer"
                        onClick={handleLogout}
                      >
                        <MdLogout /> Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="sign--btn flex items-center hide--mb">
              <Link to="/create-task" className="btn btn-dark darkbtn-hover">
                <span>+ Create A Job</span>
              </Link>

              <Notifications />

              <div className="profile--dropdown c-pointer" ref={dropdownRef}>
                <LiaUserCircleSolid
                  className="nav-link"
                  onClick={handleProviewViewDropdown}
                />
              </div>
            </div>
          </div>
        </header>

        {profileViewDropdown &&
          (
            <div
              className="notification--lists profile--view--dropdown"
            >
              <ul>
                <div className="profile--info-detail">
                  <img
                    
                       src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetails.personalDetails.profilePic}`}
                  />
                  <p>{userDetails.personalDetails.fname} {userDetails.personalDetails.lname}</p>
                </div>
                <li>
                  <Link to="/director-profile" className="dropdown-item c-pointer">
                    <IoSettingsOutline /> Profile
                  </Link>
                </li>


                <li>
                  <span
                    className="dropdown-item c-pointer"
                    onClick={handleLogout}
                  >
                    <MdLogout /> Logout
                  </span>
                </li>
              </ul>
            </div>
          )
        }


      </div>

      <div className="sticky--header--mobile">
        <nav>
          <Link to="/" class="nav-item">
            <IoHomeOutline /> <span>Home</span>
          </Link>

          <Link class="nav-item" to="/your-task-list">
            <PiFolderSimplePlusLight /> <span>Create A Job</span>
          </Link>

          <Link class="nav-item" to="/all-notifications">
            <BsBell /> <span>Notification</span>
          </Link>

          <Link class="nav-item" to="/director-profile">
            <LiaUserCircleSolid /> <span>Profile</span>
          </Link>

        </nav>
      </div>
    </>
  );
};
export default DirectorHeader;