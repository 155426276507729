import React, { useState } from "react";

const DeleteReviewModal = ({setshowDeleteModal,handleDeleteReview}) => {

  return (
    <div>
      <div
        className={`deletemodal modal ${setshowDeleteModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"
               // onClick={setshowDeleteModal}
                onClick={()=>setshowDeleteModal(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              <h5 className="mb-0">
                Are you sure you want to delete this review?
              </h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
               
                onClick={()=>setshowDeleteModal(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                onClick={handleDeleteReview}
              >
                <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${setshowDeleteModal ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default DeleteReviewModal;