import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineCalendar } from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { BiTimeFive } from "react-icons/bi";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { toast } from "react-toastify";
import { authAxios } from "../../services/config";
import { setReportFormatDate } from "../../assets/Helper";
import { IoIosPeople } from "react-icons/io";
import moment from "moment";
import DeleteCreatedTask from "./DeleteTask";
import {
  MdPublic ,
  MdEmojiPeople,
  MdOutlineRemoveRedEye,
  MdReviews,
} from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsFillChatLeftTextFill, BsListTask } from "react-icons/bs";
import EditcreateTask from "./EditcreateTask";
import ConfirmProgressTask from "./ConfirmProgressTask";
import AddReviews from "../../common/AddReviews";
import DeleteProgressTaskModal from "../../common/Modal/DeleteProgressTaskModal";
import { IoCheckmarkCircle, IoCloseCircleSharp ,IoEyeSharp} from "react-icons/io5";
import Pagination from "../../common/Pagination";
import { setParticipantDetails } from "../../Redux/Reducers/chatSlice";
import { useDispatch } from "react-redux";
import DirectorDeniedJobModel from "../../common/Modal/DirectorDeniedJobModel";
import {
  BudgetFormat,
  handleDecimalNumber,
  payloadBudget,
  serviceFeePercentage,
} from "../../utils/helper";
import { useSocket } from "../../contexts/SocketContext";
import { GoArrowRight } from "react-icons/go";
import RejectedJobReason from "../become-a-hustler/RejectedJobReason";
import { IoIosCloseCircle, IoIosCloseCircleOutline } from "react-icons/io";
import { LuPartyPopper } from "react-icons/lu";
import PaymentForm from "./PaymentForm";
// import BeforePaymentForm from "./BeforePaymentForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DirectorCounterOfferModel from "./DirectorCounterOfferModel";
import { PiEye } from "react-icons/pi";
import TaskCompletionRejectedModal from "./TaskCompletionRejectedModal";
import PublicJobModel from "../../common/PublicJobModel";
import DeclinedJobCompletedModal from "./DeclinedJobCompletedModal";
import ConfirmDeniedJobModel from "../../common/ConfirmDeniedJobModel";
import DirectorJobFrequently from "../../common/DirectorJobFrequently";
import EndSubsciption from "../../common/Modal/EndSubsciption";
import AnotherHustlrLists from "../../common/AnotherHustlrLists";
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const DirectorJobs = (props) => {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();

  const location = useLocation();
  const id = location?.state?.id;
  const [activeTab, setActiveTab] = useState("");
  const [OpenTask, setOpenTask] = useState([]);
  const [showdenieJobModel, setshowdenieJobModel] = useState(false);
  const [showAnotherHustlr, setshowAnotherHustlr] = useState({
    taskId: "",
    model: false,
    hustlerId: "",
  });
  const [deleteCreatedTask, setDeleteCreatedTask] = useState(false);
  const [singleTask, setSingleTask] = useState({});
  const [showPaymentForm, setshowPaymentForm] = useState(false);
  const [BeforeShowPaymentForm, setBeforeShowPaymentForm] = useState(false);

  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [ProgressTask, setProgressTask] = useState([]);
  const [showConfirmProgressTask, setshowConfirmProgressTask] = useState(false);
  const [ProgressTaskId, setProgressTaskId] = useState("");
  const [CompletedTask, setCompletedTask] = useState([]);
  const [checkboxValue, setcheckboxValue] = useState("");
  const [showReviewModel, setshowReviewModel] = useState(false);
  const [rejectedJobModal, setRejectedJobModal] = useState(false);
  const [rejectedJobDetails, setRejectedJobDetails] = useState(null);
  const [ClosedTask, setClosedTask] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);
  const [showPublicJobModel, setshowPublicJobModel] = useState(false);
  const [totalPosts, settotalPosts] = useState(0);
  const [taskID, setTaskId] = useState([]);
  const [finaltaskDetails, setfinaltaskDetails] = useState({
    budget: "",
    date: "",
  });
  const [TaskDetail, setTaskDetail] = useState([]);
  const [SensitiveWords, setSensitiveWords] = useState([]);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const [showeditModal, setshoweditModal] = useState(false);
  const [taskReviewDetails, settaskReviewDetails] = useState({});
  const [showDeleteProgressTaskModal, setshowDeleteProgressTaskModal] =
    useState(false);
  const [hustlerPaymentId, setHustlerPaymentId] = useState(false);
  const [taskPaymentId, setTaskPaymentId] = useState(false);
  const [deleteProgressId, setdeleteProgressId] = useState("");
  const [showEndSubscriptionModel, setshowEndSubscriptionModel] =
    useState(false);
  const [showdirectorCounterOffermodel, setshowdirectorCounterOffermodel] =
    useState(false);
  const [allCounterOfferdetails, setallCounterOfferdetails] = useState([]);
  const [incomingJobtaskId, setincomingJobtaskId] = useState("");

  const [showDirectorDeniedJobModel, setshowDirectorDeniedJobModel] =
    useState(false);
  const [allId, setallId] = useState({
    hustlerId: "",
    taskId: "",
    intervalId: "",
    subscriptionId: "",
    reason: "",
  });
  const [rejectedTaskModal, setRejectedTaskModal] = useState({
    model: false,
    data: "",
  });

  const [showDeclinedJobTaskModal, setshowDeclinedJobTaskModal] = useState({
    model: false,
    data: "",
  });

  const editCreateTask = (item) => {
    setSingleTask(item);
    setshoweditModal(true);
  };

  const closeModal = () => {
    setDeleteCreatedTask(false);
  };

  const fetchOpentask = async (number) => {
    if (number) {
      setcurrentPage(1);
    }
    setLoading(true);
    await authAxios()
      .get(
        `/task/tasks-created-by-director?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        const resData = response?.data;
        if (resData.status == 1) {
          setLoading(false);
          setOpenTask(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchCompletedtask = async (number) => {
    if (number) {
      setcurrentPage(1);
    }
    setLoading(true);
    await authAxios()
      .get(
        `/bookhustler/get-completed-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setLoading(false);
          setCompletedTask(resData.data);
          settotalPosts(resData?.count?.total);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleChangeHustler = async (item) => {
    const payload = {
      hustlerId: item?.hustlerId,
      taskId: item?.taskId,
    };
    setLoading(true);

    await authAxios()
      .post(`/bookhustler/book-hustler`, payload)
      .then(async (response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status === 1) {
          socket.emit("directlyHustlerBooked", resData.data);
          setshowAnotherHustlr((prev) => ({
            ...prev,
            model: false,
            taskId: "",
          }));
          handleChangeCounterOfferStatus(item);
          fetchProgressTask(1);
          toast.success(resData?.message);
          //  await bookingHustler(payload);
        } else {
          toast.info(resData?.message);
          setLoading(false);
          //  setshowBookHustler(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleChangeCounterOfferStatus = async (item) => {
    const payload = {
      hustlerId: item?.hustlerId,
      taskId: item?.taskId,
    };
    setLoading(true);

    await authAxios()
      .post(`/bookhustler/change-book-hustler`, payload)
      .then(async (response) => {
        const resData = response.data;
        setLoading(false);
        fetchProgressTask(1);
        if (resData.status === 1) {
        } else {
          toast.info(resData?.message);
          setLoading(false);
          //  setshowBookHustler(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchSensitiveWords = async () => {
    setLoading(true);
    await authAxios()
      .get(`/sensitiveword/get-all-sensitive-words`)
      .then((response) => {
        const resData = response?.data;

        if (resData.status == 1) {
          setLoading(false);
          setSensitiveWords(resData?.data?.words);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleDeniedJob = async (data) => {
    setshowdenieJobModel(true);
    setallCounterOfferdetails([]);
    setshowdirectorCounterOffermodel(false);

    if (showdenieJobModel == true) {
      setLoading(true);
      await authAxios()
        .put(`/task/cancel-tasks/${singleTask._id}`, { reason: data })
        .then((response) => {
          const resData = response.data;
          setLoading(false);
          if (resData.status == 1) {
            fetchProgressTask(1);
            setshowdenieJobModel(false);
            toast.success(resData?.message);
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleCancelDeniedJob = () => {
    setshowdenieJobModel(false);
  };
  const bookHustler = async (bookHustler) => {
    setLoading(true);

    await authAxios()
      .post("/stripe/change-payment-status", {
        taskId: bookHustler,
      })
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          const taskId = bookHustler;
          socket.emit("paymentToHustler", taskId);
          setLoading(false);
          // navigate("/director-thanks");
          navigate("/your-task-list/in-progress");

console.log("/director-thanks111")
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchProgressTask = async (number) => {
    if (number) {
      setcurrentPage(1);
    }
    setLoading(true);
    await authAxios()
      .get(
        `/bookhustler/get-in-progress-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setLoading(false);
          setProgressTask(resData.data);
          settotalPosts(resData?.count?.total);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleTabClick = (tabId) => {
    if (tabId === activeTab) {
      return;
    } else {
      setActiveTab(tabId);
      setcurrentPage(1);
    }
  };

  const deleteCreateTask = (item) => {
    setSingleTask(item?._id);
    setDeleteCreatedTask(true);
  };

  const handleCloseTask = async () => {
    setLoading(true);
    await authAxios()
      .put(`/task/close-task/${singleTask}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setLoading(false);
          toast.success(resData?.message);
          closeModal();
          fetchOpentask(1);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const EditupdateTask = async (data) => {
    const formData = new FormData();
    data.job_image.forEach((file) => {
      formData.append("job_image", file);
    });

    formData.append("title", data.title);
    formData.append("serviceName", data?.serviceName);
    formData.append("cityName", data?.cityName);
    formData.append("budget", payloadBudget(`${data.budget}`));
    formData.append("province", data?.province);
    formData.append("dueDate", data?.dueDate);
    // formData.append("endDate", data?.endDate);

    formData.append("description", data.description);
    formData.append("oneTimePayment", data.oneTimePayment);
    if (!data.subscriptionInterval == "") {
      formData.append("subscriptionInterval", data.subscriptionInterval);
    }
    formData.append("status", "open");

    const ids = data.id;
    setLoading(true);
    await authAxios()
      .put(`/task/update-task/${ids}`, formData)
      .then((response) => {
        const resData = response.data;

        if (resData.status == 1) {
          setLoading(false);
          toast.success(resData?.message);
          setshoweditModal(false);
          fetchOpentask(1);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleShowProgressTaskPopup = (id) => {
    setcheckboxValue(id === checkboxValue ? "" : id);
    setProgressTaskId(id);
    setshowConfirmProgressTask(true);
    setTaskId(id);
  };

  const handleSubmitProgressTask = async () => {
    setLoading(true);
    await authAxios()
      .post(`/bookhustler/completed/${ProgressTaskId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          socket.emit("taskCompletedbyDirector", resData.data);
          setLoading(false);
          toast.success(resData?.message);
          setshowConfirmProgressTask(false);
          // fetchProgressTask();
          setActiveTab("completed-task");
          //    fetchCompletedtask();
          //   fetchOpentask();
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleCompleteReccuringJob = async (item) => {
    if (showConfirmProgressTask == true) {
      setLoading(true);
      try {
        setLoading(false);
        const response = await authAxios().post(
          "/stripe/mark-job-completed-director",
          {
            subscriptionId: allId.subscriptionId,
            intervalId: allId.intervalId,
          }
        );
        toast.success(response.data.message);
        fetchProgressTask(1);
        setshowConfirmProgressTask(false);
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      const subscriptionId = item?.subscription?._id;
      const intervalRecords = item?.subscription?.intervalRecords;
      const intervalRecordsid = intervalRecords[intervalRecords.length - 1]._id;
      setshowConfirmProgressTask(true);
      setallId((prev) => ({
        ...prev,
        intervalId: intervalRecordsid,
        subscriptionId: subscriptionId,
      }));
      setTaskDetail(item);
    }
  };

  const handleDeniedProgressTask = async (id) => {
    setshowDirectorDeniedJobModel(true);
    setProgressTaskId(id);
    if (showDirectorDeniedJobModel == true) {
      const cancelledJobreason = id;
      const cancelPayloadreason = {
        reasonForNotCompleted: cancelledJobreason,
      };
      setLoading(true);
      await authAxios()
        .post(
          `/bookhustler/not-completed/${ProgressTaskId}`,
          cancelPayloadreason
        )
        .then((response) => {
          const resData = response.data;
          if (resData.status == 1) {
            socket.emit("taskNotCompleted", resData.data.task);
            setLoading(false);
            toast.success(resData?.message);
            setshowDirectorDeniedJobModel(false);
            fetchProgressTask(1);
          } else {
            setLoading(false);
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleDeniedReccuringProgressTask = async (item) => {
    setshowDirectorDeniedJobModel(true);
    setTaskDetail(item);

    if (showDirectorDeniedJobModel == true) {
      const subscriptionId = TaskDetail?.subscription?._id;
      const intervalRecords = TaskDetail?.subscription?.intervalRecords;
      const intervalRecordsid = intervalRecords[intervalRecords.length - 1]._id;
      setLoading(true);
      try {
        setLoading(false);
        const response = await authAxios().post(
          "/stripe/mark-job-deny-director",
          {
            subscriptionId: subscriptionId,
            intervalId: intervalRecordsid,
            reason: item,
          }
        );
        toast.success(response.data.message);
        fetchProgressTask(1);
        setshowDirectorDeniedJobModel(false);
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
        console.error("Failed to end subscription:", error);
      }
    }
  };

  const handleEndSubScription = async (id) => {
    if (showEndSubscriptionModel == true) {
      setLoading(true);
      try {
        setLoading(false);
        const response = await authAxios().post("/stripe/end-subscription", {
          taskId: allId.taskId,
        });
        toast.success(response.data.message);
        fetchProgressTask(1);
        setshowEndSubscriptionModel(false);
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
        console.error("Failed to end subscription:", error);
      }
    } else {
      setshowEndSubscriptionModel(true);
      setallId((prev) => ({
        ...prev,
        taskId: id,
      }));
    }
  };

  const handleSubmitReview = async (item) => {
    const payload = {
      reviewReceiver: allId.hustlerId,
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      skills: item.skills,
      communication: item.communication,
      comment: item.comment,
      taskId: allId.taskId,
    };
    setLoading(true);
    await authAxios()
      .post("/review/create-review", payload)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          socket.emit("directorReviewed", resData.data.review);
          setLoading(false);
          toast.success(resData?.message);
          fetchCompletedtask(1);

          setshowReviewModel(false);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error?.response?.data?.message);
      });
  };
  const handleEditReview = async (item) => {
    const payload = {
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      skills: item.skills,
      communication: item.communication,
      comment: item.comment,
    };
    const taskId = item.taskId;
    setLoading(true);
    await authAxios()
      .put(`/review/update-review/${taskId}`, payload)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData?.message);
          fetchCompletedtask(1);
          settaskReviewDetails([]);

          setshowReviewModel(false);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleAddOpenModal = (item) => {
    setallId((prev) => ({
      ...prev,
      hustlerId: item?.task?.hustlerId._id,
      taskId: item?.task?._id,
    }));

    setshowReviewModel(true);
  };

  const handleViewOpenModal = (item) => {
    fetchSpecificTaskReview(item?.task?._id);

    setshowReviewModel(true);
  };

  const fetchSpecificTaskReview = async (id) => {
    setLoading(true);
    await authAxios()
      .get(`/review/specific-task-reviews/${id}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          settaskReviewDetails(resData?.data[0]);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.message);
      });
  };

  const handleDeleteProgressTask = async (id) => {
    setdeleteProgressId(id);
    setshowDeleteProgressTaskModal(true);

    if (showDeleteProgressTaskModal == true) {
      setLoading(true);
      await authAxios()
        .delete(`/task/cancel-tasks/${deleteProgressId}`)
        .then((response) => {
          setLoading(false);
          const resData = response.data;
          if (resData.status == 1) {
            toast.success(resData?.message);
            fetchProgressTask(1);
            setdeleteProgressId("");

            setshowDeleteProgressTaskModal(false);
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const CounterOffer = async (data) => {
    setLoading(true);
    await authAxios()
      .post(`/task/counter-offer`, data)
      .then((response) => {
        setLoading(false);
        const resData = response.data;

        if (resData.status == 1) {
          socket.emit("counterOfferbyDirector", resData.data);
          toast.success(resData?.message);
          fetchProgressTask(1);
          setshowdirectorCounterOffermodel(false);
          setallCounterOfferdetails([]);
          setincomingJobtaskId("");
        } else {
          toast.error(resData?.message);
        }

      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchRejectedJob = async (rejectedJobId) => {
    setLoading(true);
    try {
      const response = await authAxios().get(`/task/rejected/${rejectedJobId}`);

      const resData = response?.data?.data.rejectionDetails;
      setLoading(false);
      if (resData) {
        setRejectedJobDetails(resData);
        setRejectedJobModal(true);
      } else {
        toast.error(resData?.message?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching rejected job:", error);
      toast.error("An error occurred while fetching rejected job");
    }
  };

  const fetchClosedTask = async (number) => {
    if (number) {
      setcurrentPage(1);
    }
    setLoading(true);
    await authAxios()
      .get(
        `/task/get-closed-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setClosedTask(resData.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSendMessage = (item) => {

    const payload = {
      participant_id: item?.hustlerId?._id,
      taskId: item?._id,
      profilePic: item?.hustlerId?.profilePic,
      participant_name: item?.hustlerId?.fname + " " + item?.hustlerId?.lname,
      showWelcomeMessage: true,
    };

    dispatch(setParticipantDetails(payload));
    navigate("/messages");
  };

  const handleStripeAccountConnect = async (item) => {
    setLoading(true);
    await authAxios()
      .post(`/stripe/create-customer-account`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          handleSubmit(item);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const [itemCheck, SetitemCheck] = useState(null);  // Initialize with null or an empty object
  const handleBeforeSubmit = (item)=>{
    SetitemCheck(item)
    const budget = item.finalPrice || item.budget;
    setfinaltaskDetails((prev) => ({
      ...prev,
      budget: budget,
    }));
    setBeforeShowPaymentForm(true)

  }

  const handleSubmit = async (item) => {
    const TaskDetail = item;

    const id = item._id;
    const hustlerId = item.hustlerId._id;
    const budget = item.finalPrice || item.budget;
    setHustlerPaymentId(hustlerId);
    setTaskPaymentId(id);
    setfinaltaskDetails((prev) => ({
      ...prev,
      budget: budget,
    }));

    const resData = await authAxios()
      .get(`/stripe/check-stripe-account`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.data.status === 0) {
          handleStripeAccountConnect(TaskDetail);
        } else {
          toast.error(error?.response?.data?.message);
        }
      });

    if (resData && resData.status === 1) {

      if (item.oneTimePayment == false) {
        handleRecurringPayment(budget, resData.data, TaskDetail);
      } else {
        const totalPayment = (budget * (1 + serviceFeePercentage)).toFixed(2)*100
        setLoading(true);
  
        const paymentIntentResponse = await authAxios().post(
          `/stripe/create-payment-intent`,
          {
            amount: totalPayment,
            currency: "cad",
            transferGroup: `${hustlerId}-${id}-${totalPayment}`,
          }
        );

        const paymentIntentResData = paymentIntentResponse.data;
        if (paymentIntentResData.status === 1) {
          setPaymentIntentId(paymentIntentResData.data.client_secret);
          setLoading(false);
          setshowPaymentForm(true);
          // setshowBookHustler(false);
        } else {
          setLoading(false);
          toast.error(paymentIntentResData?.message);
        }
      }
    }
  };

  const handleRecurringPayment = async (
    pricing,
    stripeAccountId,
    TaskDetail
  ) => {
    const totalPayment = (pricing * (1 + serviceFeePercentage)).toFixed(2)*100

    const subscriptionIntervalTime = TaskDetail.subscriptionInterval;


    const meta = `${TaskDetail._id}-${TaskDetail.user}`;
    const metadata = {
      subscription_details: meta,
    };

    try {
      const productID = process.env.REACT_APP_PRODUCT_ID;
      const stripeItems = [
        {
          price_data: {
            currency: "CAD",
            product: productID,
            recurring: {
              interval: TaskDetail.subscriptionInterval,
              interval_count: 1,
            },
            unit_amount_decimal: totalPayment,
          },
        },
      ];
      setLoading(true);

      const subscriptionResponse = await authAxios().post(
        `/stripe/create-subscription`,
        {
          customer: stripeAccountId,
          items: stripeItems,
          metadata: metadata,
        }
      );
      
      const subscriptionData = subscriptionResponse.data;
      setLoading(false);
      if (subscriptionData.status === 1) {
        setfinaltaskDetails((prev) => ({
          ...prev,
          budget: pricing,
        }));

        setPaymentIntentId(
          subscriptionData.data.latest_invoice.payment_intent.client_secret
        );
        setLoading(false);
        setshowPaymentForm(true);
      } else {
        toast.error(subscriptionData.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to create subscription:", error);
      toast.error(
        `Failed to create subscription: ${
          error.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : "Network error"
        }`
      );
    }
  };

  const handleViewCounterOffer = async (item, hustlerId) => {
    // setshowdirectorCounterOffermodel(true);
    setSingleTask(item);
    setincomingJobtaskId(item._id);

    await authAxios()
      .get(`/task/get-counter-offer/${item._id}`)
      .then((response) => {
        const resData = response.data;

        const counterOfferData = resData.data.find(
          (item) => item.hustlerId === hustlerId
        );


        if (counterOfferData) {
          const hustlerCounterOffers = counterOfferData.counterOffers.filter(
            (offer) => offer.userType === "HUSTLER"
          );
          const DirectorCounterOffers = counterOfferData.counterOffers.filter(
            (offer) => offer.userType !== "HUSTLER"
          );

          const allOffers = {
            hustlerCounterOffers: hustlerCounterOffers,
            directorCounterOffers: DirectorCounterOffers,
          };


          setallCounterOfferdetails(allOffers);
          setshowdirectorCounterOffermodel(true);
        } else {
          setallCounterOfferdetails([]);
          toast.info("Hustlr hasn't sent an offer yet.");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handlePublic = async (item) => {
    setSingleTask(item._id);
    setshowPublicJobModel(true);
    if (showPublicJobModel == true) {
      setLoading(true);
      await authAxios()
        .put(`/task/make-task-public/${singleTask}`)
        .then((response) => {
          setLoading(false);
          toast.success(response.data.message);
          fetchProgressTask(1);
          setSingleTask([]);
          setshowPublicJobModel(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleAcceptcounterOffer = async (id) => {
    setLoading(true);
    await authAxios()
      .put(`/task/accept-task/${id}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData?.message);

          fetchProgressTask(1);

          setshowdirectorCounterOffermodel(false);
          setSingleTask([]);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handlePaymentView = async (item) => {
    const taskDetails = item;
    
    const FinalBid=payloadBudget(`${taskDetails?.finalPrice||taskDetails?.budget}`)
    if (item.oneTimePayment == false) {
      setLoading(true);
      try {
        const response = await authAxios().get(
          `/stripe/list-stripe-subscription-details`
        );
        setLoading(false);
        const resData = response?.data;
        if (resData?.status === 1) {
          const filterData = resData.data.subscriptions.filter(
            (item) => item.taskId == taskDetails.subscription.taskId
          );
          sessionStorage.setItem("subscriptionDetails", JSON.stringify(filterData[0]));
          sessionStorage.setItem("FinalBid", FinalBid);
          navigate("/subscription-invoice");
        } else {
          toast.error(resData?.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      setLoading(true);
      try {
        const response = await authAxios().get(
          `/stripe/list-stripe-payment-details`
        );
        setLoading(false);
        const resData = response?.data;
        if (resData?.status === 1) {
          const filterData = resData.data.payments.filter(
            (item) => item.task._id == taskDetails._id
          );
          sessionStorage.setItem("paymentDetails", JSON.stringify(filterData[0]));
          sessionStorage.setItem("FinalBid", FinalBid);
          navigate("/payment-invoice");
        } else {
          setLoading(false)
          toast.error(resData?.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    fetchSensitiveWords();
    setActiveTab(id || "new-jobs");
  }, [id]);

  useEffect(() => {
    if (activeTab === "new-jobs") {
      fetchOpentask(1);
    }
    if (activeTab === "in-progress") {
      fetchProgressTask(1);
    }
    if (activeTab === "completed-task") {
      fetchCompletedtask(1);
    }
    if (activeTab === "in-closed") {
      fetchClosedTask(1);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "new-jobs") {
      fetchOpentask();
    }
    if (activeTab === "in-progress") {
      fetchProgressTask();
    }
    if (activeTab === "completed-task") {
      fetchCompletedtask();
    }
    if (activeTab === "in-closed") {
      fetchClosedTask();
    }
  }, [currentPage]);

  // const publicJob = async (number) => {
  //   setActiveButton("read"); // Set the active button
  //   setLoading(true); // Show loading state
  
  //   try {
  //     const response = await authAxios().put(
  //       `/api/taskproposal/public-job/${number}`, // Replace 'number' with the dynamic job ID
  //       {
  //         notificationsStatus: true, // Payload to be sent in the request body
  //       }
  //     );
  
  //     const resData = response.data;
  //     setLoading(false); // Turn off loading after response is received
  
  //     if (resData.status === 1) {
  //       setTotalPosts(resData.count.total); // Update total posts from response
  //       setAllReadNotifications(resData.data); // Update read notifications
  //     } else {
  //       toast.error(resData.message); // Handle unsuccessful response
  //     }
  //   } catch (error) {
  //     setLoading(false); // Turn off loading if an error occurs
  //     toast.error(error.response?.data?.message || 'Something went wrong'); // Show error message
  //   }
  // };
  // const publicJob = async (number) => {

    const publicJob = async (number) => {
      setLoading(true);
      await authAxios()
        .put( `/taskproposal/public-job/${number}`, 
          // Replace 'number' with the dynamic job ID
        )
        .then((response) => {
          setLoading(false);
          const resData = response.data;
          fetchProgressTask()
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    };

  return (
    <>
      <section
        className="director--task--lists pt-117"
        style={{ minHeight: "400px" }}
      >
        <div className="custom--container m-auto pt-5 pb-5 px-3">
          <div className="director--list--task">
            <div className="director--list--task--header">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "new-jobs" ? "active" : ""
                    }`}
                    id="new-jobs-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#new-jobs"
                    type="button"
                    role="tab"
                    aria-controls="new-jobs"
                    aria-selected={activeTab === "new-jobs" ? "true" : "false"}
                    onClick={() => handleTabClick("new-jobs")}
                  >
                    New Jobs
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "in-progress" ? "active" : ""
                    }`}
                    id="in-progress-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#in-progress"
                    type="button"
                    role="tab"
                    aria-controls="in-progress"
                    aria-selected={
                      activeTab === "in-progress" ? "true" : "false"
                    }
                    onClick={() => handleTabClick("in-progress")}
                  >
                    In Progress Jobs
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "completed-task" ? "active" : ""
                    }`}
                    id="completed-task-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#completed-task"
                    type="button"
                    role="tab"
                    aria-controls="completed-task"
                    aria-selected={
                      activeTab === "completed-task" ? "true" : "false"
                    }
                    onClick={() => handleTabClick("completed-task")}
                  >
                    Completed Jobs
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "in-closed" ? "active" : ""
                    }`}
                    id="in-closed-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#in-closed"
                    type="button"
                    role="tab"
                    aria-controls="in-closed"
                    aria-selected={activeTab === "in-closed" ? "true" : "false"}
                    onClick={() => handleTabClick("in-closed")}
                  >
                    Closed Jobs
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade ${
                  activeTab === "new-jobs" ? "show active" : ""
                }`}
                id="new-jobs"
                role="tabpanel"
                aria-labelledby="new-jobs-tab"
              >
                <div className="list--tasks">
                  {OpenTask && OpenTask?.length > 0 ? (
                    OpenTask.map((item, index) => (
                      <div
                        key={index}
                        className="created--task--lists process--task--list hover--effect"
                        style={{ position: "relative" }}
                      >
                        <div className="new--jobs-h">
                          <span>
                            <BsListTask /> New Jobs
                          </span>

                          {/* <span>
                            {" "}
                            Applicants: <b>{item?.applications}</b>
                          </span> */}

<Link
                                to={`/task/${item._id}`}
                                style={{
                                  color: "#b0754e",
                                  textDecoration: "none",
                                }}
                              >
                            Applicants: <b>{item?.applications}</b>
                            </Link>

                        </div>

                        <div className="task--work">
                          <div className="task--items--header">
                            <div className="info--task--data">
                              <Link
                                to={`/task/${item._id}`}
                                style={{
                                  color: "#212529",
                                  textDecoration: "none",
                                }}
                              >
                                <h2 className="task--items--heading hover-text">
                                  {item?.title}
                                </h2>
                              </Link>
                              <div className="service--category--task">
                                <h3>{item?.serviceName}</h3>
                              </div>
                            </div>

                            <div className="task--items--edit--delete info--data--button">
                              <div className="button--lists-view">
                                <button
                                  onClick={() => editCreateTask(item)}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <FiEdit /> Edit Job 
                                  </span>
                                </button>
                                <button
                                  onClick={() => deleteCreateTask(item)}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <IoCloseCircleSharp /> Close Job
                                  </span>
                                </button>

                            {item?.applications?
                            //   <Link
                            //   to={`/task/${item._id}`}
                            // >
                            
                            <button
                            onClick={() => navigate(`/task/${item._id}`)} // Corrected syntax
                            // className="custom--btn lightbtn-hover transparent--btn minbutton icon--btn text-black"
                            className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"

                          >
                            <span>
                              <IoEyeSharp /> View Applicants
                            </span>
                          </button>
                                                      // </Link>

                                :""
}
                              </div>
                            </div>
                          </div>

                          <div className="task--items--content">
                            <p>
                              <span className="task--items--content--icon">
                                <AiOutlineCalendar />
                              </span>
                              {setReportFormatDate(item?.dueDate)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <TbMoneybag />
                              </span>{" "}
                              Budget: ${BudgetFormat(`${item?.budget}`)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <GrLocation />
                              </span>{" "}
                              Area: {item?.cityName}
                            </p>
                          </div>

                          <div className="task--items-footer">
                            {item?.description
                              ? item?.description?.length > 400
                                ? `${item?.description?.substring(0, 400)}...`
                                : `${item?.description}`
                              : "No description available"}
                          </div>
                        </div>

                        {item?.oneTimePayment ? null : (
                          <div
                            className={`task--items-down--footer new--jobs-h`}
                          >
                            <p>
                              Please note that this is a{" "}
                              <b>{item?.subscriptionInterval}ly</b> job, and
                              payment will be made on a{" "}
                              <b>{item?.subscriptionInterval}ly</b> basis upon
                              completion.{" "}
                            </p>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="no--data--dedected">
                      No new jobs to display
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "in-progress" ? "show active" : ""
                }`}
                id="in-progress"
                role="tabpanel"
                aria-labelledby="in-progress-tab"
              >
                <div className="list--tasks">
                  {ProgressTask && ProgressTask?.length > 0 ? (
                    ProgressTask.map((item, index) => (
                      <div
                        key={index}
                        className="created--task--lists process--task--list hover--effect"
                      >
                        {item?.subscription?.intervalRecords[
                          item?.subscription?.intervalRecords.length - 1
                        ]?.jobCompletion?.director.isCompleted == true &&
                        item?.subscription?.intervalRecords[
                          item?.subscription?.intervalRecords.length - 1
                        ]?.jobCompletion?.hustler.isCompleted == true ? (
                          <div className="waiting--msg">
                            <div>
                              <BiTimeFive /> Job period has been completed, now
                              wait for the next subscription period
                            </div>
                          </div>
                        ) : item?.subscription?.intervalRecords[
                            item?.subscription?.intervalRecords.length - 1
                          ]?.jobCompletion?.director.reason ? (
                          <div className="cancelled--interested-job">
                            <div>
                              <BiTimeFive /> You rejected the completion of the
                              specific job by the hustlr.
                            </div>
                            <div
                              className="view--reason"
                              onClick={() =>
                                setRejectedTaskModal({
                                  model: true,
                                  reason:
                                    item?.subscription?.intervalRecords[
                                      item?.subscription?.intervalRecords
                                        .length - 1
                                    ]?.jobCompletion?.director.reason,
                                })
                              }
                            >
                              View Reason
                              <GoArrowRight />
                            </div>
                          </div>
                        ) : item?.subscription?.intervalRecords[
                            item?.subscription?.intervalRecords.length - 1
                          ]?.jobCompletion?.hustler.isCompleted == true ? (
                          <div className="waiting--msg">
                            <BiTimeFive /> Hustlr have Completed the job
                          </div>
                        ) : item?.counterOfferIds?.length &&
                          !item.cancel &&
                          item?.counterOfferAccepted == false ? (
                          <div className="waiting--msg">
                            <BiTimeFive /> Hustlr has made a counteroffer.
                          </div>
                        ) : item?.directorAsked ? (
                          <div className="waiting--msg">
                            <BiTimeFive /> Waiting for Hustlr's acceptance
                          </div>
                        ) : (
                          <>
                            {item?.cancel ? (
                              <div className="cancelled--interested-job">
                                <span className="rejected-text">
                                  <IoIosCloseCircleOutline />{" "}
                                  {item?.rejectionDetails?.rejectedBy ===
                                  "DIRECTOR"
                                    ? "You have rejected hustlr's offer"
                                    : "Hustlr has rejected this job"}
                                </span>
                                <span
                                  className="view--reason"
                                  onClick={() => window.location.href = 'https://sidehustlsupport.zendesk.com/hc/en-us/requests/new'}
                                >
                                  Help Center<GoArrowRight />
                                </span>


                                <span
                                  className="view--reason"
                                  onClick={() => fetchRejectedJob(item._id)}
                                >
                                  View Reason <GoArrowRight />
                                </span>
                              </div>
                            ) : (
                              <>
                                {item?.taskCompletionId
                                  ?.hustlerMarkedCompleted == true ? (
                                  <span className="mark-status">
                                    <span className="rejected-text">
                                      <BiTimeFive /> Hustlr has completed your
                                      job
                                    </span>
                                  </span>
                                ) : (
                                  <>
                                    {!item?.stripePaymentStatus ? (
                                      <div className="working--process">
                                        <span>
                                          <BiTimeFive /> Hustlr has accepted the
                                          job!
                                        </span>
                                      </div>
                                    ) : (
                                      <>
                                        {item.taskCompletionId
                                          ?.reasonForNotCompleted ? (
                                          <div className="cancelled--interested-job">
                                            <div>
                                              <BiTimeFive /> You rejected the
                                              completion of the specific job by
                                              the hustlr.
                                            </div>
                                            <div
                                              className="view--reason"
                                              onClick={() =>
                                                setRejectedTaskModal({
                                                  model: true,
                                                  reason:
                                                    item.taskCompletionId
                                                      ?.reasonForNotCompleted,
                                                })
                                              }
                                            >
                                              View Reason
                                              <GoArrowRight />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="working--process">
                                            <span>
                                              <BiTimeFive /> Hustlr has accepted
                                              the job!
                                            </span>
                                            <span
                                              className="payment--option"
                                              onClick={() =>
                                                handlePaymentView(item)
                                              }
                                            >
                                              View payment  <GoArrowRight />
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        <div className="task--work">
                          <div
                            className={`task--items--header waiting--task--top ${
                              item?.directorAsked ? "waiting--task--header" : ""
                            }`}
                            style={{ marginBottom: "0px" }}
                          >
                            <div className="task--add--info">
                              <div className="user--task--info">
                                <div className="task--info--img">
                                  <Link
                                    to={`/user-info/${item?.hustlerId?._id}`}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.hustlerId?.profilePic}`}
                                    />
                                  </Link>
                                </div>
                                <div className="task--info--details">
                                  <span>
                                    {item?.hustlerId?.fname}{" "}
                                    {item?.hustlerId?.lname}
                                  </span>
                                  <p>
                                    <img src="../assets/images/hustler-star.png" />{" "}
                                    {handleDecimalNumber(
                                      item?.hustlerId?.hustlerDetails?.ratings
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`task--items--edit--delete waiting--task--item ${
                                item?.directorAsked ? "waiting--task" : ""
                              } ${item?.cancel ? "cancelled-task" : ""}`}
                              style={{
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <div className="button--lists-view">
                                <button
                                  onClick={() => handleSendMessage(item)}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                  style={{ textAlign: "left" }}
                                >
                                  <span>
                                    {" "}
                                    <BsFillChatLeftTextFill /> Send Message
                                  </span>
                                  <br />
                                </button>

                                {item.status == "assigned" && (
                                  <>
                                    {" "}
                                    <button
                                      onClick={() =>
                                        setshowAnotherHustlr((prev) => ({
                                          ...prev,
                                          model: true,
                                          taskId: item._id,
                                          hustlerId: item?.hustlerId?._id,
                                        }))
                                      }
                                      // onClick={() => handleSendMessage(item)}
                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                      style={{ textAlign: "left" }}
                                    >
                                      <span>
                                        {" "}
                                        <MdEmojiPeople /> Choose Another Hustlr
                                      </span>
                                      <br />
                                    </button>

                                    <button
                                      onClick={() =>publicJob(item._id)
                                        // setshowAnotherHustlr((prev) => ({
                                        //   ...prev,
                                        //   model: true,
                                        //   taskId: item._id,
                                        //   hustlerId: item?.hustlerId?._id,
                                        // }))
                                      }
                                      // onClick={() => handleSendMessage(item)}
                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                      style={{ textAlign: "left" }}
                                    >
                                      <span>
                                        {" "}
                                        <MdPublic  /> Make Job Public
                                      </span>
                                      <br />
                                    </button>

                                    <button
                                      onClick={() => deleteCreateTask(item)}
                                      //   onClick={() => handleSendMessage(item)}
                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                      style={{ textAlign: "left" }}
                                    >
                                      <span>
                                        <IoCloseCircleSharp /> Close Job
                                      </span>
                                      <br />
                                    </button>
                                  </>
                                )}

                                {item?.cancel && (
                                  <>
                                    <button
                                      onClick={() => handlePublic(item)}
                                      //   onClick={() => handleSendMessage(item)}
                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                      style={{ textAlign: "left" }}
                                    >
                                      <span>
                                        <IoIosPeople /> Make this job as public
                                      </span>
                                      <br />
                                    </button>

                                    <button
                                      onClick={() => deleteCreateTask(item)}
                                      //   onClick={() => handleSendMessage(item)}
                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                      style={{ textAlign: "left" }}
                                    >
                                      <span>
                                        <IoCloseCircleSharp /> Close Job
                                      </span>
                                      <br />
                                    </button>
                                  </>
                                )}

                                {item?.counterOfferIds?.length &&
                                !item.cancel &&
                                item.counterOfferAccepted == false ? (
                                  <button
                                    onClick={() =>
                                      handleViewCounterOffer(
                                        item,
                                        item?.hustlerId?._id
                                      )
                                    }
                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                    style={{ textAlign: "left" }}
                                  >
                                    <span>
                                      <PiEye /> View Offer
                                    </span>
                                  </button>
                                ) : null}

                                {item?.directorAsked ||
                                item?.taskCompletionId
                                  ?.hustlerMarkedCompleted ||
                                item?.cancel ? null : (
                                  <>
                                    {!item?.stripePaymentStatus ? (
                                      <button
                                        onClick={() => handleSubmit(item)}
                                        className="custom--btn
                                         ligghtbtn-hover transparent--btn minbutton"
                                      >
                                        <span>Complete Payment</span>
                                      </button>
                                    ) : null}
                                  </>
                                )}

                                {item?.stripePaymentStatus &&
                                !item.taskCompletionId
                                  ?.reasonForNotCompleted ? (
                                  <>
                                    {" "}
                                    <div
                                      className="checkmar--wait"
                                      style={{
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                        width: "100%",
                                      }}
                                    >
                                      {item.oneTimePayment == false ? (
                                        <>
                                          {item?.subscription?.intervalRecords[
                                            item?.subscription?.intervalRecords
                                              .length - 1
                                          ]?.jobCompletion?.director
                                            .isCompleted == true ||
                                          item?.subscription?.intervalRecords[
                                            item?.subscription?.intervalRecords
                                              .length - 1
                                          ]?.jobCompletion?.director.reason ? (
                                            <> </>
                                          ) : (
                                            <>
                                              {item?.latestCounterOffer
                                                ?.finalDate &&
                                              (moment().format("LL") ===
                                                moment(
                                                  item?.latestCounterOffer
                                                    ?.finalDate
                                                ).format("LL") ||
                                                moment().format("LL") ===
                                                  moment(
                                                    item?.nextDueDate
                                                  ).format("LL")) ? (
                                                <>
                                                  <button
                                                    onClick={() =>
                                                      handleCompleteReccuringJob(
                                                        item
                                                      )
                                                    }
                                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <span>
                                                      <IoCheckmarkCircle /> Mark
                                                      as Completed 
                                                    </span>
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      handleDeniedReccuringProgressTask(
                                                        item
                                                      )
                                                    }
                                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <span>
                                                      <IoIosCloseCircle /> Deny
                                                      Completion
                                                    </span>
                                                  </button>
                                                </>
                                              ) : item?.taskProposal
                                                  ?.proposalDate &&
                                                (moment().format("LL") ===
                                                  moment(
                                                    item?.taskProposal
                                                      ?.proposalDate
                                                  ).format("LL") ||
                                                  moment().format("LL") ===
                                                    moment(
                                                      item?.nextDueDate
                                                    ).format("LL")) ? (
                                                <>
                                                  <button
                                                    onClick={() =>
                                                      handleCompleteReccuringJob(
                                                        item
                                                      )
                                                    }
                                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <span>
                                                      <IoCheckmarkCircle /> Mark
                                                      as Completed 
                                                    </span>
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      handleDeniedReccuringProgressTask(
                                                        item
                                                      )
                                                    }
                                                    className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                    style={{
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    <span>
                                                      <IoIosCloseCircle /> Deny
                                                      Completion
                                                    </span>
                                                  </button>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.latestCounterOffer
                                            ?.finalDate &&
                                          (moment().format("LL") ===
                                            moment(
                                              item?.latestCounterOffer
                                                ?.finalDate
                                            ).format("LL") ||
                                            moment().format("LL") ===
                                              moment(item?.nextDueDate).format(
                                                "LL"
                                              )) ? (
                                            <>
                                              <button
                                                onClick={() =>
                                                  handleShowProgressTaskPopup(
                                                    item?._id
                                                  )
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                style={{ textAlign: "left" }}
                                              >
                                                <span>
                                                  <IoCheckmarkCircle /> Mark as
                                                  Completed 
                                                </span>
                                              </button>
                                              
                                              <button
                                                onClick={() =>
                                                  handleDeniedProgressTask(
                                                    item?._id
                                                  )
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                style={{ textAlign: "left" }}
                                              >

                                                <span>
                                                  <IoIosCloseCircle /> Deny
                                                  Completion
                                                </span>
                                              </button>
                                            </>
                                          ) : 
                                          item?.taskProposal ?.proposalDate &&
                                            // (moment().format("LL") ===moment(item?.taskProposal?.proposalDate).format("LL") ||
                                            //   moment().format("LL") ===moment(item?.nextDueDate).format("LL")) 
                                              
                                              (moment().isSameOrAfter(moment(item?.taskProposal?.proposalDate), 'day') ||
                                                moment().isSameOrAfter(moment(item?.nextDueDate), 'day'))
                                              ?
                                             (
                                            <>
                                              <button
                                                onClick={() =>
                                                  handleShowProgressTaskPopup(
                                                    item?._id
                                                  )
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                style={{ textAlign: "left" }}
                                              >
                                                <span>
                                                  <IoCheckmarkCircle /> Mark as
                                                  Completed 
                                                </span>
                                              </button>
                                              <button
                                                onClick={() =>
                                                  handleDeniedProgressTask(
                                                    item?._id
                                                  )
                                                }
                                                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                                style={{ textAlign: "left" }}
                                              >
                                                <span>
                                                  <IoIosCloseCircle /> Deny
                                                  Completion
                                                </span>
                                              </button>
                                            </>
                                          ) 
                                          
                                          : (
                                            <></>
                                          )}
                                        </>
                                      )}

                                      {item.oneTimePayment == false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleEndSubScription(item)
                                            }
                                            className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                            style={{ textAlign: "left" }}
                                          >
                                            <span>
                                              <IoIosCloseCircle /> End
                                              Subscription
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="task--items--details">
                            <div
                              className="task--detail-full"
                              style={{ width: "100%" }}
                            >
                              <Link
                                to={`/task/${item._id}`}
                                style={{
                                  color: "#212529",
                                  textDecoration: "none",
                                }}
                              >
                                <h2 className="hover-text">{item?.title}</h2>
                              </Link>
                              <div className="service--category--task">
                                <h3> {item?.serviceName}</h3>
                              </div>
                            </div>
                          </div>

                          <div className="task--items--content">
                            <p>
                              <span className="task--items--content--icon">
                                <AiOutlineCalendar />
                              </span>
                              {setReportFormatDate(item?.dueDate)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <TbMoneybag />
                              </span>{" "}
                              Budget: ${BudgetFormat(`${item?.budget}`)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <GrLocation />
                              </span>{" "}
                              Area: {item?.cityName}
                            </p>
                          </div>
                          <div className="task--items-footer">
                            {item?.description
                              ? item?.description?.length > 400
                                ? `${item?.description?.substring(0, 400)}...`
                                : `${item?.description}`
                              : "No description available"}
                          </div>
                        </div>

                        {item?.oneTimePayment ? null : (
                          <DirectorJobFrequently item={item} />
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="no--data--dedected">
                      No in progress jobs to display
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`tab-pane fade ${
                  activeTab === "completed-task" ? "show active" : ""
                }`}
                id="completed-task"
                role="tabpanel"
                aria-labelledby="completed-task-tab"
              >
                <div className="list--tasks">
                  {CompletedTask && CompletedTask?.length > 0 ? (
                    CompletedTask.map((item, index) => (
                      <div
                        key={index}
                        className="created--task--lists process--task--list"
                      >
                        <div className="mark-status">
                          <span>
                            <LuPartyPopper /> Completed Job
                          </span>
                        </div>

                        <div className="task--work">
                          <div
                            className="task--items--header"
                            style={{ marginBottom: "0px" }}
                          >
                            <div className="task--add--info">
                              <div className="user--task--info">
                                <div className="task--info--img">
                                  <Link
                                    to={`/user-info/${item?.task?.hustlerId?._id}`}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.task?.hustlerId?.profilePic}`}
                                    />
                                  </Link>
                                </div>
                                <div className="task--info--details">
                                  <span>
                                    {item?.task?.hustlerId?.fname}{" "}
                                    {item?.task?.hustlerId?.lname}
                                  </span>
                                  <br />
                                  <p>
                                    <img src="../assets/images/hustler-star.png" />{" "}
                                    {handleDecimalNumber(
                                      item?.task?.hustlerId?.hustlerDetails
                                        ?.ratings
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="task--items--edit--delete completed-jobs">
                              {item?.task?.directorReviewed ? (
                                <button
                                  onClick={() => handleViewOpenModal(item)}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <MdOutlineRemoveRedEye /> View Review
                                  </span>
                                </button>
                              ) : (
                                <button
                                  onClick={() => handleAddOpenModal(item)}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <MdReviews /> Add Review
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>

                          <div className="task--items--details">
                            <div
                              className="task--detail-full"
                              style={{ width: "100%" }}
                            >
                              <Link
                                to={`/task/${item?.task?._id}`}
                                style={{
                                  color: "#212529",
                                  textDecoration: "none",
                                }}
                              >
                                <h2 className="hover-text">{item?.task?.title}</h2>
                              </Link>
                              <div className="service--category--task">
                                <h3> {item?.task?.serviceName}</h3>
                              </div>
                            </div>
                          </div>

                          <div className="task--items--content">
                            <p>
                              <span className="task--items--content--icon">
                                <AiOutlineCalendar />
                              </span>
                              {setReportFormatDate(item?.task?.dueDate)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <TbMoneybag />
                              </span>{" "}
                              Budget: ${BudgetFormat(`${item?.task?.budget}`)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <GrLocation />
                              </span>{" "}
                              Area: {item?.task?.cityName}
                            </p>
                          </div>

                          <div className="task--items-footer">
                            {item?.task?.description
                              ? item?.task?.description?.length > 400
                                ? `${item?.task?.description?.substring(
                                    0,
                                    400
                                  )}...`
                                : `${item?.task?.description}`
                              : "No description available"}
                          </div>
                        </div>
                        {item?.task?.oneTimePayment ? null : (
                          <div
                            className={`task--items-down--footer mark-status`}
                          >
                            <p>
                              Please note that this is a{" "}
                              <b>{item?.task?.subscriptionInterval}ly</b> job,
                              and payment will be made on a{" "}
                              <b>{item?.task?.subscriptionInterval}ly</b> basis
                              upon completion.{" "}
                            </p>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="no--data--dedected">
                      No completed jobs to display
                    </div>
                  )}
                </div>
              </div>
              
              <div
                className={`tab-pane fade ${
                  activeTab === "in-closed" ? "show active" : ""
                }`}
                id="in-closed"
                role="tabpanel"
                aria-labelledby="in-closed-tab"
              >
                <div className="list--tasks">
                  {ClosedTask && ClosedTask?.length > 0 ? (
                    ClosedTask.map((item, index) => (
                      <div
                        key={index}
                        className="created--task--lists process--task--list"
                      >
                        {(item?.blocked == true ||
                          item?.status === "closed") && (
                          <div className="cancelled--interested-job">
                            <span>
                              <IoIosCloseCircleOutline />{" "}
                              {item?.blocked == true ? "Blocked" : "Closed"} Job
                            </span>

                            {item?.blocked == true && (
                              <span style={{ color: "#FF0000" }}>
                                This job is blocked for breaching policy.
                              </span>
                            )}
                                <span
                                  className="view--reason"
                                  onClick={() => window.location.href = 'https://sidehustlsupport.zendesk.com/hc/en-us/requests/new'}
                                >
                                  Help Center<GoArrowRight />
                                </span>

                            {item?.taskCompletionId !== null ? (
                              <span
                                onClick={() =>
                                  setshowDeclinedJobTaskModal({
                                    model: true,
                                    reason:
                                      item?.taskCompletionId
                                        ?.reasonForNotCompleted,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#000",
                                }}
                              >
                                View Reason <GoArrowRight />
                              </span>
                            ) : null}
                          </div>
                        )}
                        <div className="task--work">
                          <div className="task--items--details">
                            <div
                              className="task--detail-full"
                              style={{ width: "100%" }}
                            >
                              <h2>{item?.title}</h2>
                              <div className="service--category--task">
                                <h3> {item.serviceName}</h3>
                              </div>
                            </div>
                          </div>

                          <div className="task--items--content">
                            <p>
                              <span className="task--items--content--icon">
                                <AiOutlineCalendar />
                              </span>
                              {setReportFormatDate(item.dueDate)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <TbMoneybag />
                              </span>{" "}
                              Budget: ${BudgetFormat(`${item?.budget}`)}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <GrLocation />
                              </span>{" "}
                              Area: {item?.cityName}
                            </p>
                          </div>
                          <div className="task--items-footer">
                            {item?.description
                              ? item?.description?.length > 400
                                ? `${item?.description?.substring(0, 400)}...`
                                : `${item?.description}`
                              : "No description available"}
                          </div>
                        </div>

                        {item?.oneTimePayment ? null : (
                          <div
                            className={`task--items-down--footer cancelled--interested-job`}
                          >
                            <p>
                              Please note that this is a{" "}
                              <b>{item?.subscriptionInterval}ly</b> job, and
                              payment will be made on a{" "}
                              <b>{item?.subscriptionInterval}ly</b> basis upon
                              completion.{" "}
                            </p>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="no--data--dedected">
                      No closed jobs to display
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPosts={totalPosts}
              paginate={paginate}
              postsPerPage={postsPerPage}
            />
          </div>
        </div>
      </section>

      {showeditModal && (
        <EditcreateTask
          EditupdateTask={EditupdateTask}
          setshoweditModal={setshoweditModal}
          singleTask={singleTask}
        />
      )}

      {deleteCreatedTask && (
        <DeleteCreatedTask
          deleteCreatedTask={deleteCreatedTask}
          closeModal={closeModal}
          handleCloseTask={handleCloseTask}
        />
      )}
      {showDirectorDeniedJobModel && (
        <DirectorDeniedJobModel
          setTaskDetail={setTaskDetail}
          TaskDetail={TaskDetail}
          handleDeniedReccuringProgressTask={handleDeniedReccuringProgressTask}
          handleDeniedProgressTask={handleDeniedProgressTask}
          setshowDirectorDeniedJobModel={setshowDirectorDeniedJobModel}
        />
      )}

      {showAnotherHustlr.model && (
        <AnotherHustlrLists
          handleChangeHustler={handleChangeHustler}
          setLoading={setLoading}
          setshowAnotherHustlr={setshowAnotherHustlr}
          showAnotherHustlr={showAnotherHustlr}
        />
      )}

      {showDeleteProgressTaskModal && (
        <DeleteProgressTaskModal
          setshowDeleteProgressTaskModal={setshowDeleteProgressTaskModal}
          handleDeleteProgressTask={handleDeleteProgressTask}
        />
      )}

      {showPublicJobModel && (
        <PublicJobModel
          handlePublic={handlePublic}
          setshowPublicJobModel={setshowPublicJobModel}
        />
      )}

      {showdirectorCounterOffermodel && (
        <DirectorCounterOfferModel
          handleDeniedJob={handleDeniedJob}
          CounterOffer={CounterOffer}
          allCounterOfferdetails={allCounterOfferdetails}
          setallCounterOfferdetails={setallCounterOfferdetails}
          setshowdirectorCounterOffermodel={setshowdirectorCounterOffermodel}
          incomingJobtaskId={incomingJobtaskId}
          singleTask={singleTask}
          handleAcceptcounterOffer={handleAcceptcounterOffer}
        />
      )}

      {showConfirmProgressTask && (
        <ConfirmProgressTask
          setcheckboxValue={setcheckboxValue}
          handleSubmitProgressTask={handleSubmitProgressTask}
          setshowConfirmProgressTask={setshowConfirmProgressTask}
          taskID={taskID}
          setallId={setallId}
          allId={allId}
          handleCompleteReccuringJob={handleCompleteReccuringJob}
          setTaskDetail={setTaskDetail}
          TaskDetail={TaskDetail}
        />
      )}

      {showReviewModel && (
        <AddReviews
          SensitiveWords={SensitiveWords}
          taskReviewDetails={taskReviewDetails}
          handleEditReview={handleEditReview}
          handleSubmitReview={handleSubmitReview}
          setshowReviewModel={setshowReviewModel}
          settaskReviewDetails={settaskReviewDetails}
        />
      )}

      {rejectedJobModal && (
        <RejectedJobReason
          setRejectedJobModal={setRejectedJobModal}
          rejectedJobDetails={rejectedJobDetails}
        />
      )}

      {showdenieJobModel && (
        <ConfirmDeniedJobModel
          handleCancelDeniedJob={handleCancelDeniedJob}
          handleDeniedJob={handleDeniedJob}
          setshowdenieJobModel={setshowdenieJobModel}
        />
      )}
      {showEndSubscriptionModel && (
        <EndSubsciption
          handleEndSubScription={handleEndSubScription}
          setshowEndSubscriptionModel={setshowEndSubscriptionModel}
        />
      )}

      {showPaymentForm && (
        <div className="payment-form">
          <Elements stripe={stripePromise}>
            <PaymentForm
              finaltaskDetails={finaltaskDetails}
              setshowPaymentForm={setshowPaymentForm}
              paymentIntentId={paymentIntentId}
              onSuccess={() => {
                bookHustler(taskPaymentId);
              }}
            />
          </Elements>
        </div>
      )}

{/* {BeforeShowPaymentForm && (
        <div className="payment-form">
            <BeforePaymentForm
              finaltaskDetails={finaltaskDetails}
              setBeforeShowPaymentForm={setBeforeShowPaymentForm}
              handleSubmit={handleSubmit}
              itemCheck={itemCheck}
            />
        </div>
      )} */}


      {rejectedTaskModal.model && (
        <TaskCompletionRejectedModal
          rejectedTaskModal={rejectedTaskModal}
          setRejectedTaskModal={setRejectedTaskModal}
        />
      )}

      {showDeclinedJobTaskModal.model && (
        <DeclinedJobCompletedModal
          showDeclinedJobTaskModal={showDeclinedJobTaskModal}
          setshowDeclinedJobTaskModal={setshowDeclinedJobTaskModal}
        />
      )}
    </>
  );
};

export default IsLoadingHOC(DirectorJobs);
