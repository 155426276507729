// import React, { useEffect, useState } from 'react'
// import { useSelector } from "react-redux";
// import { AiOutlineCalendar, AiOutlineHeart } from 'react-icons/ai';
// import { GrLocation } from 'react-icons/gr';
// import { IoCloseSharp, IoSearchSharp } from "react-icons/io5";
// import { TbMoneybag } from 'react-icons/tb';
// import Select from "react-select";
// import { initiateSocketConnection, disconnectSocket, subscribeToChat, sendMessage } from '../../services/socketService';
// const DirectorMessage = ({ closeShowInterestModal }) => {
//   const [selectedService, setSelectedService] = useState(null);
//   const taskOptions = useSelector((state) => state.app.tasks);
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');


//   const handleSelectTask = (option) => {
//     setSelectedService(option);
//   };
//   const handleClick = (value) => {
//     console.log(value);
//     setSelectedService(value)
//   };
//   useEffect(() => {
//     const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmbmFtZSI6IkRpcmVjdG9yIiwibG5hbWUiOiJPbmUiLCJlbWFpbCI6ImRpcmVjdG9yQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY1Njg1ZGE1ODRhNTU4NzA4MzFmOGJiMCIsInVzZXJUeXBlIjoiRElSRUNUT1IiLCJpYXQiOjE3MDE3NzE2NjEsImV4cCI6MTcwMjM3NjQ2MX0.B3EgvtGWAGoccAOjskQ6oLtAxdKDEVlifrTsJTV3NOQ"
//     initiateSocketConnection(token);

//     return () => {
//       disconnectSocket();
//     };
//   }, []);

//   const handleSendMessage = () => {
//     if (!newMessage.trim()) return; // Prevent sending empty messages
//     const senderId = "65685da584a55870831f8bb0"; // Replace with actual sender ID
//     const receiverId = "65685d7f84a55870831f8ba8"; // Replace with actual receiver ID

//     sendMessage(senderId, receiverId, newMessage);
//     setNewMessage(''); // Clear input after sending
//   };
//   useEffect(() => {
//     subscribeToChat((err, newMessage) => {
//       if (err) return;
//       console.log('New message received:', newMessage);
//       setMessages(prevMessages => [...prevMessages, newMessage]);
//     });
//   });


//   return (
//     <>
//       <div className='message-container pt-117 pb-5'>
//         <div className='heading--page'>
//           <h1 className='text--dark playFairDisplay heading-h1 mb-2'>Messages</h1>
//         </div>
//         <div id="frame" className='mx-auto'>
//           <div id="sidepanel">
//             <div id="search">
//               <label htmlFor="">
//                 <IoSearchSharp />

//               </label>
//               <input type="text" placeholder="Search" />
//             </div>
//             <div id="contacts">
//               <ul>
//                 <li className="contact">
//                   <div className="wrap">
//                     <img src="./assets/images/profile/profile-one.png" alt="" />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact active">
//                   <div className="wrap">
//                     <img src="./assets/images/profile/profile-two.png" alt="" />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact">
//                   <div className="wrap">
//                     <img src="./assets/images/profile/profile-three.png" alt="" />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact">
//                   <div className="wrap">
//                     <img src="./assets/images/profile/profile-four.png" alt="" />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact">
//                   <div className="wrap">
//                     <img
//                       src="./assets/images/profile/profile-five.png"
//                       alt=""
//                     />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact">
//                   <div className="wrap">
//                     <span className="contact-status" />
//                     <img
//                       src="./assets/images/profile/profile-six.png"
//                       alt=""
//                     />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact">
//                   <div className="wrap">
//                     <img src="./assets/images/profile/profile-saven.png" alt="" />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact">
//                   <div className="wrap">
//                     <img
//                       src="./assets/images/profile/profile-eight.png"
//                       alt=""
//                     />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//                 <li className="contact">
//                   <div className="wrap">
//                     <img
//                       src="./assets/images/profile/profile-one.png"
//                       alt=""
//                     />
//                     <div className="meta">
//                       <p className="name mb-0">Jhon Carter <small className="timee"> 12:30 </small></p>
//                       <p className="preview">Lorem ipsum dolor sit amet, consectetuer </p>
//                       <div class="online-signal"></div>
//                     </div>
//                   </div>
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="content">
//             <div className="contact-profile">
//               <img src="./assets/images/profile/profile-two.png" alt="" />
//               <p className='mb-0 pt-3'>Jhon Carter</p>
//               <div class="online-signal"></div>
//               <small class="timee"> Online </small>
//               <div className="social-media pt-3">
//                 <img
//                   src="./assets/images/tool-tip.png"
//                   alt=""
//                 />
//               </div>
//             </div>
//             <div className="messages">
//               <ul>
//                 {messages.map((msg, index) => (
//                   <li key={index} className={msg.senderId === "65685da584a55870831f8bb0" ? "sent" : "replies"}>
//                     <p>{msg.message}</p>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//             <div className="message-input">
//               <div className="wrap">
//                 <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <rect x="0.5" y="0.5" width="57" height="57" rx="7.5" fill="white" stroke="#F0F0F0" />
//                   <path d="M29.5 41C26.4282 41 23.5403 39.8038 21.3682 37.6318C19.1962 35.4597 18 32.5718 18 29.5C18 26.4282 19.1962 23.5403 21.3682 21.3682C23.5403 19.1962 26.4282 18 29.5 18C32.5718 18 35.4597 19.1962 37.6318 21.3682C39.8038 23.5403 41 26.4282 41 29.5C41 32.5718 39.8038 35.4597 37.6318 37.6318C35.4597 39.8038 32.5718 41 29.5 41ZM29.5 19.7969C24.1497 19.7969 19.7969 24.1497 19.7969 29.5C19.7969 34.8503 24.1497 39.2031 29.5 39.2031C34.8503 39.2031 39.2031 34.8503 39.2031 29.5C39.2031 24.1497 34.8503 19.7969 29.5 19.7969ZM33.7108 31.485C33.2922 31.2186 32.7369 31.342 32.4705 31.7606C32.4595 31.778 31.3413 33.5008 29.4551 33.5008C27.5688 33.5008 26.4507 31.778 26.4396 31.7606C26.1732 31.342 25.6179 31.2186 25.1993 31.485C24.7807 31.7514 24.6573 32.3067 24.9237 32.7253C24.9905 32.8303 26.5935 35.2977 29.4551 35.2977C32.3166 35.2977 33.9196 32.8304 33.9865 32.7253C34.2529 32.3067 34.1295 31.7514 33.7108 31.485ZM25.5469 25.4121C26.1671 25.4121 26.6699 25.9149 26.6699 26.5352C26.6699 27.1554 26.1671 27.6582 25.5469 27.6582C24.9266 27.6582 24.4238 27.1554 24.4238 26.5352C24.4238 25.9149 24.9266 25.4121 25.5469 25.4121ZM32.2852 26.5352C32.2852 27.1554 32.788 27.6582 33.4082 27.6582C34.0284 27.6582 34.5312 27.1554 34.5312 26.5352C34.5312 25.9149 34.0284 25.4121 33.4082 25.4121C32.788 25.4121 32.2852 25.9149 32.2852 26.5352Z" fill="black" />
//                 </svg>
//                 <input type="text" placeholder="Type Message" value={newMessage}
//                   onChange={(e) => setNewMessage(e.target.value)} />
//                 <button className="submit" onClick={handleSendMessage}>
//                   <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <rect width="36" height="36" rx="6" fill="#1F1F28" />
//                     <path d="M25.8623 10.1373C25.7297 10.0047 25.5308 9.96391 25.3568 10.0335L10.2947 16.0583C10.1223 16.1272 10.0069 16.2914 10.0003 16.4769C9.9937 16.6624 10.0972 16.8343 10.2642 16.9153L16.2037 19.7959L19.0843 25.7353C19.1628 25.8973 19.3269 25.9995 19.5059 25.9995C19.5115 25.9995 19.5171 25.9995 19.5227 25.9992C19.7081 25.9926 19.8723 25.8772 19.9412 25.7049L25.9661 10.6429C26.0357 10.4687 25.9949 10.2699 25.8623 10.1373ZM11.6279 16.5347L23.5846 11.7521L16.4589 18.8777L11.6279 16.5347ZM19.4648 24.3717L17.1218 19.5406L24.2475 12.4149L19.4648 24.3717Z" fill="white" />
//                   </svg>

//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default DirectorMessage