import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { LiaUserCircleSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { logout, setallnotifications } from "../Redux/Reducers/authSlice";
import { toast } from "react-toastify";
import { socketService } from "./../services/socket";
import { FaSquare } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { authAxios } from "../services/config";
import { useRef } from "react";
import Notifications from "../common/Notifications";
import { clearParticipantDetails } from "../Redux/Reducers/chatSlice";
import { FaUser, FaBriefcase, FaStar } from "react-icons/fa6";
import { IoHomeOutline, IoSettingsOutline } from "react-icons/io5";
import { PiFolderSimplePlusLight } from "react-icons/pi";
import { FaPlay } from "react-icons/fa"; // Import an icon, FaPlay is just an example



import Joyride from "react-joyride";
import { FALSE } from "sass";


const CommonHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const CurrentPath = location.pathname;
  const user = useSelector((state) => state.auth.user);
  const [arrivalMessage, setArrivalMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [profileViewDropdown, setProfileViewDropdown] = useState(false);
  const [activeOverlayMenu, setActiveOverlayMenu] = useState(false);
  const dropdownRef = useRef(null);

  const [userDetails, setuserDetails] = useState({
    personalDetails: {
      fname: "",
      lname: "",
      profilePic: "",
    },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileViewDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const fetchUserDetails = async () => {
    await authAxios()
      .get("profile/view-profile")
      .then((response) => {
        const resData = response?.data;
        if (resData.status == 1) {
          setuserDetails(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };


  useEffect(() => {
    if (arrivalMessage) {
      const existData = messages.find(
        ({ userId, task_id }) =>
          userId === arrivalMessage.userId && arrivalMessage.task_id
      );
      if (!existData){
        setMessages((prev) => [...prev, arrivalMessage]);
      }
    }
    fetchUserDetails();
  }, [arrivalMessage]);

  useEffect(() => {
    socketService.connect().then(async (socket) => {
      await socket.on("accepted-task", (data) => {
        setArrivalMessage(data);
      });
    });
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    toast.success("Logout successful");
    dispatch(setallnotifications([]))
    dispatch(clearParticipantDetails());
    localStorage.removeItem("CityName")
    localStorage.removeItem("Province")
    localStorage.removeItem("Keyword")
    sessionStorage.removeItem('subscriptionDetails')
    sessionStorage.removeItem('paymentDetails')
    sessionStorage.removeItem('redirectPath1')

    navigate("/login");
  };

  const handleProviewViewDropdown = () => {
    fetchUserDetails()
    setProfileViewDropdown(!profileViewDropdown);
  };


  const handleMenuOverlayButton = () => {
    setActiveOverlayMenu(!activeOverlayMenu);
  };

  const handleMenuOverlayClose = () => {
    setActiveOverlayMenu(false);
  };

  useEffect(() => {
    setActiveOverlayMenu(false);
  }, [location]);

  const [run, setRun] = useState(false); // Controls whether the tour is running
  const stepsForCreater = [
    {
      target: ".my-first-step", // CSS selector for the target element
      title: 'HOME',

      content: "This is the first step of the tour!",
    },
    {
      target: ".my-second-step",
      title: 'MESSAGES',

      content: "Here's the second step, showcasing another element.",
    },
    {
      target: ".my-third-step",
      title: 'HUSTLRS',

      content: "This is the third step. Congratulations!",
    },
    {
      target: ".my-fourth-step",
      title: 'YOUR JOBS',

      content: "This is the fourth step. Congratulations!",
    },
    {
      target: ".my-fifth-step",
      title: 'HELP CENTER',

      content: "This is the fifth step. Congratulations!",
    },
    {
      target: ".my-six-step",
      title: 'CREATE A JOB',

      content: "This is the six step. Congratulations!",
    },
    {
      target: ".my-seven-step",
      title: 'NOTIFICATIONS',

      content: "This is the seventh step. Congratulations!",
      placement: "right", // Tooltip appears to the right of the element

    },
    {
      target: ".my-eight-step",
      title: 'PROFILE',

      content: "This is the final step. Congratulations!",
      placement: "right", // Tooltip appears to the right of the element

    },
  ];

  const stepsForHustler = [
    {
      target: ".first-step", // CSS selector for the target element
      title: 'NEW JOB',

      content: "This is the first step of the tour!",
    },
    {
      target: ".second-step",
      title: 'YOUR JOBS',

      content: "Here's the second step, showcasing another element.",
    },
    {
      target: ".third-step",
      title: 'MESSAGES',

      content: "This is the third step. Congratulations!",
    },
    {
      target: ".fourth-step",
      title: 'HELP CENTER',

      content: "This is the fourth step. Congratulations!",
    },
    {
      target: ".fifth-step",
      title: 'NOTIFCATIONS',

      content: "This is the fifth step. Congratulations!",
    },
    {
      target: ".six-step",
      title: 'PROFILE',

      content: "This is the six step. Congratulations!",
      placement: "right", // Tooltip appears to the right of the element

    },
  ];


  const TourStart = async (status) => {
    await authAxios()
      .post("/users/restart-tour", {
        // Include any data you want to send in the POST request here (optional)
        // For example:s
        // userId: "12345"
        restartTour: status,
      })
      .then((response) => {
        const resData = response;
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  
  const handleStartTour = (PLrestartTour)=>{
    TourStart(PLrestartTour)
    setRun(PLrestartTour)
  }
  const handleTourCompletion = async (data) => {
    const { status, type ,action} = data;
    if (action === "skip") {
      TourStart(false); // Assuming TourStart is your function to call on tour end or skip
      setRun(false); // Stop the tour


    } else if ( action=== 'close') {
      TourStart(false); // Assuming TourStart is your function to call on tour end or skip
      setRun(false); // Stop the tour

    } 
    // Check if the tour is completed or manually closed/skipped
    if (status === "finished") {
     

      try {
        await TourStart(); // Assuming TourStart is your function to call on tour end or skip
        setRun(false); // Stop the tour
      } catch (error) {
        console.error("Error in TourStart:", error);
      }
    }
  };



  return (
    <>
      <div className="header--director">
      <Joyride
        steps={user?.userType === "DIRECTOR" ? stepsForCreater : stepsForHustler}
        run={run}  // Starts the tour automatically if true
        continuous={true} // Moves to the next step automatically
        scrollToFirstStep={true} // Scrolls to the first step
        showProgress={true} // Shows progress
        showSkipButton={true} // Displays skip button
        callback={handleTourCompletion} // Set callback for completion

        styles={{
          options: {
            zIndex: 10000, // Ensure the tour is above everything
            // arrowColor: "black", // Arrow color
            // backgroundColor: "red", // Tooltip background color
            // overlayColor: "green", // Background overlay color
            primaryColor: "black", // Button color
            textColor: "#333", // Tooltip text color
            spotlightShadow: "black", // Spotlight effect
            width: 300, // Width of the tooltip

          },
        }}
      />
        <header
          className={`main--header`}
          data-aos="fade-in"
          data-aos-duration="1500"
          style={{ backgroundColor: activeOverlayMenu ? "var(--white)" : "" }}
        >

          <div className="max-w-1920 flex items-center justify-between sm-flex-col">
            <div className="main--nav">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="navbar-brand">
                  <Link to="/">
                    <img
                      src="/assets/images/logo.png"
                      alt="logo"
                      onClick={() => dispatch(clearParticipantDetails())}
                    />
                  </Link>

                  <p className="user--type">{user?.userType === "DIRECTOR" ? 'Creatr' : 'Hustlr'} </p>
                </div>
           {/* //======================= */}
                        <Link
                          onClick={() => handleStartTour(true)}
                          style={{
                            display: "flex", 
                            alignItems: "center", 
                          }}
                        >
                                <FaPlay style={{ marginRight: "8px" }} /> 

                          start tour
                        </Link>
{/* //============================ */}

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={handleMenuOverlayButton}
                >
                  
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className={`collapse navbar-collapse ${activeOverlayMenu === true ? "show" : ""
                    }`}
                  id="navbarNav"
                >
                  <ul className="navbar-nav ml-auto">
                    <li
                      style={{
                        borderBottom:
                          CurrentPath === "/home" ? "2px solid black" : "none",
                      }}
                      className="nav-item"
                      onClick={() => dispatch(clearParticipantDetails())}
                    >
                      <Link className="nav-link my-first-step first-step" to="/">
                        {user?.userType === "DIRECTOR" ? 'Home' : 'New Jobs'}
                      </Link>
                    </li>

                    {user?.userType === "HUSTLER" && (
                      <li className="nav-item second-step">
                        <Link
                          onClick={() => dispatch(clearParticipantDetails())}
                          className="nav-link"
                          to="/hustlr-jobs"
                          style={{
                            borderBottom:
                              CurrentPath === "/hustlr-jobs"
                                ? "2px solid black"
                                : "none",
                          }}
                        >
                          Your Jobs
                        </Link>
                      </li>
                    )}

                    <li
                      className="nav-item"
                      style={{
                        borderBottom:
                          CurrentPath === "/messages"
                            ? "2px solid black"
                            : "none",
                      }}
                    >
                      <Link className="nav-link my-second-step third-step"  to="/messages">
                        Messages
                      </Link>
                    </li>
                    {user?.userType === "DIRECTOR" && (
                      <li
                        className="nav-item"
                        style={{
                          borderBottom:
                            CurrentPath === "/hustlrs-lists"
                              ? "2px solid black"
                              : "none",
                        }}
                      >
                        <Link
                          className="nav-link my-third-step"
                          to="/hustlrs-lists"
                          onClick={() => dispatch(clearParticipantDetails())}
                        >
                          Hustlrs
                        </Link>
                      </li>
                    )}

                    {user?.userType === "DIRECTOR" && (
                      <li className="nav-item">
                        <Link
                          onClick={() => dispatch(clearParticipantDetails())}
                          className="nav-link my-fourth-step"
                          to="/your-task-list"
                          style={{
                            borderBottom:
                              CurrentPath === "/your-task-list"
                                ? "2px solid black"
                                : "none",
                          }}
                        >
                          Your Jobs
                        </Link>
                      </li>
                    )}

                    <li
                      className="nav-item my-fifth-step fourth-step"
                      onClick={() => window.open('https://sidehustlsupport.zendesk.com')}
                    >
                      <Link className="nav-link" >
                        Help Center
                      </Link>
                    </li>



                    <li
                      className="nav-item hide-menu mobile--item--nav "
                      onClick={() => dispatch(clearParticipantDetails())}
                    >
                      <span
                        className="nav-link c-pointer"
                        onClick={handleLogout}
                      >
                        <MdLogout /> Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="sign--btn flex items-center hide--mb">
              {user?.userType === "DIRECTOR" && (
                <Link to="/create-task" className="btn btn-dark darkbtn-hover my-six-step">
                  <span>+ Create A Job</span>
                </Link>
              )}
               <div className="my-seven-step fifth-step" >
              <Notifications />
              </div>

              <div className="profile--dropdown c-pointer my-eight-step six-step " ref={dropdownRef}>
                <LiaUserCircleSolid
                  className="nav-link"
                  onClick={handleProviewViewDropdown}
                />
              </div>
            </div>
          </div>
        </header>

        {profileViewDropdown && (
          <div className="notification--lists profile--view--dropdown">
            <ul>
              <div className="profile--info-detail">
                <img

                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetails.personalDetails.profilePic}`}
                />
                <p style={{wordWrap:'break-word'}}>
                  {userDetails.personalDetails.fname}{" "}
                  {userDetails.personalDetails.lname}
                </p>
              </div>
              <li>
                <Link to="/profile" className="dropdown-item c-pointer">
                {/* <FaUser style={{ fontSize: '2px' }} /> Profile */}
                <FaUser style={{ width: '20px', height: '18px', marginTop:'2px' }} />Profile 

                </Link>
              </li>
              
              <li>
                <Link to="/ChangePassword" className="dropdown-item c-pointer">
                  <IoSettingsOutline /> Change Password 
                </Link>
              </li>


              <li>
                <span
                  className="dropdown-item c-pointer"
                  onClick={handleLogout}
                >
                  <MdLogout /> Logout
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
      {activeOverlayMenu && (
        <div className="overlay--menu" onClick={handleMenuOverlayClose}></div>
      )}

      <div className="sticky--header--mobile">
        <nav>
          <Link to="/" class="nav-item">
            <IoHomeOutline /> <span>Home</span>
          </Link>

          {user?.userType === "DIRECTOR" && (
            <Link class="nav-item" to="/create-task">
              <PiFolderSimplePlusLight /> <span>Create A Job</span>
            </Link>
          )}
          <div className="nav-item" style={{ overflow: "hidden" }}>
            <Notifications /> <span>Notifications</span>
          </div>
          <Link class="nav-item" to="/profile">
            <LiaUserCircleSolid /> <span>Profile</span>
          </Link>
        </nav>
      </div>
    </>
  );
};
export default CommonHeader;
