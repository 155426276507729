import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { LuSmile } from "react-icons/lu";
import {
  IoCloseCircle,
  IoPaperPlaneOutline,
  IoSearchSharp,
} from "react-icons/io5";
import {
  CheckallSensitiveWords,
  extractTime,
  getFirstName,
  handleEmailandPhoneCheck,
} from "../utils/helper";
import TextareaAutosize from "react-textarea-autosize";
import { Tooltip } from "react-tooltip";
import { authAxios } from "../services/config";
import {
  setParticipantDetails,
  clearParticipantDetails,
} from "../Redux/Reducers/chatSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { PiChatsLight } from "react-icons/pi";
import { FaChevronLeft, FaRegImage } from "react-icons/fa6";
import ChatIdTask from "./ChatIdTask";
import { useSocket } from "../contexts/SocketContext";
import EmojiPicker from "emoji-picker-react";
import { toast } from "react-toastify";
import WarningPopup from "./WarningPopup";
import { logout } from "../Redux/Reducers/authSlice";
import { MdOutlineBlock } from "react-icons/md";
import IsLoadingHOC from "./IsLoadingHOC";
import { GoChevronLeft, GoChevronRight, GoPlus } from "react-icons/go";
import { BiImageAdd } from "react-icons/bi";

const Chat = (props) => {
  const { setLoading } = props;

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();
  const inputRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [fileList, setFilesList] = useState([]);

  const { participantDetails } = useSelector((state) => state.chat);
  const { participant_id, participant_name, task_id } = participantDetails
    ? participantDetails
    : {};
  const checkedWelcomeMessage = useSelector(
    (state) => state?.chat?.participantDetails?.showWelcomeMessage
  );

  const taskId = useSelector(
    (state) => state?.chat?.participantDetails?.taskId
  );

  const ChatId = useSelector(
    (state) => state?.chat?.participantDetails?.chatId
  );
  const currentUserId = useSelector(
    (state) => state?.chat?.participantDetails?.participant_id
  );


  const [activeMobileChat, setActiveMobileChat] = useState(
    "mobile-noacitve-chat"
  );
  const [messages, setMessages] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const [chats, setChats] = useState([]);
  const messagesContainerRef = useRef(null);
  const [showChatIdModel, setshowChatIdModel] = useState(false);
  const [taskDetails, settaskDetails] = useState([]);
  const [showWarning, setshowWarning] = useState(false);
  const [SensitiveWords, setSensitiveWords] = useState([]);
  const [privacybreach, setprivacybreach] = useState("");
  const [searchkeywords, setsearchkeywords] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [unsentImages, setUnsentImages] = useState([]);
  const isReceiverSelected = userDetails && Object.keys(userDetails).length > 0;
  const isparticipantDetails =
    participantDetails && Object.keys(participantDetails).length > 0;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedMessageAttachments, setSelectedMessageAttachments] = useState(
    []
  );
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [isMediaLimit, setMediaLimit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chatUnreadCounts, setChatUnreadCounts] = useState({});
  const MAX_DISPLAY_IMAGES = 2;

  const openModal = (id, index) => {
    const message = messages.find((msg) => msg._id === id);
    if (message) {
      setSelectedMessageAttachments(message?.attachments);
      setSelectedMessageId(id);
      setCurrentImageIndex(index);
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
          nextImage();
        } else if (event.key === 'ArrowLeft') {
          prevImage();
        } else if (event.key === 'Escape') {
          closeModal();
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isModalOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedMessageAttachments?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedMessageAttachments?.length - 1 : prevIndex - 1
    );
  };

  const openModalWithAllImages = (id) => {
    const message = messages.find((msg) => msg._id === id);
    if (message) {
      setSelectedMessageAttachments(message?.attachments);
      setSelectedMessageId(id);
      setIsModalOpen(true);
    }
  };

  const removeImage = (indexToRemove) => {
    setSelectedImages((prevImages) => {
      const newImages = prevImages.filter(
        (_, index) => index !== indexToRemove
      );
      return newImages;
    });

    setFilesList((prevFiles) => {
      const newFiles = prevFiles.filter((_, index) => index !== indexToRemove);
      return newFiles;
    });
  };

  const handleSendMessage = async (taskId) => {
    const trimmedMessage = newMessage.trim();
    const hasText = trimmedMessage.length > 0;
    const hasImages = fileList.length > 0;

    if (!hasText && !hasImages) return;

    const senderId = user?.userId;
    const receiverId = participant_id;

    const messageData = {
      message: newMessage,
      senderId: senderId,
      receiverId: receiverId,
      taskId: participantDetails?.task_id || taskId,
      warning: false,
      createdAt: new Date().toISOString(),
    };

    if (hasImages) {
      try {
        const uploadedImages = await sendChatAttachments(
          fileList,
          participantDetails.chatId,
          participantDetails.taskId,
          newMessage
        );
        if (uploadedImages) {
          if (socket) {
            socket.emit("sendMessageImage", {
              chat: participantDetails?.chatId,
              sender: senderId,
              taskId: participantDetails?.taskId,
              images: uploadedImages?.data,
              receiverId: receiverId,
              message: newMessage,
            });
            const newMessageWithImages = {
              ...messageData,
              attachments: uploadedImages?.data?.attachments?.map(
                (image) => image
              ),
              text: hasText ? trimmedMessage : "",
            };

            setMessages((prevMessages) => [
              ...prevMessages,
              {
                ...newMessageWithImages,
                sender: senderId,
                text: trimmedMessage,
              },
            ]);
          }
        }
      } catch (error) {
        console.error("Failed to upload images:", error);
        return;
      }
    }

    if (hasText && !hasImages) {
      if (socket) {
        socket.emit("sendMessage", messageData);
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          ...messageData,
          sender: senderId,
          text: trimmedMessage,
        },
      ]);
    }

    if (user.userType == "DIRECTOR" && !currentUserDetails.fname) {
      const firstUser = {
        fname: user?.fname,
        lname: user?.lname,
      };
      setCurrentUserDetails(firstUser);
    }

    setprivacybreach(
      "We've observed a breach in our privacy policy. As a result, this job is being set to blocked."
    );
    updateLatestMessageInChatList(receiverId, newMessage, taskId);
    setChats((prevChats) => sortChatsByLatestMessage(prevChats));
    setNewMessage("");
    setSelectedImages([]);
    setShowEmojiPicker(false);
    setFilesList([]);
    setLoading(false);
  };

  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);

    if (inputRef.current) {
      const inputValue = e.target.value;

      const containsSensitiveWord = CheckallSensitiveWords(inputValue.toLowerCase(), SensitiveWords)



      const checkEmailandPhone = handleEmailandPhoneCheck(inputValue);
      if (checkEmailandPhone || containsSensitiveWord) {
        // inputRef.current.style.color = "red";
        // setshowWarning(true);
      } else {
        // setshowWarning(false);
        inputRef.current.style.color = "";
      }
    }
  };

  const handleAttachmentChange = async (e) => {
    try {
      const MAX_IMAGES = 10;
      const files = Array.from(e.target.files);
      setFilesList((prevFiles) => [...prevFiles, ...files]);
      if (files.length === 0) {
        return;
      }

      if (selectedImages?.length + files.length > MAX_IMAGES) {
        setMediaLimit(true);
        return;
      }

      const selectedImagePreviews = files.map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages((prevImages) => [
        ...prevImages,
        ...selectedImagePreviews,
      ]);

      e.target.value = null;
    } catch (error) {
      console.error("Failed to handle attachments:", error);
    }
  };

  const sendChatAttachments = async (files, chatId, taskId, message) => {
    // alert(message)
    // alert("hi")
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("taskId", taskId);
      formData.append("message", message);
      files.forEach((file) => {
        formData.append("chat_images", file);
      });

      const response = await authAxios().post(
        "/chats/send-messages-attachments",
        formData
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Failed to send attachments:", error);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("receiveMessage", (incomingMessage) => {
        const incomingMessageAttachments = incomingMessage?.attachments
          ? incomingMessage?.attachments?.images
          : incomingMessage;

        if (!participantDetails.chatId) {
          setMessages((prevMessages) => [...prevMessages, incomingMessage]);
        }
        //  fetchUnreadMessages()


        if (
          incomingMessage?.chat ||
          incomingMessage.attachements.chat == participantDetails?.chatId
        ) {
          //   fetchMessagesForChat(participantDetails.chatId)
          // setMessages((prevMessages) => [...prevMessages, incomingMessage]);
          fetchMessagesForChat(participantDetails?.chatId);
        } else {
        }

        fetchUnreadMessages();

        setChats((prevChats) => {
          const updatedChats = prevChats.map((chat) => {
            if (chat?.chat?._id === incomingMessage?.chat) {
              return { ...chat, latestMessage: incomingMessage };
            } else if (
              incomingMessageAttachments &&
              chat?.chat?._id === incomingMessageAttachments?.attachements?.chat
            ) {
              return { ...chat, latestMessage: incomingMessageAttachments };
            }
            return chat;
          });
          return sortChatsByLatestMessage(updatedChats);
        });
      });

      return () => {
        socket.off("receiveMessage");
      };
    }
  }, [socket, participantDetails?.chatId]);

  const sortChatsByLatestMessage = (chats) => {
    return chats.sort((a, b) => {
      const aDate = a.latestMessage?.attachements?.images?.createdAt ? new Date(a.latestMessage?.attachements?.images?.createdAt) : new Date(a.latestMessage?.createdAt);
      const bDate = b.latestMessage?.attachements?.images?.createdAt ? new Date(b.latestMessage?.attachements?.images?.createdAt) : new Date(b.latestMessage?.createdAt);

      return bDate - aDate;
    });
  };


  const fetchChatData = async () => {
    try {
      const response = await authAxios().get(
        `/chats/get-all-chats-and-message`
      );
      setChats(response.data.data);
    } catch (error) {
    }
  };

  const fetchUnreadMessages = async () => {
    try {
      const response = await authAxios().get(`/chats/unread-messages`);
      const unreadCounts = {};
      response.data.data.forEach((chat) => {
        const { chatId, unreadMessages } = chat;
        unreadCounts[chatId] = unreadMessages;
      });
      setChatUnreadCounts(unreadCounts);
    } catch (error) {
      console.error("Error fetching unread message counts:", error);
    }
  };

  /*

  useEffect(() => {
    const handleNavigation = (event) => {
      if (unsentImages.length > 0) {
        const confirmationMessage = 'You have unsent images. Are you sure you want to leave?';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleNavigation);

    return () => {
      window.removeEventListener('beforeunload', handleNavigation);
    };
  }, []);
  */

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchSensitiveWords = async () => {
    setLoading(true);
    await authAxios()
      .get(`/sensitiveword/get-all-sensitive-words`)
      .then((response) => {
        const resData = response?.data;

        if (resData.status == 1) {
          setLoading(false);
          setSensitiveWords(resData?.data?.words);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchTaskDetails = async (id) => {
    await authAxios()
      .get(`/task/get-specific-task/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          settaskDetails(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  const fetchMessagesForChat = async (chatId) => {
    try {
      if (chatId) {
        const response = await authAxios().post(
          `/chats/get-chats-message/${chatId}`
        );
        if (response.data.status === 1) {
          fetchUnreadMessages();
          setMessages(response.data.data.messages);
          setUserDetails(response.data.data.otherParticipantDetails);
          setCurrentUserDetails(response.data.data.currentUserDetails);
        } else {
          setMessages([]);
        }
      }
    } catch (error) {
      setMessages([]);
      console.error("Error fetching messages:", error);
    }
  };

  const onEmojiClick = (emojiObject) => {
    setNewMessage((newMessage) => newMessage + emojiObject.emoji);
  };

  useEffect(() => {
    fetchUnreadMessages();
    fetchChatData();
    fetchMessagesForChat();
  }, [user?.userId]);

  useEffect(() => {
    fetchallDetails();
    fetchSensitiveWords();
  }, []);

  const fetchallDetails = () => {
    if (participantDetails.taskId && participantDetails.chatId) {
      fetchTaskDetails(participantDetails.taskId);
      fetchMessagesForChat(participantDetails.chatId);
    }
  };

  const handleChatSelection = async (item) => {
    fetchTaskDetails(item?.chat?.taskId);
    // setshowWarning(false);
    setNewMessage("");

    scrollToLastMessage();
    const payload = {
      participant_id: item?.participants[0]?._id,
      participant_name: item?.participants[0]?.fname,
      showWelcomeMessage: true,
      profilePic: item?.participants[0]?.profilePic,
      taskId: item?.chat?.taskId,
      chatId: item?.chat?._id,
      userStatus:
        item?.participants[0]?.hustlerDetails?.userStatus ||
        item?.participants[0]?.directorDetails?.userStatus,
    };

    dispatch(setParticipantDetails(payload));
    await fetchMessagesForChat(item?.chat?._id);
    setActiveMobileChat("mobile-active-chat");
  };

  const scrollToLastMessage = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToLastMessage();
  }, [messages]);

  const updateLatestMessageInChatList = (receiverId, messageText, taskId) => {
    setChats((prevChats) =>
      prevChats.map((chat) => {
        if (
          chat.participants.some(
            (participant) => participant._id === receiverId
          ) &&
          chat?.chat?.taskId?.toString() === taskId
        ) {
          return {
            ...chat,
            latestMessage: {
              ...chat?.latestMessage,
              text: messageText,
              createdAt: new Date().toISOString(),
            },
          };
        }
        return chat;
      })
    );
  };

  const handleSearchChat = async (value) => {
    if (value) {
      await authAxios()
        .get(`/search/search-message-chats/${value}`)
        .then((response) => {
          const resData = response.data;
          if (resData.status === 1) {
            setChats(resData.data);
            //setChats(resData.data)
          } else {
          }
        })
        .catch((error) => {
          setChats([]);
        });
    } else {
      fetchChatData();
    }
  };

  const handleBackArrowClick = () => {
    setActiveMobileChat("mobile-noacitve-chat");
  };

  const checkPreviousChat = async () => {
    try {
      const response = await authAxios().get(
        `/chats/get-all-chats-and-message`
      );

      const allChatsData = response?.data?.data;

      const allChatsItems = allChatsData.find(
        (item) =>
          item?.taskDetails?._id == taskId &&
          item?.participants[0]?._id == currentUserId
      );


      if (allChatsItems) {
        handleChatSelection(allChatsItems);
      } else {
      }

      // setChats(response.data.data);
    } catch (error) {
      //  console.error("Error fetching chat data:", error);
    }
  };

  useEffect(() => {
    checkPreviousChat();
  }, []);


  return (
    <>
      <div className="message-container pt-117 pb-5">
        <div className="heading--page">
          <h1 className="text--dark playFairDisplay heading-h1 mb-2">
            Messages
          </h1>
        </div>
        <div></div>
        <div id="frame" className="mx-auto">
          <div id="sidepanel">
            <div id="search">
              <label htmlFor="">
                <IoSearchSharp />
              </label>
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => handleSearchChat(e.target.value)}
              />
            </div>
            <div id="contacts">
              <div className="all--conversations">
                <PiChatsLight />
                <h3>All Conversations</h3>
              </div>
              <ul>
                {chats && chats.length > 0 ? (
                  chats.map(
                    (item, index) =>
                      item.participants &&
                      item.participants[0] && (
                        <li
                          className="contact"
                          key={index}
                          onClick={() => handleChatSelection(item)}
                        >
                          <div className="wrap">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.participants[0]?.profilePic}`}
                              alt=""
                            />
                            <div className="meta">
                              {chatUnreadCounts[item?.chat?._id] &&
                                chatUnreadCounts[item?.chat?._id] > 0 ? (
                                <span className="unread-count">
                                  {chatUnreadCounts[item?.chat?._id]}
                                </span>
                              ) : null}
                              <p className="name mb-0">
                                <span>{item?.participants[0]?.fname}</span>
                                {/* <div className="task--details--title">
                                  <Tooltip
                                    id="task--detail-id"
                                    className="tooltip--task--details"
                                  />
                                  {user.userType == "DIRECTOR" ? (
                                    <Link
                                      to={{
                                        pathname: "/your-task-list",
                                      }}
                                      state={{
                                        id:

                                          item.taskDetails.status == "open"
                                            ? "new-jobs" : item.taskDetails.status == "assigned"
                                              ? "in-progress"
                                              : item.taskDetails.status ==
                                                "in-progress"
                                                ? "in-progress"
                                                : item.taskDetails.status ==
                                                  "completed"
                                                  ? "completed-task"
                                                  : "",
                                      }}
                                    >
                                      <span
                                        data-tooltip-id="task--detail-id"
                                        data-tooltip-content={
                                          item?.taskDetails?.title
                                        }
                                        data-tooltip-place="top"
                                        className="task--title"
                                      >
                                        {item?.taskDetails?.title}
                                      </span>
                                    </Link>
                                  ) : (
                                    <Link
                                      to={{
                                        pathname:
                                          !item?.taskProposalDetails?.cancellation == "" ? "/hustlr-jobs" :
                                            item.taskDetails.status == "open"
                                              ? "/home"
                                              : item.taskDetails.status ==
                                                "assigned"
                                                ? "/home" : item.taskDetails.cancel == true
                                                  ? "/home"

                                                  : "/hustlr-jobs",
                                      }}
                                      state={{
                                        id:
                                          item.taskDetails.cancel == true ? "incoming-jobs" :
                                            !item?.taskProposalDetails?.cancellation == "" ? "cancelled-jobs" :
                                              item.taskDetails.status == "open"
                                                ? "interested-jobs"
                                                : item.taskDetails.status ==
                                                  "assigned"
                                                  ? "incoming-jobs"
                                                  : item.taskDetails.status ==
                                                    "in-progress"
                                                    ? "upcoming"
                                                    : item.taskDetails.status ==
                                                      "completed"
                                                      ? "completed-jobs"
                                                      : "",
                                      }}
                                    >
                                      <span
                                        data-tooltip-id="task--detail-id"
                                        data-tooltip-content={
                                          item?.taskDetails?.title
                                        }
                                        data-tooltip-place="top"
                                        className="task--title"
                                      >
                                        {item?.taskDetails?.title}
                                      </span>
                                    </Link>
                                  )}
                                </div> */}

                                <small className="timee">
                                  {" "}
                                  {item?.latestMessage &&
                                    item?.latestMessage?.createdAt
                                    ? extractTime(
                                      item?.latestMessage?.createdAt
                                    )
                                    : item?.latestMessage?.attachements?.images
                                      ?.createdAt &&
                                    extractTime(
                                      item?.latestMessage?.attachements
                                        ?.images?.createdAt
                                    )}
                                </small>
                              </p>
                              {/* <p className="name mb-0">
                                <span>{item?.participants[0]?.fname}</span>
                                <div className="task--details--title">
                                  <Tooltip
                                    id="task--detail-id"
                                    className="tooltip--task--details"
                                  />
                                  {user.userType == "DIRECTOR" ? (
                                    <Link
                                      to={{
                                        pathname: "/your-task-list",
                                      }}
                                      state={{
                                        id:

                                          item.taskDetails.status == "open"
                                            ? "new-jobs" : item.taskDetails.status == "assigned"
                                              ? "in-progress"
                                              : item.taskDetails.status ==
                                                "in-progress"
                                                ? "in-progress"
                                                : item.taskDetails.status ==
                                                  "completed"
                                                  ? "completed-task"
                                                  : "",
                                      }}
                                    >
                                      <span
                                        data-tooltip-id="task--detail-id"
                                        data-tooltip-content={
                                          item?.taskDetails?.title
                                        }
                                        data-tooltip-place="top"
                                        className="task--title"
                                      >
                                        {item?.taskDetails?.title}
                                      </span>
                                    </Link>
                                  ) : (
                                    <Link
                                      to={{
                                        pathname:
                                          !item?.taskProposalDetails?.cancellation == "" ? "/hustlr-jobs" :
                                            item.taskDetails.status == "open"
                                              ? "/home"
                                              : item.taskDetails.status ==
                                                "assigned"
                                                ? "/home" : item.taskDetails.cancel == true
                                                  ? "/home"

                                                  : "/hustlr-jobs",
                                      }}
                                      state={{
                                        id:
                                          item.taskDetails.cancel == true ? "incoming-jobs" :
                                            !item?.taskProposalDetails?.cancellation == "" ? "cancelled-jobs" :
                                              item.taskDetails.status == "open"
                                                ? "interested-jobs"
                                                : item.taskDetails.status ==
                                                  "assigned"
                                                  ? "incoming-jobs"
                                                  : item.taskDetails.status ==
                                                    "in-progress"
                                                    ? "upcoming"
                                                    : item.taskDetails.status ==
                                                      "completed"
                                                      ? "completed-jobs"
                                                      : "",
                                      }}
                                    >
                                      <span
                                        data-tooltip-id="task--detail-id"
                                        data-tooltip-content={
                                          item?.taskDetails?.title
                                        }
                                        data-tooltip-place="top"
                                        className="task--title"
                                      >
                                        {item?.taskDetails?.title}
                                      </span>
                                    </Link>
                                  )}
                                </div>

                                <small className="timee">
                                  {" "}
                                  {item?.latestMessage &&
                                    item?.latestMessage?.createdAt
                                    ? extractTime(
                                      item?.latestMessage?.createdAt
                                    )
                                    : item?.latestMessage?.attachements?.images
                                      ?.createdAt &&
                                    extractTime(
                                      item?.latestMessage?.attachements
                                        ?.images?.createdAt
                                    )}
                                </small>
                              </p> */}

                              <div className="massage--info--chat">
                                <p className="preview">

                                  {item?.latestMessage?.text ? (
                                    item?.latestMessage?.text
                                  ) : (item?.latestMessage?.attachments
                                    ?.length >= 0 &&
                                    item?.latestMessage?.text === "") ||
                                    (item?.latestMessage?.attachements?.images
                                      ?.attachments?.length > 0 &&
                                      item?.latestMessage?.attachements?.images
                                        ?.text === "") ? (
                                    <div>
                                      <FaRegImage /> Photo
                                    </div>
                                  ) : !item?.latestMessage?.attachments
                                    ?.length &&
                                    item?.latestMessage?.attachements?.images
                                      ?.text !== null ? (
                                    item?.latestMessage?.attachements?.images
                                      ?.text
                                  ) : null}
                                </p>

                                <p className="name mb-0">
                                <div className="task--details--title">
                                  <Tooltip
                                    id="task--detail-id"
                                    className="tooltip--task--details"
                                  />
                                  {user.userType == "DIRECTOR" ? (
                                    <Link
                                      to={{
                                        pathname: "/your-task-list",
                                      }}
                                      state={{
                                        id:

                                          item.taskDetails.status == "open"
                                            ? "new-jobs" : item.taskDetails.status == "assigned"
                                              ? "in-progress"
                                              : item.taskDetails.status ==
                                                "in-progress"
                                                ? "in-progress"
                                                : item.taskDetails.status ==
                                                  "completed"
                                                  ? "completed-task"
                                                  : "",
                                      }}
                                    >
                                      <span
                                        data-tooltip-id="task--detail-id"
                                        data-tooltip-content={
                                          item?.taskDetails?.title
                                        }
                                        data-tooltip-place="top"
                                        className="task--title"
                                      >
                                        {item?.taskDetails?.title}
                                      </span>
                                    </Link>
                                  ) : (
                                    <Link
                                      to={{
                                        pathname:
                                          !item?.taskProposalDetails?.cancellation == "" ? "/hustlr-jobs" :
                                            item.taskDetails.status == "open"
                                              ? "/home"
                                              : item.taskDetails.status ==
                                                "assigned"
                                                ? "/home" : item.taskDetails.cancel == true
                                                  ? "/home"

                                                  : "/hustlr-jobs",
                                      }}
                                      state={{
                                        id:
                                          item.taskDetails.cancel == true ? "incoming-jobs" :
                                            !item?.taskProposalDetails?.cancellation == "" ? "cancelled-jobs" :
                                              item.taskDetails.status == "open"
                                                ? "interested-jobs"
                                                : item.taskDetails.status ==
                                                  "assigned"
                                                  ? "incoming-jobs"
                                                  : item.taskDetails.status ==
                                                    "in-progress"
                                                    ? "upcoming"
                                                    : item.taskDetails.status ==
                                                      "completed"
                                                      ? "completed-jobs"
                                                      : "",
                                      }}
                                    >
                                      <span
                                        data-tooltip-id="task--detail-id"
                                        data-tooltip-content={
                                          item?.taskDetails?.title
                                        }
                                        data-tooltip-place="top"
                                        className="task--title"
                                      >
                                        {item?.taskDetails?.title}
                                      </span>
                                    </Link>
                                  )}
                                </div>


                              </p>



                                <div
                                  className={`online-signal ${item.participants[0].isOnline
                                      ? "online"
                                      : "offline"
                                    }`}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                  )
                ) : (
                  <p className="blank--list--contact">
                    Conversations will appear here
                  </p>
                )}
              </ul>
            </div>
          </div>
          <div
            className={`content ${activeMobileChat}`}
            style={{
              backgroundColor: checkedWelcomeMessage ? "" : "var(--lightgray2)",
            }}
          >
            {!checkedWelcomeMessage && (
              <div className="wellcome--message">
                <h1 className="text--dark playFairDisplay heading-h1 mb-2 wordkeep">
                  Welcome to Your Messages
                </h1>
                <p className="wordkeep">
                  Once you connect with a client, you'll be able to chat and
                  collaborate here.
                </p>
              </div>
            )}

            {checkedWelcomeMessage && (
              <>
                <div className="contact-profile message--profile--detail">
                  {isReceiverSelected ? (
                    <>
                      {checkedWelcomeMessage ? (
                        <div
                          className="back--chat--arrow"
                          onClick={handleBackArrowClick}
                        >
                          <FaChevronLeft />
                        </div>
                      ) : null}

                      <div>
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetails.profilePic}`}
                          alt={`${userDetails.name}'s profile`}
                          onClick={() =>
                            navigate(
                              `/user-info/${participantDetails?.participant_id}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        />
                        <p className="mb-0 pt-3">{userDetails?.fname}</p>
                        <div
                          className={`online-signal ${userDetails.isOnline ? "online" : "offline"
                            }`}
                        ></div>
                        <small className="timee">
                          {" "}
                          {userDetails.isOnline ? "online" : "offline"}{" "}
                        </small>
                      </div>
                    </>
                  ) : isparticipantDetails ? (
                    <>
                      {checkedWelcomeMessage ? (
                        <div
                          className="back--chat--arrow"
                          onClick={handleBackArrowClick}
                        >
                          <FaChevronLeft />
                        </div>
                      ) : null}

                      <div>
                        <img
                          onClick={() =>
                            navigate(
                              `/user-info/${participantDetails?.participant_id}`
                            )
                          }
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${participantDetails.profilePic}`}
                          alt={`${userDetails.name}'s profile`}
                        />
                        <p className="mb-0 pt-3">
                          {participantDetails?.participant_name}
                        </p>
                        {/*   <div className="online-signal online"></div>*/}
                        <small className="timee"> {currentTime} </small>
                      </div>
                    </>
                  ) : null}

                  {isReceiverSelected && (
                    <div className="info--details--profile pt-3">
                      <img
                        src={`/assets/images/tooltip.png`}
                        alt=""
                        onClick={() => setshowChatIdModel(true)}
                      />
                    </div>
                  )}
                </div>

                <div className="messages" ref={messagesContainerRef}>
                  <ul>
                    {messages.map((msg, index) => (
                      <li
                        key={index}
                        className={
                          msg.sender === user?.userId ? "replies" : "sent"
                        }
                      >
                        <p>
                          <span className="name--user">
                            {msg.sender == user?.userId
                              ? currentUserDetails?.fname
                              : userDetails?.fname ||
                              getFirstName(participant_name)}
                          </span>
                          {msg.text && (
                            <span className="text--chat" style={{ wordWrap: 'break-word', wordBreak: 'keep-all' }}>{msg.text}</span>
                          )}
                          <div className="images--attatement">
                            {msg?.attachments &&
                              msg?.attachments
                                .slice(0, MAX_DISPLAY_IMAGES)
                                .map((attachment, i) => (
                                  <img
                                    key={i}
                                    className="attachment-img"
                                    src={`${process.env.REACT_APP_IMAGE_URL}message_attachments/${attachment}`}
                                    alt={`Image ${i + 1}`}
                                    onClick={() => openModal(msg._id, i)} // Pass message ID and index
                                  />
                                ))}
                            {msg?.attachments &&
                              msg?.attachments.length > MAX_DISPLAY_IMAGES && (
                                <>
                                  <div
                                    className="more--images"
                                    onClick={() =>
                                      openModalWithAllImages(msg._id)
                                    }
                                  >
                                    {msg?.attachments.length -
                                      MAX_DISPLAY_IMAGES}{" "}
                                    more images
                                  </div>
                                </>
                              )}
                          </div>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* {participantDetails.userStatus && participantDetails.userStatus == "frozen" ? */}
                {taskDetails.blocked && taskDetails?.blocked == true ? (
                  <div className="account--suspended--message">
                    <div className="block--icon">
                      <MdOutlineBlock />
                    </div>
                    <h3>
                      This job has been put on blocked as policy has been
                      breached!!
                    </h3>
                  </div>
                ) : (
                  <div className="message-input">
                    <div className="wrap chat--input--message">
                      <div className="chat--input--div">
                        <LuSmile onClick={toggleEmojiPicker} />
                        {showEmojiPicker && (
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                        )}

                        <TextareaAutosize
                          minRows={0} // Set the minimum number of rows
                          maxRows={4} // Set the maximum number of rows
                          placeholder="Type Message"
                          value={newMessage}
                          ref={inputRef}
                          onChange={handleInputChange}
                          disabled={participantDetails.userStatus == "frozen"}
                          // onChange={(e) => setNewMessage(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              handleSendMessage(participantDetails?.taskId);
                            }
                          }}
                          style={{
                            backgroundColor: "transparent",
                            zIndex: "2",
                          }}
                        />
                      </div>
                      <div className="attact-submit--div">
                        {selectedImages.length > 0 && (
                          <div className="selected--list">
                            {/* <p>Selected Images {selectedImages.length}</p> */}
                            <span
                              className="close--attachment"
                              onClick={() => setSelectedImages([])}
                            >
                              <IoCloseCircle />
                            </span>
                            <div className="preview-images">
                              {selectedImages.map((previewUrl, index) => (
                                <>
                                  <div
                                    className="signle--attach"
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      className="close-single"
                                      onClick={() => removeImage(index)}
                                    >
                                      <IoCloseCircle />
                                    </span>
                                    <img
                                      key={index}
                                      src={previewUrl}
                                      alt={`Selected ${index}`}
                                      className="preview-image"
                                    />
                                  </div>
                                </>
                              ))}
                              {selectedImages &&
                                selectedImages.length > 9 ? null : (
                                <div className="add--more--images">
                                  <input
                                    type="file"
                                    name="attachment"
                                    multiple="multiple"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={handleAttachmentChange}
                                    onKeyPress={(e) => {
                                      if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage(
                                          participantDetails?.taskId
                                        );
                                      }
                                    }}
                                    style={{
                                      backgroundColor: "transparent",
                                      zIndex: "2",
                                    }}
                                  />
                                  <BiImageAdd />
                                </div>
                              )}
                            </div>

                            {/* <div className="add--more--images">
                              <input
                                type="file"
                                name="attachment"
                                multiple="multiple"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={handleAttachmentChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage(participantDetails?.taskId);
                                  }
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  zIndex: "2",
                                }}
                              />
                              <BiImageAdd />
                            </div> */}

                          </div>
                        )}

                        {
                          participantDetails?.chatId && <div className="attacment--img">
                            <span className="icon--attach">
                              <input
                                type="file"
                                name="attachment"
                                multiple="multiple"
                                accept="image/png,image/gif,image/jpeg"
                                onChange={handleAttachmentChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter" && !e.shiftKey){
                                    e.preventDefault();
                                    handleSendMessage(participantDetails?.taskId);
                                  }

                                }}
                                style={{
                                  backgroundColor:"transparent",
                                  zIndex:"2",
                                }}
                              />

                              <GoPlus />
                            </span>
                          </div>
                        }

                        <button
                          className="submit"
                          onClick={() =>
                            handleSendMessage(participantDetails?.taskId)
                          }
                        >
                          <IoPaperPlaneOutline />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showChatIdModel && (
        <ChatIdTask
          taskDetails={taskDetails}
          setshowChatIdModel={setshowChatIdModel}
        />
      )}
      

      {/* {showWarning && <WarningPopup privacybreach={privacybreach} />} */}

      {isModalOpen && (
        <div className="attachment--gallery">
          <IoCloseCircle className="close--modal" onClick={closeModal} />
          <div
            className="overlay--gallery"
            style={{ zIndex: "auto" }}
            onClick={closeModal}
          ></div>

          <div className="media--list ">
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}message_attachments/${selectedMessageAttachments[currentImageIndex]}`}
              alt={`Image ${currentImageIndex + 1}`}
            />

            {selectedMessageAttachments &&
              selectedMessageAttachments?.length > 1 && (
                <div className="navigation--task--image">
                  <div className="button--arrow" onClick={prevImage}>
                    <GoChevronLeft />
                  </div>
                  <div className="button--arrow" onClick={nextImage}>
                    <GoChevronRight />
                  </div>
                </div>
              )}
          </div>
        </div>
      )}

      {isMediaLimit && (
        <>
          <div className="attachment--modal--limit">
            <div className="attachment--modal--limit--content">
              <p>
                The selected media file exceeds the maximum size limit of 10
                units and cannot be sent.
              </p>
              <button
                className="custom--btn darkbtn-hover minbutton mb-3"
                onClick={() => setMediaLimit(false)}
              >
                <span>Ok</span>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IsLoadingHOC(Chat);
