import React, { useEffect, useState } from "react";
import Select from "react-select";
import AddReviews from "../../common/AddReviews";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../../assets/Helper";
import DeleteReviewModal from "./DeleteReviewModal";
import { useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { handleDecimalNumber } from "../../utils/helper";

const HustlerReviews = ({ comments, userDetail }) => {

  const [expandedComments, setExpandedComments] = useState([]);

  const toggleReadMore = (index) => {
    if (expandedComments.includes(index)) {
      setExpandedComments(expandedComments.filter((i) => i !== index));
    } else {
      setExpandedComments([...expandedComments, index]);
    }
  };
  return (
    <>
      <div className="reviews--section">
        <div className="profile--reviews--rating">
          <div>
            <img src="/assets/images/hustler-star.png" />{" "}
            {userDetail.userType === "DIRECTOR" ? (
              <>
                {handleDecimalNumber(userDetail?.directorDetails?.ratings)} |{" "}
                {userDetail?.directorDetails?.reviews} Reviews
              </>
            ) : (
              <>
                {handleDecimalNumber(userDetail?.hustlerDetails?.ratings)} |{" "}
                {userDetail?.hustlerDetails?.reviews} Reviews
              </>
            )}
          </div>

          <div></div>
        </div>
        <div className="ratingbar--list">
          {comments&&comments.length > 0 ? (
            <div className="ratingbar--list--left">
              <ul>
                <li>
                  <span>5 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </span>
                  <span>{"(5)"}</span>
                </li>

                <li>
                  <span>4 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "80%" }}
                        aria-valuenow="80"
                        aria-valuemin="0"
                        aria-valuemax="80"
                      ></div>
                    </div>
                  </span>
                  <span>{"(4)"}</span>
                </li>
                <li>
                  <span>3 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "60%" }}
                        aria-valuenow="60"
                        aria-valuemin="0"
                        aria-valuemax="60"
                      ></div>
                    </div>
                  </span>
                  <span>{"(0)"}</span>
                </li>
                <li>
                  <span>2 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "40%" }}
                        aria-valuenow="40"
                        aria-valuemin="0"
                        aria-valuemax="40"
                      ></div>
                    </div>
                  </span>
                  <span>{"(2)"}</span>
                </li>
                <li>
                  <span>1 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="20"
                      ></div>
                    </div>
                  </span>
                  <span>{"(4)"}</span>
                </li>
              </ul>
            </div>
          ) : (
            <div className="ratingbar--list--left">
              <ul>
                <li>
                  <span>5 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </span>
                  <span>{"(0)"}</span>
                </li>

                <li>
                  <span>4 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "80%" }}
                        aria-valuenow="80"
                        aria-valuemin="0"
                        aria-valuemax="80"
                      ></div>
                    </div>
                  </span>
                  <span>{"(0)"}</span>
                </li>
                <li>
                  <span>3 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "60%" }}
                        aria-valuenow="60"
                        aria-valuemin="0"
                        aria-valuemax="60"
                      ></div>
                    </div>
                  </span>
                  <span>{"(0)"}</span>
                </li>
                <li>
                  <span>2 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "40%" }}
                        aria-valuenow="40"
                        aria-valuemin="0"
                        aria-valuemax="40"
                      ></div>
                    </div>
                  </span>
                  <span>{"(0)"}</span>
                </li>
                <li>
                  <span>1 Star</span>
                  <span className="rating--bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="20"
                      ></div>
                    </div>
                  </span>
                  <span>{"(0)"}</span>
                </li>
              </ul>
            </div>
          )}

          {/*
            
             */}

          {userDetail.userType === "DIRECTOR" ? (
            <div className="rating--list--right">
              <ul>
                <li>
                  <span>Professionalism</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.directorDetails?.behaviorAvg)}
                  </span>
                </li>

                <li>
                  <span>Availability</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.directorDetails?.availabilityAvg)}
                  </span>
                </li>
                <li>
                  <span>Payment</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.directorDetails?.paymentAvg)}
                  </span>
                </li>
                <li>
                  <span>Communication</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.directorDetails?.communicationAvg)}
                  </span>
                </li>
              </ul>
            </div>
          ) : (
            <div className="rating--list--right">
              <ul>
                <li>
                  <span>Professionalism</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.hustlerDetails?.behaviorAvg)}
                  </span>
                </li>

                <li>
                  <span>Availability</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.hustlerDetails?.availabilityAvg)}
                  </span>
                </li>
                <li>
                  <span>Skills</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.hustlerDetails?.skillsAvg)}
                  </span>
                </li>
                <li>
                  <span>Communication</span>
                  <span>
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.hustlerDetails?.communicationAvg)}
                  </span>
                </li>
              </ul>
            </div>
          )}
          
        </div>
        <h2>Comments</h2>

        {!comments && (
          <h5 style={{ textAlign: "center", color: "#ada7a7" }}>No Reviews</h5>
        )}
        <br />
        {comments &&
        comments.map((item, index) => {
          const isExpanded = expandedComments.includes(index);
          const commentText =
            item?.comments.length > 100 && !isExpanded
              ? `${item?.comments.substring(0, 100)}...`
              : item?.comments;

          return (
            <div className="user--comment" key={item._id}>
              <div className="user--comment--info">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.reviewSender?.profilePic}`}
                  alt=""
                  className="user--profile--img"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="user--comment--detail">
                  <h3>
                    {item?.reviewSender?.fname} {item?.reviewSender?.lname}
                  </h3>
                  <span className="user--rating--comment">
                    <img src="/assets/images/hustler-star.png" alt="rating" />{" "}
                    {handleDecimalNumber(item?.rating)}
                  </span>{" "}
                  {setReportFormatDate(item?.createdAt)}
                </div>
              </div>
              <div className="user--comment--description">
                <p>{commentText}</p>
                {item?.comments.length > 100 && (
                  <span
                    className="read-more-toggle"
                    onClick={() => toggleReadMore(index)}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default HustlerReviews;
