import React, { useEffect } from 'react'
import DirectorHeader from '../pages/become-a-director/DirectorHeader'
import Footer from '../common/footer'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DirectorLayout = ({ children }) => {


  const { accessToken } = useSelector((state) => state.auth);

  const userType = useSelector((state) => state.auth?.user?.userType);

  const navigate = useNavigate();
  useEffect(() => {
    if (userType !== "DIRECTOR") {
      navigate("/");
    }
  }, []);



  return (
    <div>
      <DirectorHeader />
      {children}
      <Footer />
    </div>
  )
}

export default DirectorLayout