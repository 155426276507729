import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import DirectorHeader from "./DirectorHeader";
import { useDispatch, useSelector } from "react-redux";
import { State, City } from "country-state-city";
import CreatableSelect from "react-select/creatable";

import Select from "react-select";
import { CanadianCity } from "../../utils/constants";
import { authAxios, withoutAuthAxios } from "../../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { useNavigate } from "react-router-dom";
import { setReportFormatDate } from "../../assets/Helper";
import { displayBudget, payloadBudget, toCamelCase } from "../../utils/helper";
import { IoCheckmarkCircle, IoCloseOutline } from "react-icons/io5";
import { GoCheck } from "react-icons/go";

const DirectorCreateTask = (props) => {
  const { setLoading } = props;

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const taskStep = [
    "Step 1",
    "Step 2",
    "Step 3",
    "Step 4",
    "Step 5",
    "Step 6",
    "Step 7",
  ];
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [createTaskData, setcreateTaskData] = useState("");
  const [option, setOption] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");

  const taskServices = useSelector((state) => state.app?.services);
  // console.log(taskServices)

  const [filteredData, setFilteredData] = useState([]);
  const MAX_UPLOAD_LIMIT = 10;
  const [uploadLimitExceeded, setUploadLimitExceeded] = useState(false);

  const [stepForm, setStepForm] = useState({
    step: 1,
    name: "",
    email: "",
    title: "",
    password: "",
    jobType: "",
    pincode: "",
    budget: "",
    dueDate: "",
    description: "",
    oneTimePayment: "",
    subscriptionInterval: "",
    job_image: [],
  });

  const { step } = stepForm;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (stepForm.step === 6) {
      const formData = new FormData();
      stepForm.job_image.forEach((file) => {
        formData.append("job_image", file);
      });
      formData.append("title", stepForm.title);
      formData.append("serviceName", toCamelCase(selectedService?.value));
      formData.append("cityName", selectedCity?.value);
      formData.append("budget", payloadBudget(`${stepForm.budget}`))
      formData.append("province", selectedProvince.label);
      formData.append("dueDate", startDate);
      formData.append("description", stepForm.description);
      formData.append("status", "open");
      formData.append("oneTimePayment", stepForm.oneTimePayment);
      if (!stepForm.subscriptionInterval == "") {
        formData.append("subscriptionInterval", stepForm.subscriptionInterval)
      }
      setLoading(true);
      await authAxios()
        .post("/task/create-tasks", formData)
        .then((response) => {
          setLoading(false);
          const resData = response.data;

          if (resData.status === 1) {
            navigate("/home");
            // console.log(resData, "resData");
            toast.success(resData.message);
          } else {
            toast.error(resData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });


    } else {
      // Move to the next step
      setStepForm((prevState) => ({
        ...prevState,
        step: prevState.step + 1,
      }));
    }

  };

  const handlePrevious = () => {
    if (stepForm.step > 1) {
      setStepForm((prevState) => ({
        ...prevState,
        step: prevState.step - 1,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStepForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBudgetChange = (e) => {
    const { name, value } = e.target;
    let numericValue = value.replace(/,/g, "");
    numericValue = numericValue.replace(/[^0-9]/g, "");

    if (numericValue !== "") {
      numericValue = "$" + parseInt(numericValue, 10).toLocaleString("en-US");
    }

    setStepForm((prevStepForm) => ({
      ...prevStepForm,
      [name]: numericValue,
    }));
  };

  //console.log(stepForm.budget, "budget check value");
  const handleSelectTask = (option) => {
    setSelectedService(option);
    if (!option) {
      setFilteredData([]);
    }
  };

  const handleInputChange=(value,e)=>{
    
    if (e.action == "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        setSelectedService(newOption);
      }
    }
    
  }
 

  

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleSelectCity = (option) => {
    setSelectedCity(option);
    if (!option) {
      setFilteredData([]);
    }
  };

  const handleMediaUpload = (e) => {
    const files = e.target.files;
    console.log(files, "filesfilesfilesfiles");
    if (stepForm.job_image.length + files.length > MAX_UPLOAD_LIMIT) {
      setUploadLimitExceeded(true);
      return;
    }

    setStepForm((prevStepForm) => ({
      ...prevStepForm,
      job_image: [...prevStepForm.job_image, ...Array.from(files)],
    }));
  };

  const removeImage = (index) => {
    const updatedImages = [...stepForm.job_image];
    updatedImages.splice(index, 1);
    setStepForm((prevStepForm) => ({
      ...prevStepForm,
      job_image: updatedImages,
    }));
  };

  const handleOptionChange = (event) => {
    console.log("ev", event.target.id);

    if (event.target.id == "true") {
      setStepForm((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
        subscriptionInterval: "",
      }));
    } else {
      setStepForm((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
      }));
    }
  };

  const handleSubscriptionChange = (option) => {
    console.log(option)
    setStepForm((prev) => ({
      ...prev,
      subscriptionInterval: `${option}`,
      oneTimePayment: "false",
    }));
  };


  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
     ]
     );
  }, []);

  useEffect(() => {
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )


    if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  return (
    <>
      <section className="create--task--form">
        <div className="custom--container m-auto task--container">
          <div className="task--progress-bar">
            {taskStep.map((s, index) => (
              <div
                key={index}
                className={`step ${step === index + 1 ? "active" : ""} ${step > index + 1 ? "completed" : ""
                  }`}
              >
                {s}
              </div>
            ))}
          </div>

          <form onSubmit={handleSubmit} className="step--form--task">
            {step === 1 && (
              <div className="step--container">
                <div className="task--heading--label">
                  <label>1/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark" style={{ width: '90%' }}>
                    Enter Job Frequency
                  </h2>
                </div>

                <div className="task--heading--input">
                  <label>
                    Is this job a one-time occurrence? <span className="mendotry-field">*</span>
                  </label>
                  <div className="buget--members">

                    <div className="job--type">
                      <div className="one--time--payment">
                        <div className="ratio--box">
                          <div className="radio--custom">
                            <input
                              type="radio"
                              required
                              id="true"
                              name="option"
                              value={stepForm.oneTimePayment}
                              checked={stepForm.oneTimePayment == "true"}
                              onChange={handleOptionChange}
                            />
                            <span className="radio"></span>
                          </div>
                          <label htmlFor="yes">Yes</label>
                        </div>
                        <div className="ratio--box">
                          <div className="radio--custom">
                            <input
                              type="radio"
                              required
                              id="false"
                              name="option"
                              value={stepForm.oneTimePayment}
                              checked={stepForm.oneTimePayment == "false"}
                              onChange={handleOptionChange}
                            />
                            <span className="radio"></span>
                          </div>
                          <label htmlFor="no">No</label>
                        </div>
                      </div>

                      {stepForm.oneTimePayment === "false" ? (
                        <>
                          <label className="mt-3 mb-2">
                            How frequently would you like this job to repeat?{" "}
                            <span className="mendotry-field">*</span>
                          </label>
                          <div className="what--subscription">
                            <label
                              className={`ratio--box custom--btn minbutton ${stepForm.subscriptionInterval === "week"
                                ? "darkbtn-hover"
                                : "ligghtbtn-hover transparent--btn"
                                }`}
                              onClick={() => handleSubscriptionChange("week")}
                            >
                              <input

                                type="radio"
                                required
                                name="subcriptionoption"
                                id="week"
                                checked={stepForm.subscriptionInterval == "week"}
                              />

                              <span>
                                {stepForm.subscriptionInterval == "week" && (
                                  <IoCheckmarkCircle />
                                )}{" "}
                                Weekly
                              </span>
                            </label>

                            <label
                              className={`ratio--box custom--btn minbutton ${stepForm.subscriptionInterval === "month"
                                ? "darkbtn-hover"
                                : "ligghtbtn-hover transparent--btn"
                                }`}
                              onClick={() =>
                                handleSubscriptionChange("month")
                              }
                            >
                              <input
                                type="radio"
                                required
                                name="subcriptionoption"
                                id="month"
                                checked={stepForm.subscriptionInterval ===
                                  "month"}
                              />
                              <span>
                                {stepForm.subscriptionInterval ===
                                  "month" && <IoCheckmarkCircle />}{" "}
                                Monthly
                              </span>
                            </label>
                            {/* 
                            <label
                              className={`ratio--box custom--btn minbutton ${stepForm.subscriptionInterval === "year"
                                ? "darkbtn-hover"
                                : "ligghtbtn-hover transparent--btn"
                                }`}
                              onClick={() => handleSubscriptionChange("year")}
                            >
                              <input
                                type="radio"
                                required
                                name="subcriptionoption"
                                id="year"
                                checked={stepForm.subscriptionInterval === "year"}
                              />
                              <span>
                                {stepForm.subscriptionInterval === "year" && (
                                  <IoCheckmarkCircle />
                                )}{" "}
                                Yearly
                              </span>
                            </label> */}
                          </div>
                        </>
                      ) : null}

                      <div className="task--heading--input">
                        <label className="mt-3 mb-2">
                          What date would you like the task to be started?{" "}
                          <span className="mendotry-field">*</span>
                        </label>
                        <br />
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={new Date()}
                          required
                          dateFormat="MMM-dd-yy"
                        />
                      </div>

                      {stepForm.oneTimePayment === "false" ? (
                      <div className="task--heading--input mt-2">
                        {stepForm.subscriptionInterval?<p>Your job will reoccur <b>{stepForm.subscriptionInterval=="week"? 'weekly':'monthly'}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>
:                        <p>Your job will reoccur <b>{`weekly/monthly`}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>
}
                      </div>
                    ) : null}
                    </div>

                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="step--container">
                <div className="task--heading--label">
                  <label>2/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Enter Job Title
                  </h2>
                </div>

                <div className="task--heading--input">
                  <label>
                    Title <span className="mendotry-field">*</span>
                  </label>
                  <div className="buget--members">
                    <div>
                      <input
                        type="text"
                        name="title"
                        onChange={handleChange}
                        value={stepForm.title}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="step--container">
                <div className="task--heading--label">
                  <label>3/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Select Job Type
                  </h2>
                </div>

                <div className="task--heading--input">
                  <label>
                    Select Job <span className="mendotry-field">*</span>
                  </label>
                  <CreatableSelect
                    options={taskServices}
                    isClearable
                    className="select--custom"
                    placeholder={`I need help with`}
                    value={selectedService}
                    onChange={handleSelectTask}
                    onInputChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="step--container">
                <div className="task--heading--label">
                  <label>4/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Location
                  </h2>
                </div>

                <div className="select--city">
                  <div className="task--heading--input mb-20px">
                    <label>
                      Select Province <span className="mendotry-field">*</span>
                    </label>
                    <Select
                      options={provinceList}
                      isClearable
                      className="select--custom province--select"
                      value={selectedProvince}
                      placeholder={`Select Province`}
                      onChange={handleSelectProvince}
                      required
                    />
                  </div>

                  <div className="task--heading--input">
                    <label>
                      Select City <span className="mendotry-field">*</span>
                    </label>
                    <Select
                      options={selectedProvince?cityList:[]}
                      isClearable
                      className="select--custom"
                      value={selectedCity}
                      placeholder={`Select City`}
                      onChange={handleSelectCity}
                      required
                    />
                  </div>
                </div>
              </div>
            )}

            {step === 5 && (
              <div className="step--container">
                <div className="task--heading--label">
                  <label>5/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Select Budget
                  </h2>
                </div>

                <div className="task--heading--input">
                  <div className="buget--members">
                    <div>
                      <label>
                        Budget <span className="mendotry-field">*</span>
                      </label>
                      <div className="input--dolldar">
                        <span className="dollar-icon">$</span>
                        <input
                          type="text"
                          name="budget"
                          onChange={handleChange}
                          value={displayBudget(stepForm.budget)}
                          placeholder="0"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 6 && (
              <div className="step--container">
                <div className="task--heading--label">
                  <label>6/6</label>
                  <h2
                    className="playFairDisplay heading-h1 text-dark hidedown-767"
                    style={{ height: "145px" }}
                  >
                    Add Your Description
                  </h2>
                  <h2 className="playFairDisplay heading-h1 text-dark hidedown-767">
                    Add Your Images
                  </h2>
                </div>

                <div className="add--image-des">
                  <h2 className="playFairDisplay heading-h1 text-dark hideup-767">
                    Add Your Description
                  </h2>
                  <div className="task--heading--input pb-4">
                    <label>
                      Description <span className="mendotry-field">*</span>
                    </label>
                    <br />
                    <textarea
                      className="w-100 p-2"
                      name="description"
                      onChange={handleChange}
                      value={stepForm.description}
                      required
                    ></textarea>
                  </div>

                  <h2 className="playFairDisplay heading-h1 text-dark hideup-767">
                    Add Your Images
                  </h2>

                  <div className="task--heading--input">
                    <div className="upload--media">
                      <div className="upload--file">
                        <div className="btn btn-dark darkbtn-hover">
                          Upload Media
                        </div>
                        <input
                          type="file"
                          name="mediaupload"
                          multiple="multiple"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={handleMediaUpload}
                        />
                      </div>

                      <div className="media--list--task">
                        {stepForm.job_image.map((image, index) => (
                          <div className="media--item" key={index}>
                            <p
                              className="remove--media"
                              onClick={() => removeImage(index)}
                            >
                              <IoCloseOutline />
                            </p>
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Image ${index + 1}`}
                            />
                          </div>
                        ))}
                      </div>

                      {uploadLimitExceeded && (
                        <p style={{ color: "red" }} className="pt-3">
                          Upload limit exceeded. You can upload a maximum of{" "}
                          {MAX_UPLOAD_LIMIT} images.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="task--steps-buttons mt-25">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover"
                onClick={handlePrevious}
                disabled={step === 1}
              >
                <span>Previous</span>
              </button>
              <button type="submit" className="custom--btn darkbtn-hover">
                <span>{step === 6 ? "Submit" : "Next"}</span>
              </button>
              {/* <button onClick={checkValue}>
                check
              </button> */}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default IsLoadingHOC(DirectorCreateTask);
