import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'

const SensitiveModel = ({text}) => {
  return (
    <div className="warning--modal--container">
    <div className="warning--modal--box">
      <IoWarningOutline className="icon--warning" />
     
      <p>
       Dont use any Abusive Word 
      </p>
    </div>
  </div>
  )
}

export default SensitiveModel