import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withoutAuthAxios } from "../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../common/IsLoadingHOC";
import { saveServices, saveTaskOptions,CombineTaskOptions } from "../Redux/Reducers/appSlice";
import {RemovingDuplicatesServicesandCategories, toCamelCase} from "../utils/helper"
const LandingPage = (props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  const token = useSelector((state) => state?.auth?.accessToken);
  const dispatch = useDispatch();
  const { setLoading } = props;

  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        console.log(response,"response")
        const resData = response?.data;
        console.log(resData,"kajal is testing")
        setLoading(false);
        if (resData?.status == 1) {

          
         // dispatch(saveTaskOptions(resData?.data));

          const services = [];
          resData?.data.map((item) => {
            services.push({
              label:  toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });

          const category=[]

          resData?.data?.map((item)=>{
            item?.category?.map((cat)=>{
              category?.push(cat)
            })
          })

          console.log(services,"qwe")
          console.log(category,"deep")
          dispatch(CombineTaskOptions(resData))
          dispatch(saveServices(RemovingDuplicatesServicesandCategories(services)))
          dispatch(saveTaskOptions(RemovingDuplicatesServicesandCategories(category)))

          

        //  dispatch(saveServices(services));
          
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaskListData();
  }, []);

  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, []);

  const [landingPage, setLandingPage] = useState(true);
  const [animationWidth, setAnimationWidth] = useState(false);

  const handleWidthAnimation = (item) => {
    setAnimationWidth(true);

    setTimeout(() => {
      setAnimationWidth(false);
      setLandingPage(false);
    }, 1000);

    setTimeout(() => {
      navigate(item);
    }, 500);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {landingPage && (
        <div className="container--landing">
           <style>
            {`

             .More_hustl {
    position: absolute;
    left: 10px;
    bottom: -5px;
  }
              @media only screen and (max-width: 821px) {
                .More_hustl {
                    position: absolute; /* Ensure it's absolute */

top: -13px;
     left: auto; /* Override the left positioning */
    right: 18px;
    // left: 10px; /* Override the left positioning */
    // bottom: auto;                 }
              }
            `}
          </style>
          <div className={`logo--sticy ${animationWidth ? "animated-up" : ""}`}>
            <img
              src="./assets/images/sticky-logo.png"
              alt="logo"
              {...(isSmallScreen ? {} : { 'data-aos': 'fade-down', 'data-aos-duration': '1000' })}
            />
            {/* <h2 className="headline">Less Hassle <span>More Hustl.</span></h2> */}
          </div>
          <div className="row" >
            <div  style={{position: "relative"}}
              className={`col-12 col-lg-6 director--div ${animationWidth ? "animated-left" : ""
                }`}
            >
              <div className="content--width pt-0" {...(isSmallScreen ? {} : { 'data-aos': 'fade-up', 'data-aos-duration': '1000' } )}>
                <img
                  // src="./assets/images/director-icon.png"
                  src="./assets/images/creater.png"
                  
                  style={{
                    // width: '90px',
                    // border: '2px solid #000',
                    borderRadius: '20px',
                  }}
                  alt="director"
                />
                <h2
                  className="playFairDisplay heading-h1"
                >
                  {" "}
                  {/* All Hustl, No Hassle */}
                  Creatr 
                </h2>
                <div style={{minHeight: "120px"}}> 
                <b>                Reclaim your time. 
                </b>
                <p>
                Offload your to-dos to a qualified Hustlr–quick, easy and hassle-free. Post a job, find the right person, and get back to what matters most. 


                  {/* Are you tired of paying too much for a contractor or
                  journeyman to do something simple? SideHustl gives you
                  another option by finding the right Hustlr that fits your odd
                  job needs. Search for the right person by location or job.
                  Don't waste another fast-paced weekend on your “to-do list”
                  and pay a little for a lot of free time now. */}

                </p>
                </div>
                {/* <hr /> */}

                <button
                  className="custom--btn ligghtbtn-hover transparent--btn minbutton mt-3"
                  onClick={() => handleWidthAnimation("/become-a-creatr")}
                >
                  <span>
                    I'm a Creatr
                  </span>
                </button>
              </div>
              <h2 className="playFairDisplay headline mt-3" style={{position: "absolute", right: "6px" , bottom: "-5px"}}>Less Hassle<span style={{color: "black"}}>,</span></h2>         
               </div>

            <div style={{position: "relative"}}
              className={`col-12 col-lg-6 hustler--div ${animationWidth ? "animated-right" : ""
                }`}
            >
              <div className="content--width pt-0" {...(isSmallScreen ? {} : { 'data-aos': 'fade-up', 'data-aos-duration': '1000' } )}>
                <img
                  // src="./assets/images/hustler-icon.png"
                  src="./assets/images/onlinejob.png"
                  style={{ background:"#212529"}}
                  alt="hustler"
                />
                <h2
                  className="playFairDisplay heading-h1"
                >
                  {" "}
                  {/* Showing the Us in Hustl */}
                  Hustlr
                </h2>
                <div style={{minHeight: "120px"}}>                <p>
               <b>Earn on your terms.</b> <br/>
                Work the jobs you want, when you want, and get paid directly. You have the skill, we’ll do the rest. Join the community of Hustlrs who are turning side gigs into a flexible lifestyle. 
                  {/* Are you looking for extra income or just want to be your own
                  boss? Take control to make more money now by bidding on local
                  jobs in your community. Everything from moving an appliance to
                  building a deck; SideHustl makes it as easy as a few clicks
                  to connect you with someone in need of your services. */}
                </p>
                </div>
                {/* <p>
                  All you need to do is start with the “where” and “what.” We
                  will do the rest!
                </p> */}
                {/* <hr /> */}
                <button
                  className="custom--btn darkbtn-hover minbutton mt-3"
                  style={{ borderColor: '#fff' }}
                  onClick={() => handleWidthAnimation("/become-a-hustlr")}
                >
                  <span>
                    I'm a Hustlr
                  </span>
                </button>
              </div>
              <h2 className="playFairDisplay headline mt-3 text-white absolute More_hustl" 
			  //  style={{position: "absolute", left: "10px", bottom: "-5px"}}
         >More Hustl</h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IsLoadingHOC(LandingPage);
