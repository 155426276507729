import { React, useEffect } from "react";
import { BsChatRightText } from "react-icons/bs";
import { Link } from "react-router-dom";
import IsLoadingHOC from "./IsLoadingHOC";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Error404 = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const navigate = useNavigate(); 
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem("redirectPath", location.pathname);
    if (!accessToken) {
      sessionStorage.setItem("redirectPath1", location.pathname);
    }
    
    setTimeout(() => {
      navigate("/login");
    }, 0);
  }, [location.pathname, navigate]);

  return (
    <>
      <div className="thankyou--container">
        <div className="thankyou--col error--page">
          <img src="/assets/images/404-img.png" />
          <h1 className="text--dark playFairDisplay">000000ps</h1>
          <p>Page Not Found</p>
          <div className="button--thankyou">
            <Link
              to="/home"
              className="custom--btn ligghtbtn-hover transparent--btn minbutton"
            >
              <span>Home</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(Error404);
