import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { setReportFormatDate } from "../../assets/Helper";
import { TbMoneybag } from "react-icons/tb";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { BudgetFormat, dateFormat, displayBudget } from "../../utils/helper";
import TaskMedia from "../../common/TaskMedia";
import moment from "moment";
import { IoIosArrowRoundBack, IoIosCloseCircle } from "react-icons/io";

import {
  useNavigate,
} from "react-router-dom";


const TaskDetailsView = (props) => {
  const navigate = useNavigate();

  const param = useParams();
  const { setLoading } = props;
  const { id } = param;

  const [Taskdetails, setTaskdetails] = useState([]);

  const fetchTask = async () => {
    setLoading(true);
    await authAxios()
      .get(`task/get-specific-task/${id}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          setTaskdetails(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchTask();
  }, [id]);

  
  // useEffect(() => {
    // if (redirectPath === "/hustlr-jobs/completed-jobs") {
    //   navigate("/hustlr-jobs", { state: { id: "completed-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else if (redirectPath === "/hustlr-jobs/closed-jobs") {
    //   // alert("hi")
    //   navigate("/hustlr-jobs", { state: { id: "closed-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else if (redirectPath === "/hustlr-jobs/in-progress") {
    //   navigate("/hustlr-jobs", { state: { id: "upcoming" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else if (redirectPath === "/hustlr-jobs/cancelled-jobs") {
    //   navigate("/hustlr-jobs", { state: { id: "cancelled-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // }
    // // =====================================
    // else if (redirectPath === "/home/incoming-jobs") {
    //   navigate("/home", { state: { id: "incoming-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else if (redirectPath1 === "/home/incoming-jobs") {
    //   navigate("/home", { state: { id: "incoming-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else if (redirectPath === "/home/interested-jobs") {
    //   navigate("/home", { state: { id: "interested-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else if (redirectPath === "/home/saved-jobs") {
    //   navigate("/home", { state: { id: "saved-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else if (redirectPath === "/home/new-jobs") {
    //   navigate("/home", { state: { id: "new-jobs" } });
    //   sessionStorage.removeItem("redirectPath");
    // } else {
    //   sessionStorage.removeItem("redirectPath"); // Clear the stored path
    //   navigate(redirectPath); // Use the navigate function to redirect
      //  navigate("/home");
  //   }
  // }, []);

  return (
    <>
      <div>
        <section
          className="director--search--hustler pt-117 hustler--home--page"
          style={{ minHeight: "400px" }}
        >
          <div className="custom--container m-auto pt-5 pb-5">
            <div className="container--filter">
              <div className="filter--data--search" style={{maxWidth: "100%"}}>
                <div className="task--lists--profile--based">
                  <div className="director--list--task">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="list--tasks upper--task--list">
                        
                        <div
                              className="flex"
                              style={{ minWidth: "150px" }}
                              // onClick={goBack}

                              onClick={() => {

                                if (Taskdetails?.status == "open") {
                                  navigate("/home");
                                } else if (Taskdetails?.status == "in-progress") {
                                  navigate("/hustlr-jobs", { state: { id: "upcoming" } });
                                } else if (Taskdetails?.status == "completed") {
                                  navigate("/hustlr-jobs", { state: { id: "completed-jobs" } });
                                }
                                
                              }}
                      
                            >
                              <button
                                className="custom--btn mb-3 darkbtn-hover mini-btn"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  <IoIosArrowRoundBack /> Back
                                </span>
                              </button>
                            </div>

                          <div className="created--task--lists p-0 bg-white border-0">
                            <h1
                              className="text--dark playFairDisplay heading-h1 mb-3"
                              style={{ fontSize: "clamp(35px, 3vw, 50px)" }}
                            >
                              {Taskdetails.title}
                            </h1>
                            <div
                              className="task--items--header"
                              style={{
                                flexDirection: "initial",
                                flexWrap: "wrap",
                              }}
                            >
                              {/* {Taskdetails.serviceCategories &&
                                Taskdetails.serviceCategories.map(
                                  (item, index) => <h3 key={index}>{item}</h3>
                                )} */}
                                {Taskdetails?.serviceName && (<h3>{Taskdetails?.serviceName}</h3>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    ></ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="list--tasks">
                          <div className="created--task--lists bg-white border-0 p-0">
                            <div className="task--items-down--footer task--para pb-2 pt-4">
                              <p>
                                <span className="task--items--content--icon">
                                  <TbMoneybag />
                                </span>
                                Budget: $
                                {BudgetFormat(`${Taskdetails?.budget}`)}
                                {/* | Members: 05 */}
                              </p>
                              <p>
                                <span className="task--items--content--icon">
                                  <AiOutlineCalendar />
                                </span>
                                Job Date:{" "} {dateFormat(Taskdetails.dueDate)}
                               
                              </p>
                              <p>
                                <span className="task--items--content--icon">
                                  <GrLocation className="location" />
                                </span>
                                Area: {Taskdetails.cityName}
                              </p>
                            </div>
                          </div>

                          {Taskdetails?.finalPrice ||
                          Taskdetails?.updatedDate ? (
                            <div
                              className="created--task--lists border-0"
                              style={{
                                padding: "15px",
                                backgroundColor: "#f5f5f5",
                                marginBottom: "0px",
                              }}
                            >
                              <div className="task--items-down--footer task--para pt-0 final--biding">
                                {Taskdetails?.finalPrice && (
                                  <>
                                  {Taskdetails.oneTimePayment==true && <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "mb-0" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                    <AiOutlineCalendar />
                                    </span>
                                    <b>Final Date:</b>
                                    <span>{dateFormat(  Taskdetails?.latestCounterOffer
                                          ?.finalDate?Taskdetails?.latestCounterOffer
                                          ?.finalDate:Taskdetails?.taskProposal?.proposalDate) }</span>
                                  </p> }
                                  
                                  {/* <br/> */}

                                  <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "mb-0" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>
                                    <b>Final Bid:</b> $
                                    {BudgetFormat(`${Taskdetails?.finalPrice}`)}
                                    {/* | Members: 05 */}
                                  </p>


                                  </>
                                )}
                                
                              </div>
                            </div>
                          ) : null}

                          <TaskMedia imagePaths={Taskdetails?.attachments} />

                          <div className="created--task--lists p-0 border-0 bg-white pb-3 mb-0">
                            <div className="task--items-footer">
                              <h3 class="playFairDisplay" style={{fontSize:"22px"}}>Job Description:</h3>
                              <p>{Taskdetails?.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default IsLoadingHOC(TaskDetailsView);
