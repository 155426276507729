import React, { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { GoStarFill } from "react-icons/go";
import { GrLocation } from "react-icons/gr";
import { IoCloseSharp } from "react-icons/io5";
import { TbMoneybag } from "react-icons/tb";
import { setReportFormatDate } from "../../assets/Helper";
import { BudgetFormat, displayBudget, handleDecimalNumber, payloadBudget } from "../../utils/helper";
import { Link } from "react-router-dom";
import TaskMedia from "../TaskMedia";
import RattingStar from "../../common/RattingStar";
import DatePicker from "react-datepicker";

const ShowIntersetDisableModal = ({
  TaskDetails,
  setshowIntersetDisableModal,
  handleUpdateJob
}) => {

  const [startDate, setStartDate] = useState(new Date());

  const [formdata, setformdata] = useState({
    offer: "",
    description: "",
  });

  useEffect(() => {
    setformdata((prev) => ({
      ...prev,
      offer: TaskDetails?.offer,
      description: TaskDetails?.description,
    }));
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault()

    const payload = {
      offer: payloadBudget(`${formdata?.offer}`),
      description: formdata.description,
      proposalDate: startDate
    }



    handleUpdateJob(payload)

  }

  const handleChange = (e) => {
    const { name, value } = e.target;



    setformdata((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  useEffect(() => {
    setStartDate(new Date(TaskDetails?.proposalDate))
  }, [])

  return (
    <div className="show--interest--modal--overlay">
      <div className="show--interest--modal custom--scroll showinterest--container">
        <div className="showInsterest--header">
          <h2 className="playFairDisplay heading-h3"></h2>
          <div
            className="close--popup"
            onClick={() => setshowIntersetDisableModal(false)}
          >
            <IoCloseSharp />
          </div>
        </div>

        <div className="showInsterest--content--area">
          <div className="showInsterest--content--area--left">
            <div className="category--lists">
              {/* <ul>
                {TaskDetails?.taskDetails?.serviceCategories?.map(
                  (item, index) => (
                    <>
                      <li key={index}>{item}</li>
                    </>
                  )
                )}
              </ul> */}
              <ul>
                  <li>{TaskDetails?.taskDetails?.serviceName}</li>
                </ul>
            </div>

            <div className="task--budget--info">
              <p>
                <TbMoneybag /> Budget: ${BudgetFormat(`${TaskDetails?.taskDetails?.budget}`)}
                {/* | Members:{" "}
            {TaskDetails?.members || 0} */}
              </p>
              <p>
                <AiOutlineCalendar /> Job Date: {setReportFormatDate(TaskDetails?.taskDetails?.dueDate)}
              </p>
              <p>
                <GrLocation /> Area: {TaskDetails?.taskDetails?.cityName}
              </p>
            </div>

            <div className="task--media">
              <TaskMedia imagePaths={TaskDetails?.taskDetails?.attachments} />
            </div>

            <div className="task--description--info">
              <p>{TaskDetails?.taskDetails?.description}</p>
            </div>

            <form onSubmit={handleSubmit} className="showInsterest--form">
              <h3>Submit Your Details</h3>

              <div className="form--budget--input">
                <div className="info--budget--label">
                  <p>Your Offer Amount <span className="mendotry-field">*</span></p>
                  <p className="mb-1">
                    <small>Share your job proposal with amount </small>
                  </p>
                  <div className="input--dolldar">
                    <span className="dollar-icon">$</span>
                    <input
                      required
                      name="offer"
                      type="text"
                      onChange={handleChange}
                      value={displayBudget(formdata?.offer)}
                      className={`ammout--task`}
                      disabled={TaskDetails.taskDetails.counterOffer !== false}
                    />
                  </div>
                </div>
                <div className="input--budget--field" style={{zIndex:'2'}}>
                  <p>Enter Your Job Date </p>
                  <DatePicker className={`form-control`}
                    disabled={TaskDetails.taskDetails.counterOffer !== false}
                     minDate={new Date()} 
                     selected={startDate} 
                     onChange={(date) => setStartDate(date)}
                      dateFormat="MMM-dd-yy" required />
                </div>
              </div>

              <div className="showInsterest--textarea">
                <h3>Would you like to add any comments?</h3>
                <textarea
                  name="description"
                  onChange={handleChange}
                  value={formdata?.description}
                  // className={` ${TaskDetails.taskDetails.counterOffer === false ? '' : 'input--disable'}`}
                  disabled={TaskDetails.taskDetails.counterOffer !== false}
                ></textarea>
              </div>

              <button
                type="submit"
                className={`custom--btn ligghtbtn-hover transparent--btn minbutton mt-2 ${TaskDetails.taskDetails.counterOffer === false ? '' : 'input--disable'}`}
              >
                <span>Update Interest</span>
              </button>

            </form>
          </div>
          {/* end showInsterest--content--area--left */}

          <div className="showInsterest--content--area--right">
            <div className="submit--task--showInsterest">
              <div className="task--items--edit--work hidedown-991"></div>
              <div className="application--huster">
                <p>
                  <img
                    src="/assets/images/applications.png"
                    width={22}
                    height={22}
                  />{" "}
                  Applications : {TaskDetails?.applications}
                </p>
                {/* 
              <p>
                <img
                  src="/assets/images/low-price.png"
                  width={22}
                  height={22}
                />{" "}
                Lowest-Price: $25{" "}
              </p>
              */}
              </div>

              <div className="director--reviews border--t-b">
                <p className="director--reviews--heading">Creatrs Reviews</p>
                <div className="star--rating--director">
                  <RattingStar ratings={TaskDetails?.taskDetails?.directorDetails?.ratings} />
                </div>
                <p className="rating--director--outof">
                  {handleDecimalNumber(
                    TaskDetails?.taskDetails?.directorDetails?.ratings
                  )}
                </p>
                <div className="director--info">
                  <p>Creatr’s Professionalism</p>
                  <small>
                    {handleDecimalNumber(
                      TaskDetails?.taskDetails?.directorDetails?.behaviorAvg
                    )}
                  </small>
                </div>

                <div className="director--info">
                  <p>Creatr’s Payment</p>
                  <small>
                    {handleDecimalNumber(
                      TaskDetails?.taskDetails?.directorDetails?.paymentAvg
                    )}
                  </small>
                </div>
              </div>

              <div className="director--reviews">
                <div className="director--info">
                  <p>Total Jobs</p>
                  <small>
                    {TaskDetails?.taskDetails?.directorDetails?.totalTask}
                  </small>
                </div>

                <div className="director--info">
                  <p>Completed Jobs</p>
                  <small>
                    {TaskDetails?.taskDetails?.directorDetails?.completedTask}
                  </small>
                </div>

                <div className="director--info">
                  <p>Closed Jobs</p>
                  <small>
                    {TaskDetails?.taskDetails?.directorDetails?.closedTask}
                  </small>
                </div>
              </div>
            </div>

            <div className="profile--info">
              <div className="name--profile">
                <div className="profile--img">
                  <Link to={`/user-info/${TaskDetails?.directorId}`}>
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${TaskDetails?.taskDetails?.directorDetails?.profilePic}`}
                      height={80}
                      width={80}
                      alt=""
                    />
                  </Link>
                  <div className="profile--infodetails">
                    <small>Name</small>
                    <p>{TaskDetails?.taskDetails?.directorDetails?.name}</p>
                  </div>
                </div>
              </div>

              <div className="profile--since">
                <p>Member Since {setReportFormatDate(TaskDetails?.taskDetails?.directorDetails?.createdAt)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowIntersetDisableModal;
