import moment from "moment";
import { useSelector } from "react-redux";

export const sortByBudget = [
  { label: "ALL", value: "all" },
  { label: "00.00-20.00", value: "00.00-20.00" },
  { label: "20.00-40.00", value: "20.00-40.00" },
  { label: "40.00-60.00", value: "40.00-60.00" },
];

export const sortByPrice = [
  { label: "Low to High", value: "low-to-high" },
  { label: "High to Low", value: "high-to-low" },
];

export const staticCategory = [
  { value: "Wall Painting", label: "Wall Painting" },

  { value: "Laundry", label: "Laundry" },
  { value: "Furniture Painting", label: "Furniture Painting" },
  { value: "Plumbing", label: "Plumbing" },
];
export const displayCategoryInHustlerProfile = (data) => {
  return data.map((item, index) => {
    return {
      id: Number(index.toString()),
      serviceName: { label: item.serviceName, value: item.serviceName },
      category: item.category,
    };
  });
};
export const serviceFeePercentage = 0.18;
export function checkServices(services) {
  const checkServices = services
    .map(service => service.serviceName)
    .some(name => !name)
    ? 'not completed'
    : 'completed';
  const checkCatgeory = services.some(service => service.category.length === 0)
    ? 'not completed'
    : 'completed';

  if (checkServices == 'not completed' || checkCatgeory == 'not completed') {
    return 'not completed';
  } else {
    return 'completed';
  }


}
export const staticRatingFilter = [
  { value: "completedTasks", label: "Completed Tasks" },
  { value: "ratings", label: "Ratings" },
  { value: "alphabetsOfName", label: "A to Z" },
  { value: "availability", label: "Availablity" },
];

export const staticHustlerFilter = [
  { value: "highToLow", label: "High to Low" },
  { value: "lowToHigh", label: "Low to High" },
  { value: "directorRating", label: "Ratings" },
  { value: "date", label: "Latest Jobs" },
];

export const CancelReasons = [
  "Low Budget",
  "Availability Issue",
  "Already Have Another Task",
  "Other Issues",
];

export const HustlerDescription =
  "If you are looking for a trustworthy, professional organized, and diligent assistant to help you..";
export const DirectorDescripion =
  "I specialize in generating job opportunities both in alignment with my personal endeavors and professional engagements.";

export const extractTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString();
};
// numberFormatter.js

export const displayBudget = (data) => {



  if (data == 0) {
    return "";
  } else if (data?.includes(".")) {
    const containsAlphabet = /[a-zA-Z]/.test(data);

    if (containsAlphabet) {
        const removeData = data.replace(/[a-zA-Z]/g, '');
        return removeData;
    } else {
        // Replace any non-numeric characters after the first dot
        const invalidCharAfterDot = /\.(\d*)(\D+)/g;
        const cleanedData = data.replace(invalidCharAfterDot, (match, p1, p2) => {
            return `.${p1.replace(/\D/g, '')}`;
        });

        const findIndex = cleanedData.indexOf(".");
        const firstnumber = findIndex + 1;
        const secondnumber = findIndex + 3;

        return `${cleanedData.slice(0, firstnumber)}${cleanedData.slice(
            firstnumber,
            secondnumber
        )}`;
    }
} else if (data.length > 0) {
    const numericValue = data?.replace(/[^\d]/g, ""); // Remove non-numeric characters
    return numericValue
      ? parseInt(numericValue, 10).toLocaleString("en-US")
      : "";
  } else {
    return "";
  }
};

export const BudgetFormat = (data) => {
  if (data == 0) {
    return "";
  } else if (data.includes(".")) {
    const [integerPart, decimalPart] = data.split(".");
    let modifiedDecimalPart =
      decimalPart.length === 1 ? decimalPart + "0" : decimalPart;

    if (decimalPart.length === 2) {
      modifiedDecimalPart = decimalPart;
    }

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ); // Add commas as thousand separators to integer part
    return `${formattedIntegerPart}.${modifiedDecimalPart}`;
  } else if (data.length > 0) {
    const numericValue = data.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const formattedNumericValue = numericValue.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ); // Add commas as thousand separators
    return `${formattedNumericValue}.00`; // Append .00 to represent cents
  } else {
    return "";
  }
};

export const calculateRating = (number, numdata) => {
  let finalval = 0;

  if (number == 5) {

    numdata.forEach((item) => {
      if (item.rating >= 4.5) {
        finalval += 1;
      }
    });
  } else if (number == 4) {

    numdata.forEach((item) => {
      if (item.rating >= 3.5 && item.rating < 4.5) {
        finalval += 1;
      }
    });
  } else if (number == 3) {

    numdata.forEach((item) => {
      if (item.rating >= 2.5 && item.rating < 3.5) {
        finalval += 1;
      }
    });
  } else if (number == 2) {

    numdata.forEach((item) => {
      if (item.rating >= 1.5 && item.rating < 2.5) {
        finalval += 1;
      }
    });
  } else if (number == 1) {

    numdata.forEach((item) => {
      if (item.rating < 1.5) {
        finalval += 1;
      }
    });
  }

  return finalval;
};

export const removeIds = (dataArray) => {
  return dataArray.map((obj) => {
    const { id, _id, ...rest } = obj;
    const category = obj.category.map(({ _id, ...rest }) => rest); // Remove _id from category array
    return { ...rest, category };
  });
};

export const toCamelCase = (str) => {
  if(str){ 
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};

export const correctArrayFormat = (data) => {
  return data.map((item) => ({
    serviceName:  toCamelCase(item?.serviceName?.label),
    category: item?.category?.map(item => ({ label: toCamelCase(item?.label), value: toCamelCase(item?.value) })),
  }));
};

export const showDecimalNumber = (data) => {
  if (data.includes(".")) {
    if (data == 0) {
      return "";
    } else if (data.includes(".")) {
      const findIndex = data.indexOf(".");
      const firstnumber = findIndex + 1;
      const secondnumber = findIndex + 3;

      return `${data.slice(0, firstnumber)}${data.slice(
        firstnumber,
        secondnumber
      )}`;

      // return data;
    } else if (data.length > 0) {
      const numericValue = data?.replace(/[^\d]/g, ""); // Remove non-numeric characters
      return numericValue
        ? parseInt(numericValue, 10).toLocaleString("en-US")
        : "";
    } else {
      return "";
    }
  } else {
    return data + ".00";
  }
};

export const getFirstName = (data) => {
  const parts = data?.split(" ");
  return parts[0];
};


export const removeDuplicates = (arr) => {
  return [...new Set(arr)];
}

export const RemovingDuplicatesServicesandCategories = (items) => {
  const seenValues = new Set();
  const uniqueItems = [];

  for (const item of items) {
    if (!seenValues.has(item.value.trim())) {
      uniqueItems.push(item);
      seenValues.add(item.value.trim());
    }
  }

  return uniqueItems;
}


export const CheckallSensitiveWords = (line, data) => {

  const result = data.map(item => {
    const { word, strictWord } = item;
    const regex = strictWord ? new RegExp(`\\b${word}\\b`, 'i') : new RegExp(word, 'i');
    return regex.test(line);
  }).some(match => match);
  return result
}


export const payloadBudget = data => {
  const removeData = data.replace(/[a-zA-Z]/g, '');

  const newData = removeData.replace(/,/g, '');
  const newFinalData = newData.replace(/[^0-9.,]/g, '')
  const strData = newFinalData.toString();
  const [integerPart, decimalPart] = strData.split('.');
  if (strData.includes('.')) {
    const strNumber = decimalPart.toString();
    const firstDigit = strNumber[0];
    const secondDigit = strNumber[1];
    const thirdDigit = strNumber[2];

    if (firstDigit && secondDigit) {
      const makeNumber = `${integerPart + '.' + firstDigit + secondDigit}`;
      return makeNumber;
    } else if (firstDigit) {
      const makeNumber = `${integerPart + '.' + firstDigit + '0'}`;
      return makeNumber;
    } else {
      const makeNumber = `${integerPart + '.' + '0' + '0'}`;
      return makeNumber;
    }
  } else {
    const makeNumber = `${integerPart + '.' + '0' + '0'}`;
    return makeNumber;
  }
};

export const finalSendBudget = (data) => {
  return data?.toFixed(2);
};

export const handleDecimalNumber = (value) => {
  return value?.toFixed(1);
};
export const handleEmailandPhoneCheck = (value) => {
  return (
    value.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/) ||
    /*   value.match(/\b\d{8,}\b/) ||
    
    value.match(/^[^\s]+(\d{7,})/)
    */

    value.match(/\d{6,}/)
  );
};



export const formatCurrency = (value) => {
  const numericValue = parseInt(value.replace(/[^\d]/g, ""), 10);
  return numericValue.toLocaleString("en-US");
};

export const dateFormat = (value) => {
  if (value) {
    let formattedDateTime = moment(value).format("ll");
    return formattedDateTime;
  } else {
    return "";
  }
};

 export const dateFormatWithMilli = (value) => {
  if (value) {
    let formattedDateTime = moment(value * 1000).format("ll");
    return formattedDateTime;
  } else {
    return "";
  }
};